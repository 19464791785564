import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, get, set, serverTimestamp } from 'firebase/database';
import { useAuth } from "../contexts/AuthContext";

const ChatBox = () => {
  const auth = useAuth();
  const currentUser = auth.currentUser;
  const receiverId = 'Dm0DwDjKWmQkjlbvipfXxjEhHjQ2'; // Replace with actual receiver user ID
  const [message, setMessage] = useState('');
  const [chats, setChats] = useState([]);

  useEffect(() => {
    const database = getDatabase();
    const chatsRef = ref(database, `chats`);

    // Listen for changes in the chats node
    const unsubscribe = onValue(chatsRef, (snapshot) => {
      const chatData = snapshot.val();
      const userChats = Object.keys(chatData || {})
        .filter((chatId) => {
          const chat = chatData[chatId];
          return chat.senderId === currentUser.uid || chat.receiverId === currentUser.uid;
        })
        .map((chatId) => ({
          chatId,
          senderId: chatData[chatId].senderId,
          receiverId: chatData[chatId].receiverId,
          messages: chatData[chatId].messages || [],
        }));

      setChats(userChats);
    });

    return () => {
      // Cleanup the listener when the component unmounts
      unsubscribe();
    };
  }, [currentUser]);

  const sendMessage = async () => {
    if (message.trim() === '') return;
  
    const database = getDatabase();
    const chatId1 = `${currentUser.uid}_${receiverId}`;
    const chatId2 = `${receiverId}_${currentUser.uid}`;
  
    // Check if the chat node already exists with the given combination
    const chatRef1 = ref(database, `chats/${chatId1}`);
    const chatRef2 = ref(database, `chats/${chatId2}`);
    const chatSnapshot1 = await get(chatRef1);
    const chatSnapshot2 = await get(chatRef2);
  
    const existingMessages = [];
  
    if (chatSnapshot1.exists()) {
      const chatData = chatSnapshot1.val();
      existingMessages.push(...(chatData.messages || []));
    } else if (chatSnapshot2.exists()) {
      const chatData = chatSnapshot2.val();
      existingMessages.push(...(chatData.messages || []));
    }
  
    // Update the chat node with the combined messages array
    const newMessageData = {
      senderId: currentUser.uid,
      receiverId,
      message,
      timestamp: serverTimestamp(),
    };
  
    const updatedMessages = [...existingMessages, newMessageData];
  
    if (chatSnapshot1.exists()) {
      await set(chatRef1, { senderId: currentUser.uid, receiverId, messages: updatedMessages });
    } else if (chatSnapshot2.exists()) {
      await set(chatRef2, { senderId: currentUser.uid, receiverId, messages: updatedMessages });
    } else {
      // If the chat node doesn't exist, create a new one
      const chatRef = ref(database, `chats/${chatId1}`);
      await set(chatRef, { senderId: currentUser.uid, receiverId, messages: updatedMessages });
    }
  
    // Clear the input field after sending the message
    setMessage('');
  };
  

  return (
    <div>
      <h1>Chat App</h1>
      <div>
      <ul>
          {chats.map((chat) => (
            <li key={chat.chatId}>
              {chat.senderId === currentUser.uid
                ? <span style={{ color: 'red' }}>{`You and ${chat.receiverId}`}</span>
                : <span style={{ color: 'green' }}>{`You and ${chat.senderId}`}</span>}
              <ul>
                {chat.messages.map((message, index) => (
                  <li key={index} style={{ color: message.senderId === currentUser.uid ? 'red' : 'green' }}>
                    {message.message}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
      <div>
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message..."
        />
        <button onClick={sendMessage}>Send</button>
      </div>
    </div>
  );
};

export default ChatBox;
