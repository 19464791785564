import React from 'react'
import { Link } from 'react-router-dom';
import ImageSlider from '../ImageSlider'
import { Row, Col, Container } from 'react-bootstrap'
import { FaRegStar, FaCheckCircle, FaMapPin, FaStar  } from 'react-icons/fa';
import { GiCaptainHatProfile } from "react-icons/gi";
import { FaPeopleGroup } from "react-icons/fa6";
import { LiaCocktailSolid } from "react-icons/lia";
import TwoMonthCalendar from '../TwoMonthCalendar';
import { CiShoppingTag, CiCalendar } from "react-icons/ci";
import { RxWidth } from "react-icons/rx";
import FloatingButtons from './FloatingButtons';


export default function ListingDetail() {


    const images = [
        'https://source.unsplash.com/random/300x300/?boat',
        'https://source.unsplash.com/random/300x300/?wallpaper',
        'https://source.unsplash.com/random/300x300/?sun',
        'https://source.unsplash.com/random/300x300/?data',
        'https://source.unsplash.com/random/300x300/?tech',
        'https://source.unsplash.com/random/300x300/?image',
        // Add more image URLs as needed
    ];

    const events = [
      {
        id: 1,
        title: 'Event 1',
        start: new Date(2024, 0, 10),
        end: new Date(2024, 0, 15),
      },
      // Add more events as needed
    ];

  return (
    <>
    <FloatingButtons/>
    <section>
        <Row>
            <Col>
                <ImageSlider images={images} />
            </Col>
        </Row>
    </section>
    
    <section className='mb-4'>
        <Container>
            <Row className='mt-4'>
                <Col md={8}>
                    <Row>
                        <Col>
                            <h2>52’ Maxum Sport Yacht - NYC & NJ - HEATED FLY BRIDGE!</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='d-flex' style={{gap: '20px'}}>
                            <p className='d-flex align-items-center' style={{gap: '5px'}}><FaRegStar />5.0 (4 reviews)</p>
                            <p className='d-flex align-items-center' style={{gap: '5px'}}><FaCheckCircle />9 bookings</p>
                            <p className='d-flex align-items-center' style={{gap: '5px'}}><FaMapPin />the United States, New York, New York, Manhattan, Events</p>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <Row>
                                <Col xs={2}>
                                    <img width={'100%'} className='rounded-circle' src='https://source.unsplash.com/random/300x300/?user' />
                                </Col>
                                <Col xs={10} className='d-flex flex-column justify-content-center'>
                                    <h2>Glenn</h2>
                                    <p className='d-flex align-items-center' style={{gap: '5px'}}><FaCheckCircle />34 total bookings</p>
                                </Col>
                            </Row>
                            
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <Row>
                                <Col xs={2} className='d-flex justify-content-center'>
                                    <GiCaptainHatProfile style={{fontSize: '76px'}}/>
                                </Col>
                                <Col xs={10} className='d-flex flex-column justify-content-center'>
                                    <h5>Captain Provided in Price</h5>
                                    <p className='fs-12'>Professional Captain provided and included in the price.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={2} className='d-flex justify-content-center'>
                                    <FaPeopleGroup style={{fontSize: '76px'}}/>
                                </Col>
                                <Col xs={10} className='d-flex flex-column justify-content-center'>
                                    <h5>Capacity</h5>
                                    <p className='fs-12'>12 Guests</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={2} className='d-flex justify-content-center'>
                                    <LiaCocktailSolid style={{fontSize: '76px'}}/>
                                </Col>
                                <Col xs={10} className='d-flex flex-column justify-content-center'>
                                    <h5>Luxury, Events & Large  </h5>
                                    <p className='fs-12'>Motor Yacht</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <h4>Check Availability</h4>
                            <TwoMonthCalendar events={events} />
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <h4>Reviews</h4>
                                    <p className='d-flex align-items-center' style={{gap: '5px'}}><FaRegStar />5.0 (4 reviews)</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <Row className='mb-4'>
                                        <Col xs={6} className='d-flex align-items-center'>
                                            <h6>Listing Accuracy</h6>
                                        </Col>
                                        <Col xs={6} className='d-flex flex-column' style={{gap: '0px'}}>
                                            <p className='mb-0 fs-10'>Awesome</p>
                                            <div className='text-dark'>
                                                <FaStar  /><FaStar  /><FaStar  /><FaStar  /><FaStar  />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mb-4'>
                                        <Col xs={6} className='d-flex align-items-center'>
                                            <h6>Vessel & Equipment</h6>
                                        </Col>
                                        <Col xs={6} className='d-flex flex-column' style={{gap: '0px'}}>
                                            <p className='mb-0 fs-10'>Awesome</p>
                                            <div className='text-dark'>
                                                <FaStar  /><FaStar  /><FaStar  /><FaStar  /><FaStar  />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mb-4'>
                                        <Col xs={6} className='d-flex align-items-center'>
                                            <h6>Value</h6>
                                        </Col>
                                        <Col xs={6} className='d-flex flex-column' style={{gap: '0px'}}>
                                            <p className='mb-0 fs-10'>Awesome</p>
                                            <div className='text-dark'>
                                                <FaStar  /><FaStar  /><FaStar  /><FaStar  /><FaStar  />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={6}>
                                    <Row className='mb-4'>
                                        <Col xs={6} className='d-flex align-items-center'>
                                            <h6>Itinerary & Experience</h6>
                                        </Col>
                                        <Col xs={6} className='d-flex flex-column' style={{gap: '0px'}}>
                                            <p className='mb-0 fs-10'>Awesome</p>
                                            <div className='text-dark'>
                                                <FaStar  /><FaStar  /><FaStar  /><FaStar  /><FaStar  />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mb-4'>
                                        <Col xs={6} className='d-flex align-items-center'>
                                            <h6>Departure & Return</h6>
                                        </Col>
                                        <Col xs={6} className='d-flex flex-column' style={{gap: '0px'}}>
                                            <p className='mb-0 fs-10'>Awesome</p>
                                            <div className='text-dark'>
                                                <FaStar  /><FaStar  /><FaStar  /><FaStar  /><FaStar  />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mb-4'>
                                        <Col xs={6} className='d-flex align-items-center'>
                                            <h6>Communication</h6>
                                        </Col>
                                        <Col xs={6} className='d-flex flex-column' style={{gap: '0px'}}>
                                            <p className='mb-0 fs-10'>Awesome</p>
                                            <div className='text-dark'>
                                                <FaStar  /><FaStar  /><FaStar  /><FaStar  /><FaStar  />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col>
                                    <Row className='mb-3'>
                                        <Col xs={1} className='d-flex flex-column text-center'>
                                            <img width={'100%'} className='rounded-circle mb-2' src='https://source.unsplash.com/random/300x300/?user' />
                                            <h6>John Wick</h6>
                                        </Col>
                                        <Col xs={11}>
                                            <Row>
                                                <Col className='d-flex align-items-center'>
                                                    <FaStar  /><FaStar  /><FaStar  /><FaStar  /><FaStar  /> <p className='mb-0 mx-2'>August, 2023</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <p className='testimonialMessage'>Our trip with Glenn was amazing and everything we hoped for to celebrate my best friend's bachelorette party! Leading up to our trip, Glenn was super responsive, helpful, and friendly. We had some trouble finding the dock and he was patient, dropped us his location, and ensured we found where we needed to go. When we arrived, Glenn was ready to greet us and the boat was spotless. We got out on the water quickly and throughout our trip Glenn and his helper Sam were nothing but gracious. I would highly recommend this boat/charter for your next Hamptons outing!</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Col xs={1} className='d-flex flex-column text-center'>
                                            <img width={'100%'} className='rounded-circle mb-2' src='https://source.unsplash.com/random/300x300/?user' />
                                            <h6>John Wick</h6>
                                        </Col>
                                        <Col xs={11}>
                                            <Row>
                                                <Col className='d-flex align-items-center'>
                                                    <FaStar  /><FaStar  /><FaStar  /><FaStar  /><FaStar  /> <p className='mb-0 mx-2'>August, 2023</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <p className='testimonialMessage'>Boat was amazing and clean, the captain was friendly and he gave awesome suggestions about where to take pictures and showed us around the city in a safe way.</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Col xs={1} className='d-flex flex-column text-center'>
                                            <img width={'100%'} className='rounded-circle mb-2' src='https://source.unsplash.com/random/300x300/?user' />
                                            <h6>John Wick</h6>
                                        </Col>
                                        <Col xs={11}>
                                            <Row>
                                                <Col className='d-flex align-items-center'>
                                                    <FaStar  /><FaStar  /><FaStar  /><FaStar  /><FaStar  /> <p className='mb-0 mx-2'>August, 2023</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <p className='testimonialMessage'>We had the best time!! Highly highly recommend. The boat so nice and spacious and Glenn was a great captain! 10/10 experience.</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <hr/>
                    <Row className='mt-4 cs-bg-light d-flex justify-content-center p-3 m-1'>
                        <Col>
                        <Row>
                            <Col xs={2}>
                            <img width={'100%'} className='rounded-circle mb-2' src='https://source.unsplash.com/random/300x300/?user' />
                            </Col>
                            <Col xs={10} >
                                <h4 className='mb-0'>Glen</h4>
                                <p className='mb-0 aboutText'>I was born and raised in the Virgin Islands and have been boating ever since I was a small child. When I moved to New York City about 17 years ago I had been waiting for the chance to incorporate my former life on the water with my love for the city and it’s finally here. While I certainly love spending time on my boat with friends there is such fulfillment in watching others enjoy themselves out on the water as much as I do. So I wanted to offer the chance to share that with visitors and locals alike. I hope you enjoy your trip with us and I look forward to meeting you!</p>
                            </Col>
                        </Row>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <h4>Features & Details</h4>
                            <Row>
                                <Col xs={6} className='px-3'>
                                    <Row className='cs-bg-light p-3'>
                                        <Col xs={2} className='text-center'>
                                            <CiShoppingTag style={{fontSize: "44px", textAlign: 'center'}}/>
                                        </Col>
                                        <Col xs={4}>
                                            <h6 className='mb-0'>Make</h6>
                                            <p className='mb-0'>Samsung</p>
                                        </Col>
                                        <Col xs={4}>
                                            <h6 className='mb-0'>Model</h6>
                                            <p className='mb-0'>A52</p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={6} className='px-3'>
                                    <Row className='cs-bg-light p-3'>
                                        <Col xs={2} className='text-center'>
                                            <RxWidth style={{fontSize: "44px", textAlign: 'center'}}/>
                                        </Col>
                                        <Col xs={4} className='d-flex flex-column justify-content-center'>
                                            <p className='mb-0 fs-10'>Length</p>
                                            <h6 className='mb-0'>52ft</h6>
                                        </Col>
                                        <Col xs={2} className='text-center'>
                                            <CiCalendar style={{fontSize: "44px", textAlign: 'center'}}/>
                                        </Col>
                                        <Col xs={4} className='d-flex flex-column justify-content-center'>
                                            <p className='mb-0 fs-10'>Year</p>
                                            <h6 className='mb-0'>1997</h6>
                                        </Col>
                                    </Row> 
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col xs="6">
                                    <ul>
                                        <li>Air Conditioning</li>
                                        <li>Bluetooth</li>
                                        <li>Life jackets/required safety gear</li>
                                        <li>Wi-Fi</li>
                                        <li>Depth gauge</li>
                                    </ul>
                                </Col>
                                <Col xs="6">
                                    <ul>
                                        <li>GPS</li>
                                        <li>Fuel type: Diesel</li>
                                        <li>Cuddy Cabin</li>
                                        <li>Bimini</li>
                                        <li>Inboard Engine</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col md={4} className='px-4'>
                    <Row className='card p-3 inqueryBox'>
                        <Col className='d-flex flex-column'>
                            <p className='mb-0'>Starting from</p>
                            <h3>$42<span style={{color: '#a3aeb2'}}>/day</span></h3>
                            <Link to="#" className="btn btn-block btn-primary mt-2"> <i className="material-icons">mail</i>Send Inquiry</Link>
                            
                            <Row className='mt-4'>
                                <Col xs={6}>
                                    <h5>Hourly</h5>
                                </Col>
                                <Col xs={6}>
                                    <h5 style={{color: '#a3aeb2', fontWeight: '400'}}>23 hrs max </h5>
                                </Col>
                            </Row>
                            <hr/>
                            <Row>
                                <Col xs={6}>
                                    <h5>$300/hr</h5>
                                </Col>
                                <Col xs={6}>
                                    <h5 style={{color: '#a3aeb2', fontWeight: '400'}}>23 hrs max </h5>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </section>
    
      
    </>
  )
}
