import React, { useRef, useStat, useEffect, useState } from 'react'
import { Form, Button, Alert, Row, Col, utils } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
import { useNavigate, Link } from "react-router-dom";
import { getUserRoleByEmail } from './utils';


export default function Login() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const { login } = useAuth()

    const [error, setError] = useState('')
    const [redirectTo, setRedirectTo] = useState('')
    const [loading, setLoading] = useState(false)
    const [loggedIn, setLoggedIn] = useState(false)

    let navigate = useNavigate();   


    async function handleSubmit(e) {
        e.preventDefault();
        try {
            setError('');
            setLoading(true);
    
            const params = new URLSearchParams(window.location.search);
            const redirectTo = params.get('redirect_to');
    
            // Fetch user role using the getUserRoleByEmail function
            const userRole = await getUserRoleByEmail(emailRef.current.value);
    
            // If userRole is not null, proceed with login
            console.log('User role:', userRole);
            await login(emailRef.current.value, passwordRef.current.value);
            console.log('logged in');
            setLoggedIn(true);
    
            if (redirectTo) {
                navigate(`/listing/${redirectTo}` || '/');
            } else {
                navigate('/');
            }
        } catch (e) {
            setError(e.message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const redirectTo = params.get('redirect_to');
        if(redirectTo) {
            setRedirectTo(`?redirect_to=${redirectTo}`);
        }
    }, [])
    
    return (
        <>
        {redirectTo && <h5 className='mt-2' style={{fontWeight: 600}}>Please login or create an account to rent a boat.</h5>}
        <p className="auth-description">Please sign-in to your account and continue to the dashboard.<br/>Don't have an account?<br/><Link to={`/auth/signup-renter${redirectTo}`}>Register as Renter</Link> OR  
  <Link to={`/auth/signup-owner${redirectTo}`}> Register as Boat Owner</Link></p>
		    <div className="row">
                <div className="col">
                    {loggedIn && <Alert variant="success">You have been successfully logged in!</Alert>}
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Col>
                                <Form.Group id="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" className="form-control" ref={emailRef} required />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Form.Group id="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" className="form-control" ref={passwordRef} required />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='d-flex justify-content-end'>
                                <Link to="/auth/forgot-password" className='text-muted text-decoration-underline mb-3' style={{ marginTop: '-11px'}}>Forgot Password?</Link>
                            </Col>
                        </Row>
                        <Button disabled={loading} className="w-100" type="submit">Login</Button>
                    </Form>
                </div>
            </div>
        </>
    )
}
