import React from 'react'
import { addCoupon, getAllCouponCodes, updateCoupon, deleteCoupon } from '../utils';
import { useState, useEffect } from 'react';
import DataTable from '../DataTable';
import { Button, Modal, Form } from 'react-bootstrap';

export default function Coupons() {
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [code, setCode] = useState('');
    const [amount, setAmount] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const [editedCode, setEditedCode] = useState('');
    const [editedAmount, setEditedAmount] = useState('');


    const fetchData = async () => {
        try {
            const allCouponCodesData = await getAllCouponCodes();
            console.log("allCouponCodesData",allCouponCodesData);
            setData(allCouponCodesData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleEditCouponClick = (row) => {
        setSelectedCoupon(row);
        setEditedCode(row.coupon);
        setEditedAmount(row.discount);
        setShowEditModal(true);
    };
    
    const handleDeleteCouponClick = (row) => {
        setSelectedCoupon(row);
        setShowDeleteModal(true);
    };

    const handleCloseEditModal = () => {
        setShowEditModal(false);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };


    useEffect(() => {
        fetchData();
    }, []);


    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateCoupon(selectedCoupon.id, editedCode, parseFloat(editedAmount));
            fetchData(); // Refresh coupon data
            handleCloseEditModal();
        } catch (error) {
            console.error('Error updating coupon:', error);
        }
    };

    const handleConfirmDelete = async () => {
        try {
            await deleteCoupon(selectedCoupon.id);
            fetchData(); // Refresh coupon data
            handleCloseDeleteModal();
        } catch (error) {
            console.error('Error deleting coupon:', error);
        }
    };

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const handleAddCouponClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    const columns = [
        {
          Header: 'ID',
          accessor: 'id',
        },
        {
          Header: 'Code',
          accessor: 'coupon',
        },
        {
          Header: 'Discounted Amount $',
          accessor: 'discount',
          Cell: ({ value }) => formatter.format(value)
        },
        {
            Header: 'Actions',
            Cell: ({ row }) => (
                <>
                    <Button variant="primary" onClick={() => handleEditCouponClick(row.original)}>Edit</Button>
                    <Button variant="danger" onClick={() => handleDeleteCouponClick(row.original)}>Delete</Button>
                </>
            ),
        },
    ];

    const handleRowClick = (row) => {
    
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await addCoupon(code, parseFloat(amount.replace(/[$,]/g, '')));
            // Assuming amount is already in USD format with separators
            handleCloseModal();
            fetchData();

            setCode('');
            setAmount('');
        } catch (error) {
            console.error('Error adding coupon:', error);
        }
    };

    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="page-description d-flex justify-content-between">
                        <h1>All Coupon Codes</h1>
                        <Button onClick={handleAddCouponClick}>Add Coupon Code</Button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="card calendar-container">
                        <div className="card-body payments-data-table">
                            <DataTable columns={columns} data={data}  onRowClick={handleRowClick}/>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Coupon Code</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>
                <Modal.Body>
                    
                        <Form.Group controlId="couponCode">
                            <Form.Label>Coupon Code</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter coupon code"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="couponAmount">
                            <Form.Label>Amount (USD)</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="primary" type="submit">
                        Add Coupon
                    </Button>
                </Modal.Footer>
                </Form>
            </Modal>


            <Modal show={showEditModal} onHide={handleCloseEditModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Coupon Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleEditSubmit}>
                        <Form.Group controlId="editedCouponCode">
                            <Form.Label>Coupon Code</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter coupon code"
                                value={editedCode}
                                onChange={(e) => setEditedCode(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="editedCouponAmount">
                            <Form.Label>Amount (USD)</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter amount"
                                value={editedAmount}
                                onChange={(e) => setEditedAmount(e.target.value)}
                            />
                        </Form.Group>
                        <div className='mt-3 d-flex gap-2 justify-content-end'>
                            <Button variant="secondary" onClick={handleCloseEditModal}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit">
                                Update Coupon
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this coupon?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleConfirmDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
