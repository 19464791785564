// SignupOwner.js
import { getDatabase, ref, set, update } from 'firebase/database';

import { useAuth } from '../contexts/AuthContext';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Button, Form, Row, Col } from 'react-bootstrap';
import Cropper from "react-cropper"; // Import Cropper component and types
import "cropperjs/dist/cropper.css"; // Import Cropper CSS
import { RenterVerificationRejectedEmailBody, getAllRenterSelectionQuestions, getAllRenterSignupQuestions, getAllRenterTextQuestions, getPlatformPercentages, newUserAddedAdminEmailBody, sendEmail, sendSMS, welcomeEmailBody } from './utils'
import { Alert } from '@mui/material';

const SignupOwner = () => {
  const navigate = useNavigate();
  const { signup } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const cropperRef = useRef(null); // Create a ref to the Cropper component
  const [croppedImage, setCroppedImage] = useState(null);

  const serverUrl = 'https://www.bbrbassboatrentals.com';

  const [phoneNo, setPhoneNo] = useState("")
  const [textQuestionAnswers, setTextQuestionAnswers] = useState([]);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    password: '',
    avatar: '',
  });


  const [multiChoiceQuestions, setMultiChoiceQuestions] = useState([]);
  const [allQuestionsData, setAllQuestions] = useState([]);
  const [textQuestions, setTextQuestions] = useState([]);
  // const [pendingVerification, setHandleVerification] = useState(false);

  // const handlePendingVerification = (event) => {
  //   setHandleVerification(event.target.checked);
  // };

  const [avatar, setAvatar] = useState(null);
  const onCrop = () => {
    const cropper = cropperRef.current.cropper; // Access the Cropper instance through the ref
    setCroppedImage(cropper.getCroppedCanvas().toDataURL());
  };

  const handleUploadAvatar = async () => {
    if (croppedImage) {
      // Convert the base64 image to a Blob
      const blob = await fetch(croppedImage).then((res) => res.blob());
      console.log(blob);
      // Create FormData and append the file
      const formData = new FormData();
      formData.append('file', blob);
  
      try {
        const response = await fetch(`${serverUrl}/upload-featured-image`, {
          method: 'POST',
          body: formData,
        });
  
        if (response.ok) {
          const responseData = await response.json();
          setFormData((prevUserValues) => ({
            ...prevUserValues,
            avatar: `${serverUrl}/images/${responseData.file_name}`,
          }));
          setAvatar(null);
        } else {
          console.error('File upload failed');
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    } else {
      console.error('No cropped image available');
    }
  };


  const handleSelectImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setAvatar(reader.result); // Set the file URL as the avatar
    };
    reader.readAsDataURL(file); // Convert the file to a data URL
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      try {

        const allQuestionsData = await getAllRenterSignupQuestions();
        setAllQuestions(allQuestionsData);

        console.log('allQuestionsData');
        console.log(allQuestionsData);

        const questionsData = await getAllRenterSelectionQuestions();
        
        const questionsArray = Object.values(questionsData);
        setMultiChoiceQuestions(questionsArray);

        const textQuestionsData = await getAllRenterTextQuestions();
        const testQuestionsArray = Object.values(textQuestionsData);
        setTextQuestions(testQuestionsArray);
        
      } catch (error) {
        console.error('Error fetching Questions:', error);
      }
    };

    fetchQuestions();
  }, []);
  
  // const handleChange = (e, question) => {
  //   const { name, value } = e.target;

  //   if (name.startsWith('question_')) {
  //     // Update textfield answers
  //     const questionIndex = parseInt(name.split('_')[1], 10);
  //     const existingAnswerIndex = textQuestionAnswers.findIndex(
  //       (answer) => answer.questionText === textQuestions[questionIndex]
  //     );

  //     if (existingAnswerIndex !== -1) {
  //       // If an answer exists, update the existing answer
  //       setTextQuestionAnswers((prevAnswers) => {
  //         const updatedAnswers = [...prevAnswers];
  //         updatedAnswers[existingAnswerIndex] = {
  //           questionText: textQuestions[questionIndex],
  //           answer: value,
  //         };
        
  //         return updatedAnswers;
  //       });
  //     } else {
  //       // If no answer exists, add a new answer to the array
  //       setTextQuestionAnswers((prevAnswers) => [
  //         ...prevAnswers,
  //         {
  //           questionText: textQuestions[questionIndex],
  //           answer: value,
  //         },
  //       ]);
  //     }
  //   } else {
  //     // Update other form data
  //     setFormData((prevData) => ({ ...prevData, [name]: value }));
  //   }
  //   console.log(textQuestionAnswers);
  //   console.log(formData);
  // };


  const handleChange = (e, question) => {
    const { name, value } = e.target;
  
    if (name.startsWith('question_')) {
      // Update textfield answers
      const questionIndex = parseInt(name.split('_')[1], 10);
      const existingAnswerIndex = textQuestionAnswers.findIndex(
        (answer) => answer.questionText === allQuestionsData[questionIndex].questionText
      );
  
      if (existingAnswerIndex !== -1) {
        // If an answer exists, update the existing answer
        setTextQuestionAnswers((prevAnswers) => {
          const updatedAnswers = [...prevAnswers];
          updatedAnswers[existingAnswerIndex] = {
            questionText: allQuestionsData[questionIndex].questionText,
            answer: value,
          };
          return updatedAnswers;
        });
      } else {
        // If no answer exists, add a new answer to the array
        setTextQuestionAnswers((prevAnswers) => [
          ...prevAnswers,
          {
            questionText: allQuestionsData[questionIndex].questionText,
            answer: value,
          },
        ]);
      }
    } else {
      // Update other form data
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  

  async function handleSubmit(e) {
    e.preventDefault();
  
    try {
      setError("");
      setLoading(true);

      const params = new URLSearchParams(window.location.search);
      const redirectTo = params.get('redirect_to');

      // if(!pendingVerification) {
      //   setError('You must acknowledge before proceeding.');
      //   return;
      // }
      
      const userCredential = await signup(formData.email, formData.password);
      const userId = userCredential.user.uid; // Assuming Firebase auth returns a user object with uid
  
      const platformSettings = await getPlatformPercentages();
      const userSuffixNew = parseInt((platformSettings.userSuffix))+1;
      const bbrID = 'BBR'+userSuffixNew;

      const setLastSuffix = ref(getDatabase(), `portalSettings/settingsID`);
      await update(setLastSuffix, {
        userSuffix: userSuffixNew,
      });


      var userStatus = 'unset';
      var userRejectionReason = 'None';
      console.log('bbrID');
      console.log(bbrID);
      console.log("age", formData.age);
      
      
      if(parseInt(formData.age) < 21){
        userStatus = 'Reject';
        userRejectionReason = "Age requirment does not meet";
      }

      console.log("userStatus", userStatus);
      const userRef = ref(getDatabase(), `users/${userId}`);
      await set(userRef, {
        bbrId: bbrID,
        firstName: formData.firstName,
        lastName: formData.lastName,
        avatar: formData.avatar,
        email: formData.email,
        phone: phoneNo,
        role: "Renter",
        rejectionReason: userRejectionReason,
        status: userStatus,
      });
  
      const userMetaRef = ref(getDatabase(), `userMeta/${userId}`);
      await set(userMetaRef, {
        age: formData.age,
        userAnswers: {
          multiChoiceQuestions: allQuestionsData
            .filter(question => question.questionType === "option")
            .map((question, index) => {
              const questionIndex = allQuestionsData.findIndex(q => q.questionText === question.questionText);
              return {
                questionIndex,
                question: question.questionText,
                answer: selectedOptions.find(option => option.questionText === question.questionText)?.option || "",
              };
            }),
          textQuestions: allQuestionsData
            .filter(question => question.questionType === "text")
            .map((question, index) => {
              const questionIndex = allQuestionsData.findIndex(q => q.questionText === question.questionText);
              return {
                questionIndex,
                question: question.questionText,
                answer: textQuestionAnswers.find(answer => answer.questionText === question.questionText)?.answer || "",
              };
            }),
        },         
      });

      const userFullName = formData.firstName+' '+formData.lastName;
      const subject = 'Welcome Aboard! '+userFullName;
      const to= formData.email;
      const url = 'https://bbrbassboatrentals.com/';

      if(parseInt(formData.age) < 21){
        const rejectSubject = 'You have received a new message from BBR';
        const reason  = "Age requirment does not meet";
        const bodyData = await RenterVerificationRejectedEmailBody(userFullName, url, reason);
        const emailSent = await sendEmail(to, rejectSubject, bodyData);

        navigate('/approval-rejected');
      }else{
        const smsBody = `Welcome Aboard ${userFullName}, your account has been registered successfully.\n\nhttps://www.bbrbassboatrentals.com/auth`
        const smsResponse = sendSMS(formData.phone, smsBody);
        console.log(smsResponse)
        // const emailBodyNew = await welcomeEmailBody(userFullName);
        // const emailSent = await sendEmail(to, subject, emailBodyNew);
        // const adminEmailBody = await newUserAddedAdminEmailBody('Renter', bbrID, userFullName, to);
        // const adminEmailSent = await sendEmail('waivers@bbrbassboatrentals.com', 'New Renter Registered.', adminEmailBody);
        
        if(redirectTo) {
          navigate(`/listing/${redirectTo}`);
        } else {
          navigate('/approval-pending');
        }
      }
  
      
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }
  
  
  const [selectedOptions, setSelectedOptions] = useState([]);

  // const handleRadioChange = (questionText, option) => {
  //   // Check if an option for the same question already exists
  //   const existingOptionIndex = selectedOptions.findIndex(
  //     (selectedOption) => selectedOption.questionText === questionText
  //   );
  
  //   if (existingOptionIndex !== -1) {
  //     // If an option exists, update the existing option
  //     setSelectedOptions((prevOptions) => {
  //       const updatedOptions = [...prevOptions];
  //       updatedOptions[existingOptionIndex] = {
  //         questionText,
  //         option,
  //       };
  //       return updatedOptions;
  //     });
  //   } else {
  //     // If no option exists, add a new option to the array
  //     setSelectedOptions((prevOptions) => [
  //       ...prevOptions,
  //       {
  //         questionText,
  //         option,
  //       },
  //     ]);
  //   }
  // };

  const handleRadioChange = (questionText, option) => {
    // Check if an option for the same question already exists
    const existingOptionIndex = selectedOptions.findIndex(
      (selectedOption) => selectedOption.questionText === questionText
    );
  
    if (existingOptionIndex !== -1) {
      // If an option exists, update the existing option
      setSelectedOptions((prevOptions) => {
        const updatedOptions = [...prevOptions];
        updatedOptions[existingOptionIndex] = {
          questionText,
          option,
        };
        return updatedOptions;
      });
    } else {
      // If no option exists, add a new option to the array
      setSelectedOptions((prevOptions) => [
        ...prevOptions,
        {
          questionText,
          option,
        },
      ]);
    }
  };
  
  const [redirectTo, setRedirectTo] = useState('')
  const [redirectId, setRedirectId] = useState('')
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const redirect_to = params.get('redirect_to');
        if(redirect_to) {
            setRedirectTo(`?redirect_to=${redirect_to}`);
            setRedirectId(redirect_to);
        }
    }, [])


  return (

    <>

    <p className="auth-description">Please enter your credentials to create an account.<br/>Already have an account? <Link to={`/auth${redirectTo}`}>Sign In</Link></p>

    <div className="auth-credentials m-b-xxl">
    <Row>
      <Col>
        <Form onSubmit={handleSubmit}>
      {error && <Alert severity="error">{error}</Alert>}
            <Form.Group controlId="firstName" className='mt-4'>
              <Row className='mb-2'>
                <Col xs={6}>
                  <Form.Label className="form-label">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className='form-control'
                    required
                  />
                </Col>
                <Col xs={6}>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className='form-control'
                    required
                  />
                </Col>
              </Row>

              <Row className='mb-2'>
              <Col xs={12}>
                <Form.Label className="form-label">Profile Picture</Form.Label>
                <Form.Control
                  type="file"
                  name="avatar"
                  onChange={(e) => handleSelectImage(e)}
                  accept="image/*"
                />
                {avatar && (
                  <>
                  <Cropper
                    src={avatar} // Image source
                    className="mt-3"
                    style={{ height: 400, width: "100%" }} // Styling for Cropper container
                    initialAspectRatio={16 / 9} // Initial aspect ratio for cropping
                    guides={false} // Display cropping guides
                    crop={onCrop} // Callback function triggered after cropping
                    ref={cropperRef} // Assign the ref to the Cropper component
                  />
                  <Button className='mt-3' onClick={handleUploadAvatar}>Finish Cropping</Button>
                  </>
                )}
              </Col>
            </Row>
              
              <Row className='mb-2'>
                <Col xs={6}>
                  <Form.Label>Age</Form.Label>
                  <Form.Control
                    type="number"
                    name="age"
                    min={1}
                    max={150}
                    value={formData.age}
                    className='form-control'
                    onChange={handleChange}
                    required
                  />
                </Col>
                <Col xs={6}>
                  <Form.Label>Phone</Form.Label>
                  <PhoneInput
                    country={'us'}
                    value={phoneNo}
                    onChange={(value) => setPhoneNo(value)}
                  />
                </Col>
              </Row>
              
              <Row className='mb-2'>
                <Col xs={6}>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className='form-control'
                    required
                  />
                </Col>
                <Col xs={6}>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    className='form-control'
                    required
                  />
                </Col>
              </Row>

            </Form.Group>
            {allQuestionsData.map((question, index) => (
              <Form.Group key={index}>
                {question.questionType === "option" ? (
                  <>
                    <Form.Label className="mt-2">{question.questionText}</Form.Label>
                    <div className="d-flex mcq-renter flex-wrap">
                      {question.options.map((option, optionIndex) => (
                        <div key={optionIndex} className="mb-4">
                          <Form.Check
                            type="radio"
                            style={{ textAlign: 'start' }}
                            label={option}
                            className="btn btn-light m-1"
                            name={`question_${index}`}
                            id={`option_${index}_${optionIndex}`}
                            onChange={() => handleRadioChange(question.questionText, option)}
                          />
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    <Form.Label className="mt-3">{question.questionText}</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name={`question_${index}`}
                      onChange={(e) => handleChange(e, question)}
                      required
                    />
                  </>
                )}
              </Form.Group>
            ))}
            {error && <Alert severity="warning">{error}</Alert>}
            <Row>
              <Col>
                <div className="auth-submit mt-4">
                  <Button className="mx-2" variant="primary" type="submit">
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
        </Form>
      </Col>
    </Row>
    </div>

    </>
  );
};

export default SignupOwner;

