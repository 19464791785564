import React from 'react'
import { getAllUsersDetails, getAllUsersMeta, getOwnerMeta } from '../utils';
import { useState, useEffect } from 'react';
import DataTable from '../UsersDataTable';

export default function PendingProMembers() {
    const [allUsers, setAllUsers] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const allUsersData = await getAllUsersDetails();
                const linkedData = await linkUsersAndUsersMeta(allUsersData);

                console.log('linkedData', linkedData);
                setData(linkedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const linkUsersAndUsersMeta = async (allUsers) => {
        const linkedData = [];
        for (const user of allUsers) {
            if (user.role === "Boat Owner") {
                try {
                    const ownerMeta = await getOwnerMeta(user.userId);
                    if (ownerMeta !== null && ownerMeta.proStaffStatus === "Pending") {
                        const fullName = `${user.firstName} ${user.lastName}`;
                        linkedData.push({ ...user, name: fullName, ownerMeta });
                    }
                } catch (error) {
                    console.error('Error fetching owner meta:', error);
                }
            }
        }
        return linkedData;
    };


    const columns = [
        {
          Header: 'BBR ID',
          accessor: 'bbrId',
        },
        {
          Header: 'Full Name',
          accessor: 'name',
        },
        {
          Header: 'Phone',
          accessor: 'phone',
        },
        {
            Header: 'Email',
            accessor: 'email',
          },
        // Add more columns as needed
    ];

    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="page-description">
                        <h1>Pro Member Requests</h1>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="card calendar-container">
                        <div className="card-body">
                            <DataTable columns={columns} data={data}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
