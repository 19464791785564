// Payment.js
import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import TipPaymentForm from '../TipPaymentForm';
import { getBookingByID } from '../utils';
import { loadStripe } from '@stripe/stripe-js';


const stripePromise = loadStripe('pk_live_51OP31wGUDI27aRLukPP75NEf9vM9xP8zu8sIC5Nw20LUvA7qfrFHe5OCEvtjMtGLUEYEwLcp4d4sdu5eiNfrDtm2006843MTXC');


export default function TipPayment({bookingId}) {

    const [bookingData, setBookingData] = useState(null);


    useEffect(() => {
      const fetchDetails = async () => {
          try {
            const bookingDetails = await getBookingByID(bookingId);
            setBookingData(bookingDetails);
          } catch (error) {
            console.error('Error fetching listing details:', error);
          }
      };
      fetchDetails();
  }, []);

  return (
    <>
      <div className="row w-100">
        <div className="col">
              <div className='mt-4 d-flex justify-content-center align-items-center'>
                <Elements stripe={stripePromise}>
                  <TipPaymentForm bookingId={bookingId}/>
                </Elements>
              </div>
            </div>
      </div>
    </>
  );
}
