import React, { useEffect, useState } from 'react'
import { getPlaceReviews } from '../utils';
import { Col, Row } from 'react-bootstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import StarRating from './StarRating';

export default function GoogleReviews() {
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        const fetchUserData = async () => {
          try {
            const reviewsData = await getPlaceReviews();
            console.log('reviewsData');
            console.log(reviewsData);
            setReviews(reviewsData);
          } catch (error) {
            console.error(error.message);
          }
        };
    
        fetchUserData();
      }, []); 

      const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000, // Set the autoplay speed in milliseconds
        pauseOnHover: true, // Pause on mouse hover
      };

    
  return (
    <>
    <Row>
        <Col className='reviews-container'>
            {reviews.length > 0 ? (
                <Slider {...settings}>
                  {reviews.map((review, index) => (
                    <div key={index} className='review-item'>
                      <Row>
                        <Col className='px-4'>
                          <Row className='mb-2'>
                            {/* <Col xs={1} className='p-0'>
                              <img width={'100%'} src={review.profile_photo_url} alt={`${review.author_name}'s profile`} />
                            </Col> */}
                            <Col xs={12} className='justify-content-center d-flex flex-column p-0'>
                              <h5 className='text-white  mb-0'>{review.author_name}</h5>
                              <p className='review-time mb-0'>{review.relative_time_description}</p>
                              <div className='stars-container'>
                                <StarRating rating={review.rating} />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col className='p-0'>
                              <p className='review-text text-white'>{review.text}</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </Slider>
            ) : (
              <p className='text-white'></p>
            )}
        </Col>
    </Row>
    </>
  )
}
