import React, { useState, useEffect } from 'react'
import { Button, Col, Container, FormControl, FormGroup, FormLabel, Row, Form } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2'
import { Link, useNavigate } from 'react-router-dom';
import { contactUsEmail, sendEmail } from '../utils';
import FloatingButtons from './FloatingButtons';

export default function ContactUs() {
    useEffect(() => {
    window.scrollTo(0, 0);
    }, [])
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [message, setMessage] = useState("");

    const Navigate = useNavigate();

    async function handleSubmit (e) {
        e.preventDefault();
        const name = firstName + ' ' + lastName;
        const bodyData = await contactUsEmail(name, email, phoneNo, zipcode, message);
        sendEmail ('info@bbrbassboatrentals.com', 'New Submission Received', bodyData);
        Navigate('/');
    }
  return (
    <>
    <FloatingButtons/>
    <section id='contact' className='cs-bg-dark py-4 cs-section'>
        <Container>
            <Row>
                <Col>
                    <h1 className='fw-bold text-white'>Contact Us</h1>
                </Col>
            </Row>
            <Row className='mt-4 mobile-direction-column'>
                <Col xs={12} md={8}>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={6} className='mt-3'>
                                <FormGroup>
                                    <Form.Label className="text-white">First Name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="firstName"
                                      className='form-control'
                                      value={firstName}
                                      onChange={(e) => setFirstName(e.target.value)}
                                      required
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={6} className='mt-3'>
                                <FormGroup>
                                    <Form.Label className="text-white">Last Name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="lastName"
                                      value={lastName}
                                      onChange={(e) => setLastName(e.target.value)}
                                      className='form-control'
                                      required
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} className='mt-3'>
                                <FormGroup>
                                    <Form.Label className="text-white">Email</Form.Label>
                                    <Form.Control
                                      type="email"
                                      name="email"
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                      className='form-control'
                                      required
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={6} className='mt-3'>
                                <FormGroup>
                                    <Form.Label className="text-white">Phone</Form.Label>
                                    <PhoneInput
                                      country={'us'}
                                      value={phoneNo}
                                      onChange={(value) => setPhoneNo(value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={6} className='mt-3'>
                                <FormGroup>
                                    <Form.Label className="text-white">Zip Code</Form.Label>
                                    <Form.Control
                                      type="text"
                                      name="zipcode"
                                      value={zipcode}
                                      onChange={(e) => setZipcode(e.target.value)}
                                      className='form-control'
                                      required
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} className='mt-3'>
                                <FormGroup>
                                    <Form.Label className="text-white">Message</Form.Label>
                                    <Form.Control
                                      as='textarea'
                                      rows={4}
                                      value={message}
                                      onChange={(e) => setMessage(e.target.value)}
                                      placeholder='Type your message here...'
                                      name="message"
                                      className='form-control'
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                            <Button className='my-2 btn-light ' variant="primary" type="submit">Submit</Button>
                            </Col>
                        </Row>
                        
                    </Form>
                </Col>
                <Col xs={12} md={4} className='text-white padding-mobile-0 px-4'>
                    <Row>
                        <Col className='d-flex flex-column'>
                            <h2>BBR Bass Boat Rentals, LLC</h2>
                            <p>96 Beach Walk Blvd, Conroe, Texas 77304, United States</p>
                            <Link  to={'tel:9362767787'} className='contactLink text-white'>936-276-7787</Link>
                            <Link to={'mailto:info@BBRBassBoatRentals.com'} className='contactLink text-white'>info@BBRBassBoatRentals.com</Link>
                        </Col>
                    </Row>
                    {/* <Row className='mt-4'>
                        <Col className='d-flex flex-column mt-4'>
                            <h2>Hours</h2>
                            <p>Mon 08:00 am – 05:00 pm</p>
                            <p>Tue 08:00 am – 05:00 pm</p>
                            <p>Wed 08:00 am – 05:00 pm</p>
                            <p>Thu 08:00 am – 05:00 pm</p>
                            <p>Fri 08:00 am – 05:00 pm</p>
                            <p>Sat 08:00 am – 05:00 pm</p>
                            <p>Sun By Appointment</p>
                        </Col>
                    </Row> */}
                </Col>
            </Row>
        </Container>
    </section>
    
    </>
  )
}
