import React from 'react'
import { Col, Row } from 'react-bootstrap'
import FloatingButtons from './FloatingButtons'
import { Link } from 'react-router-dom'

export default function CookiePolicy() {
  return (
    <>
    <FloatingButtons/>
    <section className='cs-bg-dark py-4 cs-section text-white privacy-policy'>
      <div className='container'>
        <Row className='mb-4'>
        <Col> 
          <Row className='mt-4'>
            <Col>
              <h1 >Cookies Policy</h1>
              <h4  >EFFECTIVE MAY 31st 2021</h4>
              <p >At BBR BASS BOAT RENTALS, we believe in being clear and open about how we collect and use data related to you. In the spirit of transparency, this policy provides detailed information about how and when we use cookies. This cookie policy applies to any BBR BASS BOAT RENTALS product or service that links to this policy or incorporates it by reference.</p>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col>
              <h5  >Does BBR BASS BOAT RENTALS use cookies?</h5>
              <p >Yes. As described in our Privacy Policy, we use cookies and other technologies to ensure everyone who uses BBR BASS BOAT RENTALS has the best possible experience. Cookies also help us keep your account safe. By continuing to visit or use our services, you are agreeing to the use of cookies and similar technologies for the purposes we describe in this policy.</p>
              
              <h5  >What is a cookie?</h5>
              <p >A cookie is a small file placed onto your device that enables BBR BASS BOAT RENTALS features and functionality. For example, cookies enable us to identify your device, secure your access to BBR BASS BOAT RENTALS and our sites generally, and even help us know if someone attempts to access your account from a different device. Cookies also enable you to easily share content on BBR BASS BOAT RENTALS and help us serve relevant ads to you.</p>
            
              <h5  >When does BBR BASS BOAT RENTALS place cookies?</h5>
              <p >
              We use cookies on our site and mobile applications. Any browser visiting these sites will receive cookies from us.
              </p>
              <h5  >What types of cookies does BBR BASS BOAT RENTALS use?</h5>
              <p >
              We use two types: persistent cookies and session cookies. A persistent cookie helps us recognize you as an existing user, so it’s easier to return to BBR BASS BOAT RENTALS or interact with our services without signing in again. After you sign in, a persistent cookie stays in your browser and will be read by BBR BASS BOAT RENTALS when you return to one of our sites or a partner site that uses our services (for example, our sharing or job application buttons). Session cookies only last for as long as the session (usually the current visit to a website or a browser session).                
                </p>
                <h5  >What are cookies used for?</h5>
              <p >
              Cookies can be used to recognize you when you visit BBR BASS BOAT RENTALS, remember your preferences, and give you a personalized experience that’s in line with your settings. Cookies also make your interactions with BBR BASS BOAT RENTALS faster and more secure.
             </p>
              <p >
              You acknowledge and agree to take all reasonable precautions in ensuring the accuracy and reliability of any User generated content. We are also not responsible for the condition of any boats listed on the Website or the compliance with laws, rules or regulations that may be applicable to boat operation, maintenance, rental or charter in any jurisdiction. Renters should satisfy themselves that any boat rented complies with any legal or safety requirements for such rental, and Renters and Members have exclusive responsibility for such compliance.
              </p>
             <h1 >Categories of Use</h1>

             <h4  >AUTHENTICATION</h4>
             <p >
             If you’re signed in to BBR BASS BOAT RENTALS, cookies help us show you the right information and personalize your experience.             
            </p>

             <h4  >SECURITY</h4>
             <p >
             We log usage data when you visit or otherwise use our Services, including our sites and apps, such as when you view or click on content (e.g. boat listings), perform a search, or install or update one of our mobile apps. We use log-ins, cookies, device information and internet protocol ("IP") addresses to identify you and log your use.
             </p>
             <h4  >Performance, Analytics and Research</h4>
              <p >
              Cookies help us learn how well our site and plugins perform in different locations. We also use cookies to understand, improve, and research products, features, and services, including when you access BBR BASS BOAT RENTALS from other websites, applications, or devices such as your work computer or your mobile device.
              </p>

              <h4>COOKIES</h4>
              <p >
              As further described in our <Link  to='https://www.bbrbassboatrentals.com/cookie-policy/'>Cookies Policy</Link>, we use cookies and similar technologies (e.g. pixels and device identifiers) to recognize you and/or your device(s) when using our Services across different and devices. We also allow certain third parties to use cookies as described in our Cookie Policy. For information regarding your choices with respect to cookies, please see the Lawful Bases for Processing section and our Cookie Policy.

              </p>

              <h4  >What is Do Not Track (DNT)?</h4>
              <p >DNT is a concept that has been promoted by regulatory agencies such as the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing Internet users to control the tracking of their online activities across websites by using browser settings. The World Wide Web Consortium (W3C) has been working with industry groups, Internet browsers, technology companies, and regulators to develop a DNT technology standard. While some progress has been made, it has been slow. No standard has been adopted to this date. As such, BBR BASS BOAT RENTALS does not generally respond to “do not track” signals.</p>
              <h4  >Third-party cookies</h4>
              <p >
              Third-party cookies refer to cookies placed by other services when you visit BBR BASS BOAT RENTALS. We use third-party cookies for purposes such as analytics and fraud prevention. Please note that the names of cookies, pixels and other technologies may change over time.
              </p>
            

              <h4  >Controlling cookies</h4>
              <p >Most browsers allow you to control cookies through their settings preferences. However, if you limit the ability of websites to set cookies, you may worsen your overall user experience, since it will no longer be personalized to you. It may also stop you from saving customized settings like login information.
                </p>
              <h4  >YOUR USE OF SERVICES</h4>
              <p >
              Most browsers allow you to control cookies through their settings preferences. However, if you limit the ability of websites to set cookies, you may worsen your overall user experience, since it will no longer be personalized to you. It may also stop you from saving customized settings like login information.
              </p>


              <h4  >What to do if you don’t want cookies to be set or want them to be
removed?</h4>
              <p >If you do not want to receive cookies, you can change your browser settings on your computer or other device you’re using to access our services. If you use BBR BASS BOAT RENTALS without changing your browser settings, we’ll assume that you’re happy to receive all cookies on the BBR BASS BOAT RENTALS website. Most browsers also provide functionality that lets you review and erase cookies, including BBR BASS BOAT RENTALS cookies. Please note that the BBR BASS BOAT RENTALS site will not work properly without cookies.</p>
              <p>To find out more about cookies, including how to see what cookies
have been set and how to manage and delete
them, visit <a href="https://wikipedia.org">wikipedia.org</a>, <a href='www.allaboutcookies.org'>www.allaboutcookies.org</a>,
or <a href='www.aboutcookies.org'>www.aboutcookies.org</a>.</p>
            </Col>
          </Row>
          
        </Col>
        </Row>
     
      </div>
    </section>

    
    </>
  )
}
