import React from 'react'

export default function Profile() {
  return (
    <>
    <div class="row">
        <div class="col">
            <div class="page-description">
                <h1>Profile</h1>
            </div>
        </div>
    </div>
    </>
  )
}
