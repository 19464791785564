import React, { useEffect, useState } from "react";
import {
  BookingStatusUpdateEmailBody,
  getBookingByID,
  sendWaiverBody,
  sendEmail,
  getCurrentUserDetails,
  getDisputeDetailsById,
  getInquiryDataById,
  getListingByID,
  getPaymentById,
  getUserByID,
  updateBookingStatus,
  updateDisputeStatus,
  updateHeldPaymentStatus,
  BookingCompletedWithDisputeRenterEmailBody,
  BookingCompletedWithDisputeOwnerEmailBody,
  getPendingPaymentById,
} from "../utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import { Button } from "react-bootstrap";
import { Form, Col, Modal, Row } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import TextField from "@mui/material/TextField";
import { useAuth } from "../../contexts/AuthContext";
import {
  getDatabase,
  ref,
  onValue,
  get,
  push,
  update,
  serverTimestamp,
} from "firebase/database";
import { FaRegCopy } from "react-icons/fa";
import { RiMailSendLine } from "react-icons/ri";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";

export default function Event() {
  const { dynamicId } = useParams();

  const Navigate = useNavigate();

  const [offerStartDateTime, setOfferStartDateTime] = useState(dayjs());

  const [waiverType, setWaiverType] = useState("");
  const [bookingData, setBookingData] = useState(null);
  const [timeStamp, setTimeStamp] = useState(null);
  const [ownerData, setOwnerData] = useState(null);
  const [renterData, setRenterData] = useState(null);
  const [inquiryData, setInquiryData] = useState(null);
  const [listingData, setListingData] = useState(null);
  const [userData, setUserData] = useState(null);

  const [dropOffLocation, setDropOffLocation] = useState("");
  const [pickUpLocation, setPickUpLocation] = useState("");
  const [disputeAmount, setDisputeAmount] = useState(0);

  const [paymentDetails, setPaymentDetails] = useState(null);

  const [pendingPaymentDetails, setPendingPaymentDetails] = useState(null);

  const [disputeDetails, setDisputeDetails] = useState([]);

  const [isDisuputeModalOpen, setDisputeModalOpen] = useState(false);
  const [waiverReceiverEmail, setWaiverReceiverEmail] = useState("");

  const [disputeReason, setDisputeReason] = useState("");

  const closeDisputeModal = () => {
    setDisputeModalOpen(false);
  };

  const openDisputeModal = () => {
    setDisputeModalOpen(true);
  };

  const [showSendWaiverEmailModal, setSendWaiverEmailModal] = useState(false);

  const [success, setSuccess] = useState("");

  const openSendWaiverEmailModal = () => {
    setSendWaiverEmailModal(true);
  };

  const closeSendWaiverEmailModal = () => {
    setSendWaiverEmailModal(false);
  };

  const [isRequestDisuputeModalOpen, setRequestDisputeModalOpen] =
    useState(false);

  const [error, setError] = useState("");

  const closeRequestDisputeModal = () => {
    setRequestDisputeModalOpen(false);
  };

  const openRequestDisputeModal = () => {
    setRequestDisputeModalOpen(true);
  };

  const [currentUserDetails, setCurrentUserDetails] = useState([]);

  const auth = useAuth();

  const handleStartTrip = async () => {
    try {
      Navigate("/dashboard/startTrip/" + dynamicId);
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };
  const handleCancelBooking = async () => {
    try {
      const pendingPaymentRef = ref(
        getDatabase(),
        `pendingPayments/${bookingData.paymentId}`
      );
      await update(pendingPaymentRef, { status: "cancelled" });
      await updateBookingStatus(dynamicId, "cancelled");
      setError("Your booking has been cancelled");
      closeCancelBookingModal();
      Navigate(0)
    } catch (error) {
      console.log("Error: ", error.message);
    }
  };

  const getLocationName = async (dropoff, pickUp) => {
    try {
      const dropoffLat = dropoff.lat;
      const dropoffLlng = dropoff.lng;

      const pickUpLat = pickUp.lat;
      const pickUpLlng = pickUp.lng;

      const dropOffResponse = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${dropoffLat},${dropoffLlng}&key=AIzaSyBfEcoyW9DM7QQWdV3oTjevrfyhX5n5qqg`
      );

      const dropOffdata = await dropOffResponse.json();
      const dropoffName =
        dropOffdata.results[0]?.formatted_address || "Location not found";

      const PickUpResponse = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${pickUpLat},${pickUpLlng}&key=AIzaSyBfEcoyW9DM7QQWdV3oTjevrfyhX5n5qqg`
      );

      const pickUpdata = await PickUpResponse.json();
      const PickUpName =
        pickUpdata.results[0]?.formatted_address || "Location not found";

      // Set the locationName state once data is available
      setDropOffLocation(dropoffName);
      setPickUpLocation(PickUpName);
    } catch (error) {
      console.error("Error fetching location name:", error);
      setDropOffLocation("Location not found");
      setPickUpLocation("Location not found");
    }
  };

  const copyToClipboard = (text) => {
    // Create a temporary input element
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Append the input element to the DOM
    document.body.appendChild(textArea);

    // Select the text in the input element
    textArea.select();
    document.execCommand("copy");

    // Remove the temporary input element from the DOM
    document.body.removeChild(textArea);
  };

  const handleCopyMinorWaiver = () => {
    const minorWaiverText = `https://www.bbrbassboatrentals.com/waivers/minorWaiver/${dynamicId}`;
    copyToClipboard(minorWaiverText);
    alert("Link Copied");
  };

  const handleCopyAdultWaiver = () => {
    const adultWaiverText = `https://www.bbrbassboatrentals.com/waivers/adultWaiver/${dynamicId}`;
    copyToClipboard(adultWaiverText);
    alert("Link Copied");
  };

  const [startTripHours, setStartTripHours] = useState(0);
  const [endTripHours, setEndTripHours] = useState(0);
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const bookingDetails = await getBookingByID(dynamicId);
        if (!bookingDetails) return; // No need to proceed if bookingDetails is null

        const inquiryDetails = await getInquiryDataById(
          bookingDetails.inquiryId
        );
        const listingDetails = await getListingByID(inquiryDetails.listingId);
        const timestamp = bookingDetails["timestamp"];
        const dateTime = new Date(timestamp);
        const currentUserDetails = await getCurrentUserDetails();
        setCurrentUserDetails(currentUserDetails);

        if (bookingDetails.tripData && bookingDetails.tripData.waivers) {
          if (
            bookingDetails.tripData.waivers[
              `Captain's Waiver & Equipment Checklist`
            ] &&
            bookingDetails.tripData.waivers[
              `Captain's Waiver & Equipment Checklist`
            ][0] &&
            bookingDetails.tripData.waivers[
              `Captain's Waiver & Equipment Checklist`
            ][0]["questions"] &&
            bookingDetails.tripData.waivers[
              `Captain's Waiver & Equipment Checklist`
            ][0]["questions"][12]
          ) {
            setStartTripHours(
              parseInt(
                bookingDetails.tripData.waivers[
                  `Captain's Waiver & Equipment Checklist`
                ][0]["questions"][12].answer
              )
            );
          }

          if (
            bookingDetails.tripData.waivers[`Boat Owner Equipment Checklist`] &&
            bookingDetails.tripData.waivers[
              `Boat Owner Equipment Checklist`
            ][0] &&
            bookingDetails.tripData.waivers[
              `Boat Owner Equipment Checklist`
            ][0]["questions"] &&
            bookingDetails.tripData.waivers[
              `Boat Owner Equipment Checklist`
            ][0]["questions"][5]
          ) {
            setEndTripHours(
              parseInt(
                bookingDetails.tripData.waivers[
                  `Boat Owner Equipment Checklist`
                ][0]["questions"][5].answer
              )
            );
          }
        }

        const formattedDateTime = dateTime.toLocaleDateString("en-US", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false,
        });
        const ownerDetails = await getUserByID(bookingDetails["owner"]);
        const renterDetails = await getUserByID(bookingDetails["renter"]);

        const paymentData = await getPaymentById(bookingDetails.paymentId);

        const pendingPaymentData = await getPendingPaymentById(
          bookingDetails.paymentId
        );

        const disputeData = await getDisputeDetailsById(
          bookingDetails.disputeId
        );

        setDisputeDetails(disputeData);

        setPaymentDetails(paymentData);
        console.log("paymentData: ", paymentData);
        setPendingPaymentDetails(pendingPaymentData);

        setListingData(listingDetails);
        setUserData(currentUserDetails);
        setInquiryData(inquiryDetails);
        const offer = inquiryDetails.offerData;
        const offerDate = dayjs(offer.offeredDate + "T" + offer.offeredTime);
        console.log("offer");
        console.log(offerDate);
        setOfferStartDateTime(offerDate);
        setBookingData(bookingDetails);

        setTimeStamp(formattedDateTime);
        setOwnerData(ownerDetails);
        setRenterData(renterDetails);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [dynamicId]);

  const [startTripModal, setStartTripModal] = useState(false);

  const openStartTripModal = (index) => {
    setStartTripModal(true);
  };

  const closeStartTripModal = () => {
    setStartTripModal(false);
  };
  const [cancelBookingModal, setCancelBookingModal] = useState(false);

  const openCancelBookingModal = (index) => {
    setCancelBookingModal(true);
  };

  const closeCancelBookingModal = () => {
    setCancelBookingModal(false);
  };

  const handleDisputeAmount = (value) => {
    if (value <= 800) {
      setDisputeAmount(value);
      setError("");
    } else {
      setError("Amount should not exceed security deposit");
    }
  };

  if (!bookingData) {
    // You may want to display a loading spinner or message while data is being fetched
    return "";
  }

  const inquiry = inquiryData;
  const offer = inquiryData.offerData;
  const offerDate = offer.offeredDate + "T" + offer.offeredTime;
  const hoursMatch = offer.duration.match(/(\d+) hours/);
  const totalHours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;

  const tripDetails = bookingData.tripData;
  const mergedWaivers = {
    ...(tripDetails?.waivers?.["Adult Waiver"] || []),
    ...(tripDetails?.waivers?.["Minor Waiver"] || []),
    ...(tripDetails?.waivers?.["Boat Owner Equipment Checklist"] || []),
    ...(tripDetails?.waivers?.["Captain's Waiver & Equipment Checklist"] || []),
  };
  getLocationName(offer.dropOffAddress, offer.pickupAddress);

  const offerPriceAsNumber = Number(offer.offerPrice);

  function formatDateTime(dateTimeString) {
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formattedDateTimeNew = new Date(dateTimeString).toLocaleString(
      "en-US",
      options
    );
    return formattedDateTimeNew;
  }

  const sendWaiverEmail = async () => {
    try {
      const waiverTypeName = waiverType + " Waiver";
      if (waiverType === "Adult") {
        const emailBody = await sendWaiverBody(
          waiverTypeName,
          `https://www.bbrbassboatrentals.com/waivers/adultWaiver/${dynamicId}`
        );
        await sendEmail(waiverReceiverEmail, "Waiver Received", emailBody);
        setSuccess("Email has been sent.");
      } else {
        const emailBody = await sendWaiverBody(
          waiverTypeName,
          `https://www.bbrbassboatrentals.com/waivers/minorWaiver/${dynamicId}`
        );
        await sendEmail(waiverReceiverEmail, "Waiver Received", emailBody);
        setSuccess("Email has been sent.");
      }

      // Introduce a delay of 1.3 seconds before closing the modal
      setTimeout(() => {
        closeSendWaiverEmailModal();
        setSuccess("");
      }, 1300);
    } catch (error) {
      setSuccess("");
    }
  };

  const offeredFormattedDateTime = formatDateTime(
    `${offer.offeredDate} ${offer.offeredTime}`
  );

  const formatTimestamp = (timestamp) => {
    if (!timestamp || !timestamp) {
      return "....";
    }

    const date = new Date(timestamp);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const calculateDuration = (startTimestamp, endTimestamp) => {
    if (!startTimestamp || !endTimestamp) {
      return "....";
    }

    const startDateTime = new Date(startTimestamp); // Use function parameter
    const endDateTime = new Date(endTimestamp); // Use function parameter
    const durationInMillis = endDateTime - startDateTime;

    // Convert duration to a human-readable format (e.g., hours, minutes, seconds)
    const hours = Math.floor(durationInMillis / 3600000);
    const minutes = Math.floor((durationInMillis % 3600000) / 60000);
    const seconds = Math.floor((durationInMillis % 60000) / 1000);

    return `${hours} hours ${minutes} minutes`;
  };

  var offerTimestamp = "";
  var isEarlyArrival = "";
  var isLateArrival = "";

  if (
    !tripDetails &&
    tripDetails !== undefined &&
    !tripDetails.startTimestamp &&
    !tripDetails.endTimestamp
  ) {
    offerTimestamp = new Date(offer.arivingDateTime).getTime();
    isEarlyArrival = offerTimestamp < tripDetails.endTimestamp;
    isLateArrival = offerTimestamp > tripDetails.endTimestamp;
  }

  // Check if offerPriceAsNumber is a valid number
  const formattedOfferPrice = isNaN(offerPriceAsNumber)
    ? "Invalid Number"
    : offerPriceAsNumber.toLocaleString();

  const WaiversCard = ({ waivers }) => {
    if (!waivers || waivers == null) {
      return null;
    }

    const renderTable = (nodeName, waiverName, questions) => (
      <div className="col-12 col-md-6">
        <div className="card" style={{ maxHeight: "400px" }} key={nodeName}>
          <div className="card-header">
            <h2 className="card-title">{`${waiverName} - ${
              questions[0]?.answer
            } ${questions[1]?.answer || ""}`}</h2>
          </div>
          <div className="card-body waivers-body overflow-auto p-3">
            <table className="table">
              <thead>
                <tr>
                  <th>Question</th>
                  <th>Answer</th>
                </tr>
              </thead>
              <tbody>
                {questions.map((question, index) => (
                  <tr key={index}>
                    <td>{question.question}</td>
                    <td>
                      {question.question.toLowerCase() === "signature" ? (
                        <img
                          src={
                            "https://www.bbrbassboatrentals.com/images/" +
                            question.answer
                          }
                          alt="Signature"
                          style={{ maxWidth: "150px", maxHeight: "150px" }}
                        />
                      ) : Array.isArray(question.answer) ? (
                        question.answer.map((url, i) => (
                          <img
                            key={i}
                            src={
                              "https://www.bbrbassboatrentals.com/images/" + url
                            }
                            alt="Image"
                            style={{ maxWidth: "150px", maxHeight: "150px" }}
                          />
                        ))
                      ) : typeof question.answer === "string" &&
                        question.answer.startsWith("http") ? (
                        <img
                          src={
                            "https://www.bbrbassboatrentals.com/images/" +
                            question.answer
                          }
                          alt="Image"
                          style={{ maxWidth: "150px", maxHeight: "150px" }}
                        />
                      ) : (
                        question.answer
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );

    return (
      <div className="row">
        {Object.entries(waivers).map(([nodeName, node]) => {
          if (node && node.questions && node.questions.length > 0) {
            return renderTable(nodeName, node.waiverName, node.questions);
          }
          return null;
        })}
      </div>
    );
  };

  const columns = [
    { Header: "Name", accessor: "name" },
    { Header: "Date Time", accessor: "dateTime" },
    { Header: "Amount", accessor: "amount" },
    { Header: "Booking ID", accessor: "bookingId" },
    // Add more columns as needed
  ];

  const data = [
    {
      name: "John Doe",
      dateTime: "2023-01-15 10:30 AM",
      amount: 100.0,
      bookingId: "B12345",
    },
    {
      name: "Jane Doe",
      dateTime: "2023-02-02 03:45 PM",
      amount: 75.5,
      bookingId: "B67890",
    },
    // Add more data rows as needed
  ];

  const captureHeldPayment = async () => {
    try {
      const captureResponse = await fetch(
        "https://www.bbrbassboatrentals.com/capture-held-payment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentIntentId: paymentDetails.paymentHeld.id,
          }),
        }
      );

      const captureResult = await captureResponse.json();
      console.log("Capture result: ", captureResult);
      await updateHeldPaymentStatus(bookingData.paymentId, "Dispute Resolved");
      await updateBookingStatus(paymentDetails.bookingId, "Trip Concluded");
      await updateDisputeStatus(bookingData.disputeId, "Closed");

      const renterData = await getCurrentUserDetails();
      const subjectEmail = "Booking Completed";
      const renterName = renterData.firstName + " " + renterData.lastName;
      const bookingTitle = listingData.listingTitle + " - " + renterName;
      const emailBody = await BookingStatusUpdateEmailBody(
        renterName,
        bookingTitle,
        "Completed"
      );
      sendEmail(renterData.email, subjectEmail, emailBody);

      const ownerData = await getUserByID(bookingData.owner);
      const subjectOwnerEmail = "Booking Completed";
      const ownerName = ownerData.firstName + " " + ownerData.lastName;
      const emailOwnerBody = await BookingStatusUpdateEmailBody(
        ownerName,
        bookingTitle,
        "Completed"
      );
      sendEmail(ownerData.email, subjectOwnerEmail, emailOwnerBody);
    } catch (error) {
      console.error("Error capturing held payment:", error);
    }
  };

  const sendDispute = async () => {
    try {
      // Ensure that disputeAmount and disputeReason are defined and not empty
      if (
        disputeAmount === undefined ||
        disputeReason === undefined ||
        disputeReason === ""
      ) {
        console.error("Dispute amount or reason is undefined or empty.");
        return;
      }

      if (paymentDetails.status === "Disputed") {
        setError("This booking has already been disputed.");
        return;
      }

      const database = getDatabase();
      const disputesRef = ref(database, "disputes");
      const disputeData = {
        bookingId: paymentDetails.bookingId,
        listingId: listingData.id,
        filedBy: auth.currentUser.uid,
        amount: disputeAmount,
        reason: disputeReason,
        status: "Open",
        timestamp: serverTimestamp(),
      };

      const newDisputeRef = push(disputesRef, disputeData);

      // Extract the disputeId from the reference
      const disputeId = newDisputeRef.key;

      const bookingsRef = ref(database, `bookings/${paymentDetails.bookingId}`);

      await update(bookingsRef, { disputeId: disputeId });

      await updateHeldPaymentStatus(bookingData.paymentId, "Disputed");
      await updateBookingStatus(paymentDetails.bookingId, "Disputed");
      closeRequestDisputeModal();
    } catch (error) {
      console.error("Error filing dispute:", error);
    }
  };

  const captureCertainHeldPayment = async () => {
    try {
      const captureResponse = await fetch(
        "https://www.bbrbassboatrentals.com/capture-held-payment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentIntentId: paymentDetails.paymentHeld.id,
            amountToCapture: disputeAmount,
          }),
        }
      );

      const captureResult = await captureResponse.json();
      console.log("Capture result: ", captureResult);
      await updateHeldPaymentStatus(bookingData.paymentId, "Dispute Resolved");
      await updateBookingStatus(paymentDetails.bookingId, "Trip Concluded");
      await updateDisputeStatus(bookingData.disputeId, "Closed");

      const ownerData = await getCurrentUserDetails();
      const subjectOwnerEmail = "Congratulations! Booking Completed";
      const ownerName = ownerData.firstName + " " + ownerData.lastName;
      const emailOwnerBody = await BookingCompletedWithDisputeOwnerEmailBody(
        ownerName,
        bookingData.listingTitle,
        disputeAmount
      );
      sendEmail(ownerData.email, subjectOwnerEmail, emailOwnerBody);

      const renterData = await getUserByID(bookingData.renter);
      const subjectEmail = "Congratulations! Booking Completed";
      const emailBody = await BookingCompletedWithDisputeRenterEmailBody(
        ownerName,
        bookingData.listingTitle,
        disputeAmount
      );
      sendEmail(renterData.email, subjectEmail, emailBody);

      setSuccess("Payment has been charged from security deposit.");
      setTimeout(() => {
        setSuccess("");
      }, 1300);

      document.querySelector(".charge-btn").style.display = "none!important";

      closeDisputeModal();
    } catch (error) {
      console.error("Error capturing held payment:", error);
    }
  };

  const releaseHeldFunds = async () => {
    try {
      const captureResponse = await fetch(
        "https://www.bbrbassboatrentals.com/release-funds",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentIntentId: paymentDetails.paymentHeld.id,
          }),
        }
      );

      const captureResult = await captureResponse.json();
      await updateHeldPaymentStatus(bookingData.paymentId, "refunded");
      await updateBookingStatus(paymentDetails.bookingId, "Trip Concluded");

      const renterData = await getUserByID(bookingData.renter);
      const subjectEmail = "Congratulations! Booking Completed";
      const renterName = renterData.firstName + " " + renterData.lastName;
      const bookingTitle = listingData.listingTitle + " - " + renterName;
      const emailBody = await BookingStatusUpdateEmailBody(
        renterName,
        bookingTitle,
        "Completed"
      );
      sendEmail(renterData.email, subjectEmail, emailBody);

      const ownerData = await getCurrentUserDetails();
      const subjectOwnerEmail = "Congratulations! Booking Completed";
      const ownerName = ownerData.firstName + " " + ownerData.lastName;
      const emailOwnerBody = await BookingStatusUpdateEmailBody(
        ownerName,
        bookingTitle,
        "Completed"
      );
      sendEmail(ownerData.email, subjectOwnerEmail, emailOwnerBody);

      setSuccess("Security Deposit hold has been released.");

      document.querySelector(".charge-btn").style.display = "none!important";

      closeDisputeModal();
      console.log("Capture result: ", captureResult);
    } catch (error) {
      console.error("Error capturing held payment:", error);
      setSuccess("");
    }
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description d-flex justify-content-between">
            <h1>{bookingData.listingTitle}</h1>
            <div className="d-flex" style={{ gap: "5px" }}>
            {!["cancelled", "concluded", "completed"].includes(bookingData.status.toLowerCase()) &&
                !["cancelled"].includes(bookingData.status.toLowerCase()) &&
                ["admin", "boat owner"].includes(userData.role.toLowerCase()) &&
                bookingData.renter !== auth.currentUser.uid &&
                <Button className="btn btn-danger" onClick={handleCancelBooking}>
                  Cancel Booking
                </Button>
              }
              {bookingData.status.toLowerCase() == "pending" &&
              userData.role === "Boat Owner" &&
              bookingData.renter != auth.currentUser.uid ? (
                <button className="btn btn-warning">Pending</button>
              ) : bookingData.status === "Cancelled" ? (
                <button className="btn btn-danger">Cancelled</button>
              ) : bookingData.status === "Completed" ? (
                <button className="btn btn-success">Completed</button>
              ) : (bookingData.status === "Waiver Pending" ||
                  bookingData.status === "tripStarted" ||
                  bookingData.status === "Paid") &&
                (userData.role === "Renter" ||
                  bookingData.renter === auth.currentUser.uid) ? (
                <>
                  <div className="d-flex flex-column gap-2">
                    {bookingData.status === "Waiver Pending" && (
                      <>
                        <Alert severity="warning">
                          Submit waivers before starting trip.
                        </Alert>
                      </>
                    )}

                    <div className="d-flex gap-2 justify-content-center align-items-center flex-wrap">
                      <Link
                        to={"/waivers/adultWaiver/" + dynamicId}
                        className="btn btn-primary"
                      >
                        Adult Passenger Waiver
                      </Link>

                      <Link
                        to={"/waivers/minorWaiver/" + dynamicId}
                        className="btn btn-primary"
                      >
                        Minor Passenger Waiver
                      </Link>
                    </div>

                    <Button
                      className="send-waiver-button"
                      onClick={openSendWaiverEmailModal}
                    >
                      <RiMailSendLine /> Send Waivers
                    </Button>

                    {(bookingData.status === "Waiver Pending" ||
                      bookingData.status === "Paid") && (
                      <>
                        <Button
                          onClick={openStartTripModal}
                          className="btn btn-warning"
                        >
                          Start Trip
                        </Button>
                      </>
                    )}
                    {/* {(bookingData.status === "Waiver Pending" ||
                      bookingData.status === "Paid") &&
                      paymentDetails.advanceBooking === 1 &&
                      paymentDetails.remainingAmount !== 0 &&
                      pendingPaymentDetails.status === "pending" &&
                      new Date() - new Date(offer.offeredDate) <
                        1000 * 60 * 60 * 24 * 14 && (
                        <>
                          <Button
                            onClick={openCancelBookingModal}
                            className="btn btn-danger"
                          >
                            Cancel Booking
                          </Button>
                        </>
                      )} */}

                    {/* <Alert severity={(bookingData.waiversFilled.adult + bookingData.waiversFilled.minor) === bookingData.totalWaivers ? 'success' : 'warning'}>Waivers Filled {(bookingData.waiversFilled.adult + bookingData.waiversFilled.minor)}/{bookingData.totalWaivers}</Alert> */}
                  </div>
                </>
              ) : bookingData.status === "Waiver Pending" &&
                userData.role === "Boat Owner" &&
                bookingData.renter != auth.currentUser.uid ? (
                <>
                  <div className="d-flex gap-3">
                    <Alert severity="warning">
                      Waiting for waivers submission.
                    </Alert>
                    <div className="d-flex gap-3 justify-content-center align-items-center">
                      <Button
                        className="py-2"
                        onClick={openSendWaiverEmailModal}
                      >
                        {" "}
                        <RiMailSendLine /> Send Waivers
                      </Button>
                    </div>
                  </div>
                </>
              ) : (bookingData.status.toLowerCase() == "pending") &&
                (userData.role === "Renter" ||
                  (userData.role === "Boat Owner" &&
                    bookingData.renter === auth.currentUser.uid)) ? (
                <Link
                  to={
                    "https://www.bbrbassboatrentals.com/dashboard/booking-payment/?bookingId=" +
                    dynamicId
                  }
                  className="btn btn-primary"
                >
                  Pay Now
                </Link>
              ) : bookingData.status === "Paid" &&
                userData.role === "Boat Owner" &&
                bookingData.renter != auth.currentUser.uid ? (
                <Alert severity="warning">
                  Waiting for renter to start trip.
                </Alert>
              ) : bookingData.status === "tripStarted" &&
                userData.role === "Boat Owner" &&
                bookingData.renter != auth.currentUser.uid ? (
                <>
                  <div className="d-flex flex-column gap-2 justify-content-center align-items-center flex-wrap">
                    <Link
                      to={"/waivers/adultWaiver/" + dynamicId}
                      className="btn btn-primary"
                    >
                      Adult Passenger Waiver
                    </Link>

                    <Link
                      to={"/waivers/minorWaiver/" + dynamicId}
                      className="btn btn-primary"
                    >
                      Minor Passenger Waiver
                    </Link>
                    <Button
                      className="send-waiver-button"
                      onClick={openSendWaiverEmailModal}
                    >
                      <RiMailSendLine /> Send Waivers
                    </Button>
                    <Link
                      to={"/dashboard/endTrip/" + dynamicId}
                      className="btn btn-danger"
                    >
                      End Trip
                    </Link>
                  </div>
                </>
              ) : bookingData.status === "Trip Completed" &&
                userData.role === "Boat Owner" &&
                bookingData.renter != auth.currentUser.uid ? (
                <>
                  <div>
                    <div className="d-flex flex-column" style={{ gap: "5px" }}>
                      <div className="charge-btn d-flex" style={{ gap: "5px" }}>
                        <Button
                          onClick={openDisputeModal}
                          className="btn btn-primary"
                        >
                          Charge
                        </Button>
                        <Button
                          onClick={releaseHeldFunds}
                          className="btn btn-success"
                        >
                          Cancel Hold
                        </Button>
                      </div>
                      <Alert severity="info">
                        {endTripHours - startTripHours > totalHours ? (
                          <>
                            {endTripHours - startTripHours - totalHours}{" "}
                            additional hours.
                            <span style={{ display: "block" }}>
                              Extra charges: $
                              {(endTripHours - startTripHours - totalHours) *
                                50}
                            </span>
                          </>
                        ) : (
                          "No extra hours"
                        )}
                      </Alert>
                    </div>
                    {success && (
                      <Alert className="mt-3" severity="success">
                        {success}
                      </Alert>
                    )}
                  </div>
                </>
              ) : bookingData.status === "Disputed" &&
                userData.role === "Boat Owner" ? (
                <>
                  <Alert severity="warning">Dispute has been initialed.</Alert>
                  {/* <Button onClick={openRequestDisputeModal} className='btn btn-danger'>
                    Request Dispute
                  </Button> */}
                  {/* <Button onClick={openDisputeModal} className='btn btn-danger'>
                    Dispute
                  </Button>
                  <Button onClick={releaseHeldFunds} className='btn btn-success'>
                    All Good
                  </Button> */}
                </>
              ) : bookingData.status === "Disputed" &&
                userData.role === "Renter" ? (
                <>
                  <Alert severity="warning">
                    Boat owner initialed dispute.
                  </Alert>
                </>
              ) : bookingData.status === "Disputed" &&
                userData.role === "Admin" ? (
                <>
                  {/* <Alert severity="warning">Dispute has been initialed.</Alert> */}
                  {/* <Button onClick={openRequestDisputeModal} className='btn btn-danger'>
                    Request Dispute
                  </Button> */}
                  {/* <Button onClick={openDisputeModal} className='btn btn-warning'>
                    Resolve Dispute
                  </Button> */}
                  {/* <Button onClick={releaseHeldFunds} className='btn btn-success'>
                    All Good
                  </Button> } */}
                </>
              ) : bookingData.status === "Trip Concluded" ? (
                // Add your logic for 'Concluded' status here
                <Alert severity="success">Trip has been completed.</Alert>
              ) : bookingData.status === "cancelled" ? (
                // Add your logic for 'Concluded' status here
                <Alert severity="error">This booking has been cancelled.</Alert>
              ) : (
                "" // Default class for unknown status
              )}
            </div>
          </div>
        </div>
      </div>
      <section>
        <div>
          <div class="row">
            {}
            <div class="col-lg-6">
              <div class="card mb-4">
                <div className="card-header">
                  <h2 class="card-title">Booking Details</h2>
                </div>
                <div class="card-body mt-2">
                  <div class="row">
                    <div class="col-sm-4">
                      <p class="mb-0">Boat</p>
                    </div>
                    <div class="col-sm-8">
                      <p class="text-muted mb-0">
                        {userData.role === "Admin" ? (
                          <Link to={"/dashboard/listing/" + listingData?.id}>
                            {listingData?.listingTitle}
                          </Link>
                        ) : (
                          listingData?.listingTitle
                        )}{" "}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-4">
                      <p class="mb-0">Boat Owner</p>
                    </div>
                    <div class="col-sm-8">
                      <p class="text-muted mb-0">
                        {userData.role === "Admin" ? (
                          <Link to={"/dashboard/user/" + bookingData.owner}>
                            {ownerData.firstName} {ownerData.lastName}{" "}
                          </Link>
                        ) : (
                          ownerData.firstName + " " + ownerData.lastName
                        )}{" "}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-4">
                      <p class="mb-0">Renter</p>
                    </div>
                    <div class="col-sm-8">
                      <p class="text-muted mb-0">
                        {userData.role === "Admin" ? (
                          <Link to={"/dashboard/user/" + bookingData.renter}>
                            {renterData.firstName} {renterData.lastName}
                          </Link>
                        ) : (
                          renterData.firstName + " " + renterData.lastName
                        )}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-4">
                      <p class="mb-0">Renter Email</p>
                    </div>
                    <div class="col-sm-8">
                      <p class="text-muted mb-0">{renterData.email}</p>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-4">
                      <p class="mb-0">Time Stamp</p>
                    </div>
                    <div class="col-sm-8">
                      <p class="text-muted mb-0">{timeStamp}</p>
                    </div>
                  </div>
                </div>
              </div>

              {bookingData && offer && (
                <>
                  <div class="card mb-4">
                    <div className="card-header">
                      <h2 class="card-title">Offer Details</h2>
                    </div>
                    <div class="card-body mt-2">
                      <div class="row">
                        <div class="col-sm-4">
                          <p class="mb-0">Offered Price</p>
                        </div>
                        <div class="col-sm-8">
                          <p class="text-muted mb-0">${formattedOfferPrice}</p>
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-4">
                          <p class="mb-0">Offered Date Time</p>
                        </div>
                        <div class="col-sm-8">
                          <p class="text-muted mb-0">
                            {offeredFormattedDateTime}
                          </p>
                          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                    value={offerStartDateTime}
                    onChange={(value) => setOfferStartDateTime(value)}
                    />
                </LocalizationProvider> */}
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-4">
                          <p class="mb-0">Duration</p>
                        </div>
                        <div class="col-sm-8">
                          <p class="text-muted mb-0">{offer.duration}</p>
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-4">
                          <p class="mb-0">Ariving Date Time</p>
                        </div>
                        <div class="col-sm-8">
                          <p class="text-muted mb-0">{offer.arivingDateTime}</p>
                        </div>
                      </div>
                      <hr />
                      {pickUpLocation ? (
                        <div class="row">
                          <div class="col-sm-4">
                            <p class="mb-0">Pickup Location</p>
                          </div>
                          <div class="col-sm-8">
                            <p class="text-muted mb-0">{pickUpLocation}</p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <hr />
                      {dropOffLocation ? (
                        <div class="row">
                          <div class="col-sm-4">
                            <p class="mb-0">Dropoff Location</p>
                          </div>
                          <div class="col-sm-8">
                            <p class="text-muted mb-0">{dropOffLocation}</p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div class="col-lg-6">
              {bookingData && tripDetails && (
                <>
                  <div class="card mb-4">
                    <div className="card-header">
                      <h2 class="card-title">Trip Details</h2>
                    </div>
                    <div className="card-body mt-2">
                      <div className="row">
                        <div className="col-sm-4">
                          <p className="mb-0">Trip Start Date Time</p>
                        </div>
                        <div className="col-sm-8">
                          <p className="text-muted mb-0">
                            {formatTimestamp(tripDetails.startTimestamp)}
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-4">
                          <p className="mb-0">Trip End Date Time</p>
                        </div>
                        <div className="col-sm-8">
                          <p className="text-muted mb-0">
                            {formatTimestamp(tripDetails.endTimestamp)}
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-4">
                          <p className="mb-0">Duration</p>
                        </div>
                        <div className="col-sm-8">
                          <p className="text-muted mb-0">
                            {calculateDuration(
                              tripDetails.startTimestamp,
                              tripDetails.endTimestamp
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {bookingData && inquiry && (
                <>
                  <div class="card mb-4">
                    <div className="card-header">
                      <h2 class="card-title">Inquiry Details</h2>
                    </div>
                    <div class="card-body mt-2">
                      <div class="row">
                        <div class="col-sm-4">
                          <p class="mb-0">Guests</p>
                        </div>
                        <div class="col-sm-8">
                          <p class="text-muted mb-0">
                            {inquiry.total} ({inquiry.adults} Adults,{" "}
                            {inquiry.children} Children, {inquiry.infants}{" "}
                            Infants, {inquiry.seniors} Seniors)
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-4">
                          <p class="mb-0">Prefered Date</p>
                        </div>
                        <div class="col-sm-8">
                          <p class="text-muted mb-0">{inquiry.prefferedDate}</p>
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-4">
                          <p class="mb-0">Prefered Time</p>
                        </div>
                        <div class="col-sm-8">
                          <p class="text-muted mb-0">{inquiry.prefferedTime}</p>
                        </div>
                      </div>
                      <hr />
                      {inquiry.otherDateOption1 ? (
                        <>
                          <div class="row">
                            <div class="col-sm-4">
                              <p class="mb-0">Prefered Date 2</p>
                            </div>
                            <div class="col-sm-8">
                              <p class="text-muted mb-0">
                                {inquiry.otherDateOption1}
                              </p>
                            </div>
                          </div>
                          <hr />{" "}
                        </>
                      ) : (
                        ""
                      )}
                      {inquiry.otherDateOption2 ? (
                        <>
                          <div class="row">
                            <div class="col-sm-4">
                              <p class="mb-0">Prefered Date 3</p>
                            </div>
                            <div class="col-sm-8">
                              <p class="text-muted mb-0">
                                {inquiry.otherDateOption2}
                              </p>
                            </div>
                          </div>
                          <hr />{" "}
                        </>
                      ) : (
                        ""
                      )}
                      <div class="row">
                        <div class="col-sm-4">
                          <p class="mb-0">Duration</p>
                        </div>
                        <div class="col-sm-8">
                          <p
                            class="text-muted mb-0 d-flex"
                            style={{ gap: "5px" }}
                          >
                            {inquiry.overnight > 0 && (
                              <p className="mb-1">
                                {inquiry.overnight} Nights{" "}
                              </p>
                            )}
                            {inquiry.sameDayHours > 0 && (
                              <p className="mb-1">
                                {inquiry.sameDayHours} Hours{" "}
                              </p>
                            )}
                            {inquiry.sameDayMinutes > 0 && (
                              <p className="mb-1">
                                {inquiry.sameDayMinutes} Minutes{" "}
                              </p>
                            )}
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-sm-4">
                          <p class="mb-0">Comments</p>
                        </div>
                        <div class="col-sm-8">
                          <p class="text-muted mb-0">{inquiry.comments}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {inquiryData &&
                userData.role === "Boat Owner" &&
                bookingData.renter != auth.currentUser.uid && (
                  <>
                    <div class="card mb-4">
                      <div className="card-header">
                        <h2 class="card-title">Payment Details</h2>
                      </div>
                      <div class="card-body mt-2">
                        <div class="row">
                          <div class="col-sm-4">
                            <p class="mb-0">Amount</p>
                          </div>
                          <div class="col-sm-8">
                            {paymentDetails && paymentDetails.cutPercentage ? (
                              <p class="text-muted mb-0">
                                $
                                {paymentDetails
                                  ? paymentDetails.ownerAmount / 100
                                  : ""}{" "}
                                ({100 - paymentDetails?.cutPercentage * 100}% of
                                $
                                {paymentDetails?.advanceBooking &&
                                paymentDetails?.remainingAmount > 0
                                  ? "200"
                                  : formattedOfferPrice}
                                ){" "}
                              </p>
                            ) : (
                              <p class="text-muted mb-0">
                                $
                                {paymentDetails
                                  ? paymentDetails.ownerAmount / 100
                                  : ""}{" "}
                                90% of ${formattedOfferPrice}{" "}
                              </p>
                            )}
                            <p class="mb-0">
                              {paymentDetails?.advanceBooking &&
                              paymentDetails?.remainingAmount > 0
                                ? "$" +
                                  (paymentDetails?.remainingAmount / 100 -
                                    paymentDetails?.totalTax / 100) +
                                  " remaining will be charged from the customer 48 prior to start date"
                                : ""}
                            </p>
                            <p class="mb-0">
                              {paymentDetails?.firstRentalPayment &&
                              paymentDetails?.adminFirstTimeCut &&
                              !paymentDetails?.referrer
                                ? "$" +
                                  paymentDetails?.adminFirstTimeCut +
                                  " have been deducted for the first completed rental."
                                : ""}
                            </p>
                            <p class="mb-0">
                              {paymentDetails?.firstRentalPayment &&
                              !paymentDetails?.adminFirstTimeCut &&
                              !paymentDetails?.referrer &&
                              !paymentDetails?.referAmount
                                ? "$100 have been deducted for the first completed rental."
                                : ""}
                            </p>
                            <p class="mb-0">
                              {paymentDetails?.firstRentalPayment &&
                              !paymentDetails?.adminFirstTimeCut &&
                              paymentDetails?.referrer &&
                              paymentDetails?.referAmount
                                ? "$50 have been deducted for the first completed rental."
                                : ""}
                            </p>
                            <p class="mb-0">
                              {paymentDetails?.firstRentalPayment &&
                              paymentDetails?.adminFirstTimeCut &&
                              paymentDetails?.referrer &&
                              paymentDetails?.referAmount
                                ? "$" +
                                  paymentDetails?.adminFirstTimeCut / 100 +
                                  " have been deducted for the first completed rental."
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              {inquiryData &&
                userData.role === "Renter" &&
                bookingData.status != "pending" && (
                  <>
                    <div class="card mb-4">
                      <div className="card-header">
                        <h2 class="card-title">Payment Details</h2>
                      </div>
                      <div class="card-body mt-2">
                        <div class="row">
                          <div class="col-sm-4">
                            <p class="mb-0">Amount</p>
                          </div>
                          <div class="col-sm-8">
                            {paymentDetails.cutPercentage &&
                              paymentDetails?.advanceBooking == 1 && (
                                <p class="text-muted mb-0">
                                  $
                                  {paymentDetails &&
                                  paymentDetails?.remainingAmount != 0
                                    ? paymentDetails.advancePaid / 100
                                    : paymentDetails.totalAmount / 100}
                                </p>
                              )}
                            {paymentDetails.cutPercentage &&
                              !paymentDetails?.advanceBooking && (
                                <p class="text-muted mb-0">
                                  $
                                  {paymentDetails
                                    ? paymentDetails.totalAmount / 100
                                    : ""}
                                </p>
                              )}
                            <p class="mb-0">
                              {paymentDetails?.advanceBooking == 1 &&
                              paymentDetails?.remainingAmount > 0
                                ? "$" +
                                  paymentDetails?.remainingAmount / 100 +
                                  " remaining will be charged 2 days prior to start date"
                                : ""}
                            </p>
                            <p class="mb-0">
                              {paymentDetails?.firstRentalPayment &&
                              paymentDetails?.adminFirstTimeCut &&
                              !paymentDetails?.referrer
                                ? "$" +
                                  paymentDetails?.adminFirstTimeCut +
                                  " have been deducted for the first completed rental."
                                : ""}
                            </p>
                            <p class="mb-0">
                              {paymentDetails?.firstRentalPayment &&
                              !paymentDetails?.adminFirstTimeCut &&
                              !paymentDetails?.referrer &&
                              !paymentDetails?.referAmount
                                ? "$100 have been deducted for the first completed rental."
                                : ""}
                            </p>
                            <p class="mb-0">
                              {paymentDetails?.firstRentalPayment &&
                              !paymentDetails?.adminFirstTimeCut &&
                              paymentDetails?.referrer &&
                              paymentDetails?.referAmount
                                ? "$50 have been deducted for the first completed rental."
                                : ""}
                            </p>
                            <p class="mb-0">
                              {paymentDetails?.firstRentalPayment &&
                              paymentDetails?.adminFirstTimeCut &&
                              paymentDetails?.referrer &&
                              paymentDetails?.referAmount
                                ? "$" +
                                  paymentDetails?.adminFirstTimeCut / 100 +
                                  " have been deducted for the first completed rental."
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              {inquiryData &&
                userData.role === "Admin" &&
                bookingData.status != "pending" && (
                  <>
                    <div class="card mb-4">
                      <div className="card-header">
                        <h2 class="card-title">Payment Details</h2>
                      </div>

                      <div class="card-body mt-2">
                        <div class="row">
                          <div class="col-sm-4">
                            <p class="mb-0">
                              Total Amount (Including, Sales Tax)
                            </p>
                          </div>
                          <div class="col-sm-8">
                            <p class="text-muted mb-0">
                              $
                              {paymentDetails
                                ? paymentDetails.totalAmount / 100
                                : ""}
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-4">
                            <p class="mb-0">
                              Owner Amount (
                              {paymentDetails &&
                              paymentDetails.cutPercentage ? (
                                <>
                                  {100 - paymentDetails?.cutPercentage * 100}%{" "}
                                </>
                              ) : (
                                <>
                                  $
                                  {paymentDetails
                                    ? paymentDetails.ownerAmount / 100
                                    : ""}{" "}
                                  90%{" "}
                                </>
                              )}{" "}
                              of total amount)
                            </p>
                          </div>
                          <div class="col-sm-8">
                            <p class="text-muted mb-0">
                              $
                              {paymentDetails
                                ? paymentDetails.ownerAmount / 100
                                : ""}
                            </p>
                          </div>
                        </div>
                        <hr />
                        {paymentDetails && paymentDetails.referAmount > 0 ? (
                          <>
                            <div class="row">
                              <div class="col-sm-4">
                                <p class="mb-0">Referrer Amount</p>
                              </div>
                              <div class="col-sm-8">
                                <p class="text-muted mb-0">
                                  $
                                  {paymentDetails
                                    ? paymentDetails.referAmount / 100
                                    : ""}
                                </p>
                              </div>
                            </div>
                            <hr />
                          </>
                        ) : (
                          ""
                        )}

                        <div class="row">
                          <div class="col-sm-4">
                            <p class="mb-0">Total Sales Tax (8.25%)</p>
                          </div>
                          <div class="col-sm-8">
                            <p class="text-muted mb-0">
                              $
                              {paymentDetails
                                ? paymentDetails.totalTax / 100
                                : ""}
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-4">
                            <p class="mb-0">
                              Platform Fees (15% of total amount)
                            </p>
                          </div>
                          <div class="col-sm-8">
                            <p class="text-muted mb-0">
                              $
                              {paymentDetails
                                ? paymentDetails.adminCut / 100
                                : ""}
                            </p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12">
                            <p class="mb-0 text-muted">
                              {paymentDetails?.firstRentalPayment &&
                              paymentDetails?.adminFirstTimeCut
                                ? "$" +
                                  paymentDetails?.adminFirstTimeCut +
                                  " have been charged from the boat owner for the first completed rental."
                                : ""}
                            </p>
                            <p class="mb-0 text-muted ">
                              {paymentDetails?.firstRentalPayment &&
                              !paymentDetails?.adminFirstTimeCut &&
                              !paymentDetails?.referrer &&
                              !paymentDetails?.referAmount
                                ? "$100 have been charged from the boat owner for the first completed rental."
                                : ""}
                            </p>
                            <p class="mb-0 text-muted ">
                              {paymentDetails?.firstRentalPayment &&
                              !paymentDetails?.adminFirstTimeCut &&
                              paymentDetails?.referrer &&
                              paymentDetails?.referAmount
                                ? "$50 have been charged from the boat owner for the first completed rental."
                                : ""}
                            </p>
                            <p class="mb-0 text-muted ">
                              {paymentDetails?.firstRentalPayment &&
                              paymentDetails?.adminFirstTimeCut &&
                              paymentDetails?.referrer &&
                              paymentDetails?.referAmount
                                ? "$" +
                                  paymentDetails?.adminFirstTimeCut / 100 +
                                  " have been charged from the boat owner for the first completed rental."
                                : ""}
                            </p>
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </>
                )}
            </div>
          </div>
          {tripDetails &&
            tripDetails.waivers &&
            Object.keys(tripDetails.waivers).length > 0 && (
              <WaiversCard waivers={mergedWaivers} />
            )}
        </div>
      </section>

      <Modal
        centered
        show={isRequestDisuputeModalOpen}
        onHide={closeRequestDisputeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>File A Dispute</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col style={{ gap: "5px" }}>
              <h6
                className="mt-2"
                style={{ fontSize: "13px", textAlign: "center" }}
              >
                Disputes may take up to several days to process.
              </h6>
              <Form.Label>Dispute Amount</Form.Label>
              <CurrencyInput
                id="disputeAmount"
                name="disputeAmount"
                placeholder="Enter Price"
                defaultValue={0}
                decimalsLimit={2}
                prefix="$"
                onValueChange={(value) => setDisputeAmount(value)}
                className="form-control"
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Label className="mt-3">Reason</Form.Label>
              <TextField
                style={{ width: "100%" }}
                multiline
                rows={4}
                id="outlined-basic"
                value={disputeReason}
                onChange={(event) => setDisputeReason(event.target.value)}
                label="Dispute Reason"
                variant="outlined"
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-center mt-3">
            {error != "" && (
              <Alert style={{ width: "96%" }} severity="error">
                {error}.
              </Alert>
            )}
          </Row>
          {/* Additional content goes here */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeRequestDisputeModal}>
            Close
          </Button>
          <Button variant="primary" onClick={sendDispute}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal centered show={isDisuputeModalOpen} onHide={closeDisputeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Security Deposit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col style={{ gap: "5px" }}>
              <h6
                className="mt-2"
                style={{ fontSize: "14px", textAlign: "center" }}
              >
                Security Deposit: $800
              </h6>
              {/* {disputeDetails ? 
            <>
              <Form.Label>Amount Disputed</Form.Label>
              <p className='mt-0' style={{fontSize: '13px',}}>{disputeDetails.amount ? '$'+disputeDetails.amount : ''}</p>
              <Form.Label>Reason</Form.Label>
              <p className='mt-0' style={{fontSize: '13px',}}>{disputeDetails.reason ? disputeDetails.reason : ''}</p>
            </>
            : ''
            } */}
              <Form.Label>Amount to charge</Form.Label>
              <CurrencyInput
                id="disputeAmount"
                name="disputeAmount"
                placeholder="Enter Price"
                defaultValue={0}
                decimalsLimit={2}
                prefix="$"
                onValueChange={(value) => handleDisputeAmount(value)}
                className="form-control"
              />
              {error != "" && (
                <Alert className="mt-3" severity="error">
                  {error}.
                </Alert>
              )}
              {success && (
                <Alert className="mt-3" severity="success">
                  {success}
                </Alert>
              )}
            </Col>
          </Row>
          {/* Additional content goes here */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={releaseHeldFunds}>
            Cancel Hold
          </Button>
          <Button
            disabled={error}
            variant="primary"
            onClick={captureCertainHeldPayment}
          >
            Charge Payment
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        show={showSendWaiverEmailModal}
        onHide={closeSendWaiverEmailModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Waivers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col style={{ gap: "5px" }}>
              {/* {disputeDetails ? 
            <>
              <Form.Label>Amount Disputed</Form.Label>
              <p className='mt-0' style={{fontSize: '13px',}}>{disputeDetails.amount ? '$'+disputeDetails.amount : ''}</p>
              <Form.Label>Reason</Form.Label>
              <p className='mt-0' style={{fontSize: '13px',}}>{disputeDetails.reason ? disputeDetails.reason : ''}</p>
            </>
            : ''
            } */}

              <Form.Label>Receiver Email</Form.Label>
              <TextField
                style={{ width: "100%" }}
                id="outlined-basic"
                value={waiverReceiverEmail}
                onChange={(e) => setWaiverReceiverEmail(e.target.value)}
                label="Email"
                variant="outlined"
              />
            </Col>
          </Row>
          <Form.Label className="mt-3" style={{ width: "50%" }}>
            Waiver Type
          </Form.Label>
          <Form.Control
            as="select" // Set the input type to "select"
            name="waiverType"
            style={{ width: "100%" }}
            value={waiverType}
            defaultValue="Adult"
            onChange={(e) => setWaiverType(e.target.value)}
            className="form-control"
            required
          >
            <option default>Select Waiver</option>
            <option value="Adult">Adult Passenger Waiver</option>
            <option value="Minor">Minor Passenger Waiver</option>
            {/* {(bookingData.waiversFilled?.adult < (inquiryData[0].inquiryData.adults + inquiryData[0].inquiryData.seniors)) && 
                    <option value="Adult">Adult</option> }
                  {(bookingData.waiversFilled?.minor < (inquiryData[0].inquiryData.children +  inquiryData[0].inquiryData.infants)) && 
                    <option value="Minor">Minor</option> } */}
          </Form.Control>
          {/* Additional content goes here */}
          <Form.Label className="mt-4" style={{ width: "50%" }}>
            Copy Waivers
          </Form.Label>
          <div className="d-flex gap-3">
            {/* {(bookingData.waiversFilled?.adult < (inquiryData[0].inquiryData.adults + inquiryData[0].inquiryData.seniors)) && 
        <Button onClick={handleCopyAdultWaiver} variant='primary'><FaRegCopy /> Adult Waiver</Button>
          }
        {(bookingData.waiversFilled?.minor < (inquiryData[0].inquiryData.children +  inquiryData[0].inquiryData.infants)) && 
        <Button onClick={handleCopyMinorWaiver} variant='primary'><FaRegCopy /> Minor Waiver</Button>
        } */}
            <Button onClick={handleCopyAdultWaiver} variant="primary">
              <FaRegCopy /> Adult Passenger Waiver
            </Button>
            <Button onClick={handleCopyMinorWaiver} variant="primary">
              <FaRegCopy /> Minor Passenger Waiver
            </Button>
          </div>
          {success && (
            <Alert className="mt-4" severity="success">
              {success}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={error} variant="primary" onClick={sendWaiverEmail}>
            Send Waiver
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        centered
        show={startTripModal}
        onHide={closeStartTripModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Start Your Trip</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Alert
              severity={
                bookingData.waiversFilled.adult +
                  bookingData.waiversFilled.minor >=
                bookingData.totalWaivers
                  ? "success"
                  : "warning"
              }
            >
              Required Waivers Filled{" "}
              {bookingData.waiversFilled.adult +
                bookingData.waiversFilled.minor}{" "}
              out of {bookingData.totalWaivers}
            </Alert>
            <h6 className="mt-3">Are you sure you want to start trip?</h6>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-warning" onClick={handleStartTrip}>
            Start Trip
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        centered
        show={cancelBookingModal}
        onHide={closeCancelBookingModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cancel Advanced Booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <h6 className="mt-3">
              Are you sure you want to cancel your booking?
            </h6>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-warning" onClick={handleCancelBooking}>
            Cancel Booking
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
