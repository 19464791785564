import React, { useRef, useState } from 'react'
import { Form, Button, Alert, Row, Col, utils } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
import { useNavigate, Link } from "react-router-dom";
import { getUserRoleByEmail } from './utils';


export default function ForgotPassowrd() {
    const emailRef = useRef()
    const { resetPassword } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [emailSent, setEmailSent] = useState(false)
    let navigate = useNavigate();


    async function handleSubmit(e) {
        e.preventDefault();
    
        try {
            setError('');
            setLoading(true);

            await resetPassword(emailRef.current.value);
            setEmailSent(true);
        } catch (e) {
            setError(e.message);
            setEmailSent(false);
        } finally {
            setLoading(false);
        }
    }
    return (
        <>
        <p className="auth-description">Enter your email address below and we'll send you a link to reset your password.<br/><br/>Know your credentials? <Link to="/auth">Sign in</Link></p>
		    <div className="row">
                <div className="col">
                    {emailSent && <Alert variant="success">An email has been sent to your registered email address with instructions on how to reset your password. Please check your inbox and follow the provided steps. If you don't see the email in your inbox, please check your spam or junk folder. If you need further assistance, please contact our support team.</Alert>}
                    {error && <Alert variant="danger">{error}</Alert>}
                    {!emailSent && 
                        <Form onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Group id="email">
                                        <Form.Label>Enter Your Email</Form.Label>
                                        <Form.Control type="email" className="form-control" ref={emailRef} required />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Button disabled={loading} className="w-100" type="submit">Submit</Button>
                        </Form>
                    }
                </div>
            </div>
        </>
    )
}
