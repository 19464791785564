// SignupOwner.js
import { getDatabase, ref, set, update } from "firebase/database";

import { useAuth } from "../contexts/AuthContext";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Cropper, { ReactCropperElement } from "react-cropper"; // Import Cropper component and types
import "cropperjs/dist/cropper.css"; // Import Cropper CSS
import { Button, Form, Modal, ProgressBar, Row, Col, Alert } from "react-bootstrap";
import {
  getAllOwnerSelectionQuestions,
  getAllOwnerTextQuestions,
  getPlatformPercentages,
  newUserAddedAdminEmailBody,
  sendEmail,
  sendSMS,
  welcomeEmailBody,
  welcomeOwnerEmailBody,
} from "./utils";


const SignupOwner = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [step, setStep] = useState(1);

  const cropperRef = useRef(null); // Create a ref to the Cropper component
  const [croppedImage, setCroppedImage] = useState(null);

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };
  
  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    console.log(croppedImage);
  }, [croppedImage]);
  const serverUrl = "https://www.bbrbassboatrentals.com";

  const queryParams = new URLSearchParams(location.search);
  const refParam = queryParams.get("ref");

  const { signup } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [phoneNo, setPhoneNo] = useState("");
  const [textQuestionAnswers, setTextQuestionAnswers] = useState([]);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    referralCode: refParam || "",
    password: "",
    avatar: "",
  });

  const [multiChoiceQuestions, setMultiChoiceQuestions] = useState([]);
  const [textQuestions, setTextQuestions] = useState([]);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const questionsData = await getAllOwnerSelectionQuestions();
        // Convert the object into an array of values
        const questionsArray = Object.values(questionsData);
        setMultiChoiceQuestions(questionsArray);
        const textQuestionsData = await getAllOwnerTextQuestions();
        // Convert the object into an array of values
        const testQuestionsArray = Object.values(textQuestionsData);
        console.log(testQuestionsArray);
        setTextQuestions(testQuestionsArray);
      } catch (error) {
        console.error("Error fetching Questions:", error);
      }
    };

    fetchQuestions();
  }, []);
  const handleChange = (e, question) => {
    const { name, value } = e.target;

    if (name.startsWith("question_")) {
      // Update textfield answers
      const questionIndex = parseInt(name.split("_")[1], 10);
      const existingAnswerIndex = textQuestionAnswers.findIndex(
        (answer) => answer.questionText === textQuestions[questionIndex]
      );

      if (existingAnswerIndex !== -1) {
        // If an answer exists, update the existing answer
        setTextQuestionAnswers((prevAnswers) => {
          const updatedAnswers = [...prevAnswers];
          updatedAnswers[existingAnswerIndex] = {
            questionText: textQuestions[questionIndex],
            answer: value,
          };
          return updatedAnswers;
        });
      } else {
        // If no answer exists, add a new answer to the array
        setTextQuestionAnswers((prevAnswers) => [
          ...prevAnswers,
          {
            questionText: textQuestions[questionIndex],
            answer: value,
          },
        ]);
      }
    } else {
      // Update other form data
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
    console.log(textQuestionAnswers);
  };

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);

      const params = new URLSearchParams(window.location.search);
      const redirectTo = params.get("redirect_to");

      // Signup the user using email and password
      const userCredential = await signup(formData.email, formData.password);
      const userId = userCredential.user.uid; // Assuming Firebase auth returns a user object with uid

      const platformSettings = await getPlatformPercentages();
      const userSuffixNew = parseInt(platformSettings.userSuffix) + 1;
      const bbrID = "BBR" + userSuffixNew;

      const setLastSuffix = ref(getDatabase(), `portalSettings/settingsID`);
      await update(setLastSuffix, {
        userSuffix: userSuffixNew,
      });

      console.log("bbrID");
      console.log(bbrID);

      // Store user information in the Realtime Database under 'users' node
      const userRef = ref(getDatabase(), `users/${userId}`);
      await set(userRef, {
        bbrId: bbrID,
        firstName: formData.firstName,
        lastName: formData.lastName,
        avatar: formData.avatar,
        refferalCode: formData.referralCode,
        email: formData.email,
        phone: phoneNo,
        role: "Boat Owner",
        status: "Active",
      });
      const ownerMetaRef = ref(getDatabase(), `ownerMeta/${userId}`);

      await set(ownerMetaRef, {
        payments: {
          currentAmount: 0,
          totalAmount: 0,
        },
        totalPaidBookings: 0,
      });
      // Store userMeta information under 'userMeta' node with the same user ID
      const userMetaRef = ref(getDatabase(), `userMeta/${userId}`);
      await set(userMetaRef, {
        age: formData.age,
        userAnswers: {
          // Assuming multiChoiceQuestions and textQuestionAnswers are arrays of objects
          multiChoiceQuestions: multiChoiceQuestions.map((question, index) => ({
            question: question.questionText,
            answer: selectedOptions[index]?.option || "", // Assuming selectedOptions is an array of selected options
          })),
          textQuestions: textQuestions.map((question, index) => ({
            question: question,
            answer: textQuestionAnswers[index]?.answer || "",
          })),
        },
        // Add other userMeta data fields as needed
      });

      const userFullName = formData.firstName + " " + formData.lastName;
      const subject = "Welcome Aboard! " + userFullName;
      const to = formData.email;

      const emailBodyNew = await welcomeOwnerEmailBody(userFullName);
      const emailSent = await sendEmail(to, subject, emailBodyNew);

      const smsBody = `Welcome Aboard ${userFullName}, your account has been registered successfully.\n\nhttps://www.bbrbassboatrentals.com/auth`;
      const smsResponse = sendSMS(formData.phone, smsBody);
      console.log(smsResponse);

      const adminEmailBody = await newUserAddedAdminEmailBody(
        "Boat Owner",
        bbrID,
        userFullName,
        to
      );
      const adminEmailSent = await sendEmail(
        "waivers@bbrbassboatrentals.com",
        "New Boat Owner Registered.",
        adminEmailBody
      );

      if (redirectTo) {
        navigate(`/listing/${redirectTo}`);
      } else {
        navigate("/dashboard");
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }

  const [avatar, setAvatar] = useState(null);

  const onCrop = () => {
    const cropper = cropperRef.current.cropper; // Access the Cropper instance through the ref
    setCroppedImage(cropper.getCroppedCanvas().toDataURL());
  };

  const handleUploadAvatar = async () => {
    if (croppedImage) {
      // Convert the base64 image to a Blob
      const blob = await fetch(croppedImage).then((res) => res.blob());
      console.log(blob);
      // Create FormData and append the file
      const formData = new FormData();
      formData.append("file", blob);

      try {
        const response = await fetch(`${serverUrl}/upload-featured-image`, {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const responseData = await response.json();
          setFormData((prevUserValues) => ({
            ...prevUserValues,
            avatar: `${serverUrl}/images/${responseData.file_name}`,
          }));
          setAvatar(null);
        } else {
          console.error("File upload failed");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      console.error("No cropped image available");
    }
  };

  const handleSelectImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setAvatar(reader.result); // Set the file URL as the avatar
    };
    reader.readAsDataURL(file); // Convert the file to a data URL
  };

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleRadioChange = (questionText, option) => {
    // Check if an option for the same question already exists
    const existingOptionIndex = selectedOptions.findIndex(
      (selectedOption) => selectedOption.questionText === questionText
    );

    if (existingOptionIndex !== -1) {
      // If an option exists, update the existing option
      setSelectedOptions((prevOptions) => {
        const updatedOptions = [...prevOptions];
        updatedOptions[existingOptionIndex] = {
          questionText,
          option,
        };
        return updatedOptions;
      });
    } else {
      // If no option exists, add a new option to the array
      setSelectedOptions((prevOptions) => [
        ...prevOptions,
        {
          questionText,
          option,
        },
      ]);
    }
  };

  const [redirectTo, setRedirectTo] = useState("");
  const [redirectId, setRedirectId] = useState("");
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const redirectTo = params.get("redirect_to");
    if (redirectTo) {
      setRedirectTo(`?redirect_to=${redirectTo}`);
      setRedirectId(redirectTo);
    }
  }, []);

  return (
    <>
      <p className="auth-description">
        Please enter your credentials to create an account.
        <br />
        Already have an account? <Link to={`/auth${redirectTo}`}>Sign In</Link>
      </p>

      <div className="auth-credentials m-b-xxl">
        <Row>
          <Col>
            <ProgressBar now={(step / 2) * 100} className="mb-3" />
            <Form onSubmit={handleSubmit}>
              {error && <Alert variant="danger">{error}</Alert>}
              {step === 1 && (
                <>
                  <Form.Group controlId="firstName" className="mt-4">
                    <Row className="mb-2">
                      <Col xs={6}>
                        <Form.Label className="form-label">
                          First Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleChange}
                          className="form-control"
                          required
                        />
                      </Col>
                      <Col xs={6}>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleChange}
                          className="form-control"
                          required
                        />
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      <Col xs={12}>
                        <Form.Label className="form-label">
                          Profile Picture
                        </Form.Label>
                        <Form.Control
                          type="file"
                          name="avatar"
                          onChange={(e) => handleSelectImage(e)}
                          accept="image/*"
                        />
                        {avatar && (
                          <>
                            <Cropper
                              src={avatar} // Image source
                              className="mt-3"
                              style={{ height: 400, width: "100%" }} // Styling for Cropper container
                              initialAspectRatio={16 / 9} // Initial aspect ratio for cropping
                              guides={false} // Display cropping guides
                              crop={onCrop} // Callback function triggered after cropping
                              ref={cropperRef} // Assign the ref to the Cropper component
                            />
                            <Button
                              className="mt-3"
                              onClick={handleUploadAvatar}
                            >
                              Finish Cropping
                            </Button>
                          </>
                        )}
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      <Col xs={6}>
                        <Form.Label>Age</Form.Label>
                        <Form.Control
                          type="number"
                          name="age"
                          min={1}
                          max={150}
                          value={formData.age}
                          className="form-control"
                          onChange={handleChange}
                          required
                        />
                      </Col>
                      <Col xs={6}>
                        <Form.Label>Phone</Form.Label>
                        <PhoneInput
                          country={"us"}
                          value={phoneNo}
                          onChange={(value) => setPhoneNo(value)}
                        />
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      <Col xs={6}>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          className="form-control"
                          required
                        />
                      </Col>
                      <Col xs={6}>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                          className="form-control"
                          required
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col xs={12}>
                        <Form.Label>Refferal Code</Form.Label>
                        <Form.Control
                          type="referralCode"
                          name="referralCode"
                          value={formData.referralCode}
                          onChange={handleChange}
                          className="form-control"
                          required
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Row className="mt-4">
                    <Col>
                      <Button variant="btn btn-primary" onClick={nextStep}>
                        Next
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
              {/* {step === 2 && (
          <>
          {multiChoiceQuestions.map((question, questionIndex) => (
            <Form.Group key={questionIndex}>
              <Form.Label className='mt-2'>{question.questionText}</Form.Label>
              <div className='d-flex'>
                {question.options.map((option, optionIndex) => (
                  <div key={optionIndex} className="mb-4">
                    <Form.Check
                      type="radio"
                      style={{textAlign: 'start'}}
                      label={option}
                      className="btn btn-light m-1"
                      name={`question_${questionIndex}`}
                      id={`option_${questionIndex}_${optionIndex}`}
                      onChange={() => handleRadioChange(question.questionText, option)}
                    />
                  </div>
                ))}
              </div>
            </Form.Group>
          ))}
          <Row className='mt-4'>
            <Col>
              <Button className='mx-2' variant="secondary" onClick={prevStep}>Previous</Button>
              <Button className='mx-2' variant="primary" onClick={nextStep}>Next</Button>
            </Col>
          </Row>
          
        </>
        )} */}
              {step === 2 && (
                <>
                  {textQuestions.map((question, questionIndex) => (
                    <Form.Group key={questionIndex} className="mt-4">
                      <Form.Label>{question}</Form.Label>
                      <Form.Control
                        rows={3}
                        name={`question_${questionIndex}`}
                        onChange={(e) => handleChange(e, question)}
                        required
                      />
                    </Form.Group>
                  ))}
                  <Row>
                    <Col>
                    <Form.Check 
                        type="checkbox"
                        style={{marginTop: '20px'}}
                        label={
                          <>
                            By signing up for BBR Bass Boat Rentals, users agree to abide by these 
                            <span onClick={openModal} style={{ marginLeft: '5px', marginRight: '5px', textDecoration: 'underline', color: '#0d5ceb', cursor: 'pointer' }}>
                              rules 
                            </span> 
                             and contribute to a safe, fair, and trusted community of boat renters and owners.
                          </>
                        }
                        required
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="auth-submit mt-4">
                        <Button
                          className="mx-2"
                          variant="secondary"
                          onClick={prevStep}
                        >
                          Previous
                        </Button>
                        <Button
                          className="mx-2"
                          variant="primary"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </Form>
          </Col>
        </Row>

        
      <Modal centered show={isModalOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>BBR Bass Boat Rentals - Sign Up Rules and Guidelines</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>1. Communication Within the BBR Platform Only</h4>
          <p>All communication between boat owners and renters must take place exclusively within the BBR Bass Boat Rentals dashboard. Sharing personal phone numbers, emails, or any other form of contact information for the purpose of arranging bookings outside of the system is strictly prohibited.</p>
          <h5>Reason:</h5>
          <ul>
            <li><strong>Fair Business Practices:</strong> BBR’s platform is designed to ensure that transactions and bookings are transparent, secure, and fairly conducted. Making bookings outside of the system compromises the fairness and integrity of the platform.</li>
            <li><strong>Safety in Communications:</strong> By keeping all communication within the dashboard, BBR can ensure that any issues or disputes can be resolved fairly. It also protects the privacy of both parties by keeping personal contact details confidential.</li>
            <li><strong>Liability Protection:</strong> BBR provides legal coverage and waivers to both renters and boat owners through the platform. Any transaction or communication outside of the system could lead to liability issues and will not be covered under BBR's policies.</li>
          </ul>
          <h4>2. No Off-Platform Transactions</h4>
          <p>All bookings, payments, and transactions must be completed through the BBR Bass Boat Rentals system. Any attempt to circumvent this process by making off-platform deals or payments will result in suspension or termination of the account.</p>
          <h5>Reason:</h5>
          <ul>
            <li>This ensures that the platform can properly track, secure, and guarantee each booking.</li>
            <li>Off-platform transactions undermine the protection offered by BBR’s system, including payment security, insurance, and liability coverage.</li>
          </ul>
          <h4>3. Adherence to BBR's Safety Standards</h4>
          <p>By signing up, users agree to adhere to the safety guidelines and standards set by BBR Bass Boat Rentals, which include following local boating laws and regulations, providing accurate information about boat conditions, and using the appropriate safety equipment.</p>
          <h4>4. Liability Waivers</h4>
          <p>All users must acknowledge that BBR Bass Boat Rentals provides liability waivers to both renters and boat owners. These waivers are only valid for transactions made through the platform. BBR cannot guarantee or cover any damages, losses, or issues arising from bookings made outside of the platform.</p>
          <h5>Reason:</h5>
          <ul>
            <li>The liability waivers ensure that both parties are legally protected during the rental period, but this protection is only available for on-platform transactions.</li>
          </ul>
          <h4>5. Community of Trust</h4>
          <p>BBR Bass Boat Rentals strives to create a community built on trust and mutual respect. By following these rules, users contribute to a safe and reliable environment for everyone involved. Maintaining the integrity of the platform helps boat owners feel secure about renting their boats, and it assures renters that they are booking from verified and trustworthy owners.</p>
          <h4>6. Violations of Terms</h4>
          <p>Any violation of these rules, including attempts to circumvent the platform, will result in immediate action, which may include suspension or permanent removal from the platform.</p>
          <p style={{marginTop: '20px'}}><strong>By signing up for BBR Bass Boat Rentals, users agree to abide by these rules and contribute to a safe, fair, and trusted community of boat renters and owners.</strong></p>
        </Modal.Body>
      </Modal>
      </div>
    </>
  );
};

export default SignupOwner;
