import React, { useState, useEffect } from 'react';
import DataTable from '../WaiversDataTable';
import { getAllWaiversData } from '../utils';

export default function Waivers() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      Header: 'Waiver Name',
      accessor: 'waiverName',
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const waiversData = await getAllWaiversData();
  
        // Filter out waivers with less than two questions
        const filteredWaivers = waiversData.filter(waiver => waiver.questions.length > 1);
  
        const simplifiedData = filteredWaivers.map(waiver => ({
          waiverName: waiver.waiverName,
          userId: waiver.waiverId,
        }));
  
        setData(simplifiedData);
      } catch (error) {
        console.error('Error fetching waivers data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  
  

  

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>All Waivers</h1>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="card calendar-container">
            <div className="card-body">
              {loading ? (
                <p>Loading...</p>
              ) : (
                <DataTable columns={columns} data={data} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
