import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Container, Image } from "react-bootstrap"
import { useNavigate, useParams } from 'react-router-dom';
import TipPayment from "./TipPayment"
import { getBookingByID, getUserByID } from '../utils';

const Tip = () => {
    const [bookingData, setBookingData] = useState(null);
    const [ownerData, setOwnerData] = useState(null);

    const { dynamicId } = useParams();

    useEffect(() => {
      const fetchDetails = async () => {
          try {
            const bookingDetails = await getBookingByID(dynamicId);
            setBookingData(bookingDetails);

            const ownerDetails = await getUserByID(bookingDetails.owner);
            setOwnerData(ownerDetails);

            console.log('ownerDetails');
            console.log(ownerDetails);
          } catch (error) {
            console.error('Error fetching listing details:', error);
          }
      };
      fetchDetails();
  }, []);

  return (
    <>
    <section className='cs-bg-dark py-4 cs-section'>
    <Container className="">
        <Row className="justify-content-center">
            <Col xs={8}>
                <Card>
                    <Card.Body className="d-flex flex-column justify-content-center align-items-center bg-cs-secondary">
                        <Card.Title className="text-center text-white"><h3>Leave a tip for the Boat Owner</h3></Card.Title>
                        <Image
                            className="mt-4 text-center"
                            src={ownerData ? ownerData.avatar : 'https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp'}
                            roundedCircle
                            width={180}
                            height={180}
                            style={{objectFit: 'cover'}}
                        />
                        <Card.Text>
                            <h3 className="mt-4 text-center text-white">{ownerData ? ownerData.firstName + ' ' + ownerData.lastName : 'Loading...'}</h3>
                            <p className="mt-4 text-center text-white" >for the booking of</p>
                            <h3 className="text-center text-white">{bookingData?.listingTitle ? bookingData?.listingTitle : ''}</h3>
                        </Card.Text>
                        <TipPayment bookingId={dynamicId}/>
                        </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
    </section>
    
    </>
  )
}

export default Tip