import React, { useState, useEffect } from 'react';
import loadingImage from '../lotties/loading.png'; // Replace with the path to your PNG image

const LoadingTransition = () => {
    const tips = [
        "FUN TIMES UPLOADING"
        // "Two world-record bass were caught and declared a tie weighing at 22-pounds, 4 ounces.",
        // "Largemouth bass use scent mainly to attack and ambush prey.",
        // "Females can lay a large number of eggs between 2,000-40,000 eggs.",
        // "Bass are known for their aggressive strikes, making them exciting for anglers.",
        // "Bass are highly adaptable and can thrive in various water conditions.",
        // "The largemouth bass is the official state fish of Georgia, Mississippi, and Florida.",
        // "Bass can live for more than 10 years, and some have been known to live even longer.",
        // "The world's first bass fishing tournament took place in 1967 at Beaver Lake, Arkansas.",
        // "Bass have excellent low-light vision, making them effective predators during dawn and dusk.",
        // "The largemouth bass has a distinctive lateral line system that helps it detect vibrations in the water.",
        // "Jet skis, also known as personal watercraft (PWC), were first introduced in the 1970s by Kawasaki.",
        // "Modern jet skis are powered by internal combustion engines and use a water jet pump for propulsion.",
        // "Boating is a popular recreational activity, with millions of people enjoying it worldwide.",
        // "Bass boats are specially designed for bass fishing, typically featuring low sides and a platform for casting.",
        // "Many bass boats come equipped with advanced fish-finding technology to locate fish in the water.",
        // "Boating safety is crucial, and wearing life jackets, following navigation rules, and avoiding alcohol are important practices.",
        // "Some bass anglers practice catch and release to preserve fish populations and maintain a sustainable fishing environment.",
        // "Jet skis are known for their agility and speed, allowing riders to perform tricks and maneuvers on the water.",
        // "Bass fishing tournaments often have strict rules and regulations to ensure fair competition and fish conservation.",
        // "Boating and fishing contribute to the economy through equipment sales, tourism, and related industries.",
        // "The world's fastest speedboat, Spirit of Australia, set the water speed record in 1978 at 318.75 mph.",
        // "Jet skis are used for various water sports, including racing, freestyle riding, and tow sports like wakeboarding.",
        // "The term 'bass' can refer to various species, including largemouth bass, smallmouth bass, and spotted bass.",
        // "Bass are cold-blooded, meaning their body temperature is influenced by the temperature of the surrounding water.",
        // "Some bass anglers use artificial lures to mimic the movement of prey and attract bass to strike.",
        // "Boating enthusiasts often enjoy cruising, waterskiing, wakeboarding, and other recreational activities on the water.",
        // "Jet ski racing is a popular competitive sport, with events held globally and different classes based on the jet ski's specifications.",
        // "Bass fishing is a year-round activity, with different seasons affecting bass behavior and preferred baits.",
        // "Boats come in various types, including fishing boats, pontoon boats, sailboats, and yachts, each designed for specific purposes.",
        // "Jet skis have evolved from stand-up models to sit-down designs, providing greater stability and comfort for riders.",
        // "Bass have a keen sense of hearing, allowing them to detect vibrations and sounds in the water.",
        // "Boating safety courses are available to educate boaters on navigation rules, emergency procedures, and responsible boating practices.",
        // "Jet skis are commonly used for rescue missions by lifeguards and emergency responders due to their maneuverability.",
        // "Bass fishing requires knowledge of fish behavior, seasonal patterns, and the ability to adapt to changing conditions on the water.",
        // "Boating can have environmental impacts, and responsible boaters practice Leave No Trace principles to minimize their footprint.",
        // "Jet skis are known for their nimble handling, allowing riders to explore narrow waterways and navigate tight turns.",
        // "Bass have a diverse diet that includes fish, insects, crayfish, and even small mammals or birds.",
        // "Boat shows and expos are held worldwide, showcasing the latest in boating technology, accessories, and watercraft.",
        // "Jet ski maintenance is crucial for optimal performance, including regular checks on the engine, fuel system, and hull.",
        // "Bass fishing requires knowledge of different fishing techniques, such as flipping, pitching, and topwater fishing.",
        // "Boating regulations vary by region, and boaters should be aware of local laws, speed limits, and safety requirements.",
        // "Jet skis are often used for exploring coastal areas, islands, and remote waterways that may be inaccessible to larger boats.",
        // "Bass fishing can be a social activity, with bass fishing clubs, tournaments, and community events bringing anglers together.",
        // "Boating and fishing contribute to conservation efforts, as many organizations work to protect water ecosystems and fish habitats.",
        // // Add more fun facts as needed
    ];
    
    const getRandomIndex = () => Math.floor(Math.random() * tips.length);

    const [currentTipIndex, setCurrentTipIndex] = useState(getRandomIndex());
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setCurrentTipIndex((prevIndex) => (prevIndex + 1) % tips.length);
        }, 1500);

        return () => clearTimeout(timeout);
    }, [currentTipIndex]);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    return (
        <div className={`loading-container ${imageLoaded ? 'fade-in-out' : ''}`}>
            {/* <img
                src={loadingImage}
                alt="Loading"
                style={{ width: '150px'}}
                onLoad={handleImageLoad}
            />
            <h6 style={{ marginTop: '20px', marginBottom: '15px' }}>
                Did you know?
            </h6> */}
            <p className="tip-transition" style={{fontSize: '24px', fontWeight: '600'}} onAnimationEnd={() => setCurrentTipIndex(getRandomIndex())}>
                {tips[currentTipIndex]}
            </p>
        </div>
    );
};

export default LoadingTransition;
