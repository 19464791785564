import React from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

export default function DatepickerCompo() {
    return (
        <>
        <LocalizationProvider dateAdapter={AdapterDayjs}> 
        <DatePicker
            label="Preffered Date" 
            required
        />
        </LocalizationProvider>
        </>
      )
}