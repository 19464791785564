import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { getListingByID } from '../utils';
import CreateListing from '../createListing';


export default function Listing() {

    const[listing, setListing] = useState([]);

    const { dynamicId } = useParams();

    useEffect(() => {
      const fetchListingDetails = async () => {
          const listingID = String(dynamicId);
          try {
              const details = await getListingByID(listingID);
              // Append listingID to the details object
              details.listingId = listingID;
              setListing(details);
          } catch (error) {
              console.error('Error fetching listing details:', error);
          }
      };
  
      fetchListingDetails();
  }, []);
    console.log(listing);

  return (
    <>
    <div class="row">
        <div class="col">
            <div class="page-description">
                <Row>
                    <Col xs={12}>
                        <h1>Listing</h1>
                    </Col>
                </Row>
            </div>
        </div>
    </div>

    <CreateListing initialData={listing} />

    
    </>
  )
}
