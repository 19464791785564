import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { getBookingByID, sendSMS, getCurrentUserDetails, getInquiryDataById, getUserByID, sendEmail, getWaiverDataByName, getWaiverDescriptionByName, tripStatusUpdateEmailBody } from '../utils';
import { get, getDatabase, push, ref, serverTimestamp, set, update } from 'firebase/database';
import SignatureCanvas from 'react-signature-canvas';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Alert, Checkbox, FormControlLabel } from '@mui/material';
import { ThreeDots } from 'react-loader-spinner'

export default function StartTrip() {
  
  const { dynamicId } = useParams();
  const elements = useElements();
  const stripe = useStripe();
  const navigate = useNavigate();

  const [acknowledgement, setAcknowledgement] = useState(false);

  const [signatureUrl,  setSignatureFile] = useState('');

  const [formResponses, setFormResponses] = useState([]);
  const [adultFormResponses, setAdultFormResponses] = useState([]);
  const [minorFormResponses, setMinorFormResponses] = useState([]);
  const [waiverQuestions, setWaiverQuestions] = useState([]);
  const [adultWaiverQuestions, setAdultWaiverQuestions] = useState([]);
  const [minorWaiverQuestions, setMinorWaiverQuestions] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [signingWaiverName, setSigningWaiverName] = useState([]);
  const [waiverDescription, setWaiverDescription] = useState([]);
  const [inquiryDetails, setInquiryDetails] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const signatureRef = React.useRef();

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed
      
      setIsMobile(isMobile);
      
    };

    // Initial setup
    handleResize();

    // Attach event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleImageUpload = async (question, files) => {
    const formData = new FormData();
  
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
  
    try {
      const response = await fetch('https://www.bbrbassboatrentals.com/upload-images', {
        method: 'POST',
        body: formData,
      });
  
      if (response.ok) {
        const data = await response.json();
        const filenames = data.file_names;
        console.log(question);
        if(question == 'Signature') {
          setSignatureFile('https://www.bbrbassboatrentals.com/images/'+filenames);
          console.log('https://www.bbrbassboatrentals.com/images/'+filenames)
        }
        const existingResponseIndex = formResponses.findIndex((response) => response.Question === question);
  
        if (existingResponseIndex !== -1) {
          const updatedResponses = [...formResponses];
          updatedResponses[existingResponseIndex].Answer = question === 'Signature' ? filenames : filenames;
          console.log(updatedResponses)
          setFormResponses(updatedResponses);
        } else {
          setFormResponses([...formResponses, { Question: question, Answer: question === 'Signature' ? filenames : filenames }]);
        }
      } else {
        console.error('Error uploading images:', response.statusText);
      }
    } catch (error) {
      console.error('Error uploading images:', error);
    }
  };
  

  const handleSaveSignature = async () => {
    if (signatureRef.current.isEmpty()) {
      console.error('Signature is empty');
      return;
    }
  
    const signatureImage = signatureRef.current.getTrimmedCanvas().toDataURL('image/png');
    const signatureFile = dataURLtoFile(signatureImage, 'signature.png');
    await handleImageUpload('Signature', [signatureFile]);
};

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };
  
  useEffect(() => {
    const todayDateElement = document.querySelector('.today-date');
    if (todayDateElement) {
      const todaysDate = new Date().toLocaleDateString(); // Get today's date in a readable format
      todayDateElement.textContent = `Today's Date: ${todaysDate}`;
    }
  }, [waiverDescription]);

  const handleInputChange = (question, answer) => {
    if (question === 'date' || question === 'country') {
      // Handle date and country inputs
      const updatedResponses = [...formResponses];
      const existingResponseIndex = updatedResponses.findIndex((response) => response.Question === question);
  
      if (existingResponseIndex !== -1) {
        updatedResponses[existingResponseIndex].Answer = answer;
      } else {
        updatedResponses.push({ Question: question, Answer: answer });
      }
  
      setFormResponses(updatedResponses);
    } else {
      const existingResponseIndex = formResponses.findIndex((response) => response.Question === question);
  
      if (existingResponseIndex !== -1) {
        const updatedResponses = [...formResponses];
        updatedResponses[existingResponseIndex].Answer = answer;
        setFormResponses(updatedResponses);
      } else {
        setFormResponses([...formResponses, { Question: question, Answer: answer }]);
      }
    }
  };

  const [error, setError] = useState('');

  const holdPayment = async () => {
    let result = {};
    if (!stripe || !elements) {
        return;
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
    });

    if (error) {
        console.error(error);
        // Throw an error to stop further execution
        setError('Oops... something went wrong.');
        throw new Error('Error creating payment method');
    }

    try {
        const response = await fetch('https://www.bbrbassboatrentals.com/payment-hold', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                amount: 80000,
                payment_method: paymentMethod.id,
            }),
        });

        result = await response.json();
        console.log('result: ', result);

        if (!result.error) {
            const database = getDatabase();
            const paymentRef = ref(database, `payments/${bookingData.paymentId}`);

            // Retrieve existing data
            const currentData = (await get(paymentRef)).val() || {};

            const paymentHeld = {
                id: result.paymentIntent.id,
                amount: result.paymentIntent.amount,
                status: 'pending',
            };

            // Merge existing data with new data
            const mergedData = {
                ...currentData,
                paymentHeld: paymentHeld,
            };

            // update the database with merged data
            await update(paymentRef, mergedData);
            setError('');
        }
        else {
          setError(result.error);
          setLoading(false);
          throw new Error('Error capturing payment');
        }
    } catch (error) {
      setError(result.error);
      setLoading(false);
      throw new Error('Error capturing payment down');
    }
};

useEffect(() => {
  console.log(formResponses);
}, [formResponses])

  const [loading, setLoading] = useState(false);
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      await holdPayment();
      // Await the signature saving process to complete before proceeding
      await handleSaveSignature();
  
      // Ensure formResponses is up-to-date after async operations
      const updatedFormResponses = await new Promise((resolve) => {
        setFormResponses((prevResponses) => {
          const responses = [...prevResponses];
          resolve(responses);
          return responses;
        });
      });
  
      // Define the path in the Firebase database
      const database = getDatabase();
      const tripDataRef = ref(database, `bookings/${dynamicId}/tripData/waivers/Captain's Waiver & Equipment Checklist`);
      
      const questionsArray = updatedFormResponses.map((response) => {
        let answer;
        if (response.type === 'signature') {
          answer = response.Answer[0];
          console.log(answer);
        } else if (response.type === 'images') {
          // For images, use an array of URLs
          answer = response.Answer.map((image) => ({ url: image }));
        } else {
          // For other types, use the answer directly
          answer = response.Answer;
        }
  
        return {
          question: waiverQuestions.find((q) => q.id === response.Question)?.question || response.Question,
          answer,
        };
      });
      
      await push(tripDataRef, { waiverName: "Captain's Waiver & Equipment Checklist", questions: questionsArray });
  
      const bookingRef = ref(database, `bookings/${dynamicId}`);
      await update(bookingRef, { status: 'tripStarted' });
      
      const tripData = ref(database, `bookings/${dynamicId}/tripData`);
      await update(tripData, { startTimestamp: serverTimestamp() });
  
      console.log('Form responses saved to Firebase:', questionsArray);
  
      const renterData = await getCurrentUserDetails();
      const renterName = renterData.firstName + ' ' + renterData.lastName;
  
      const emailBody = await tripStatusUpdateEmailBody(renterName, bookingData.listingTitle, 'Trip Started');
      sendEmail(renterData.email, 'Trip Started', emailBody);

      const renterSmsBody = `Your trip of ${bookingData.listingTitle} has now started.`
      const renterSmsResponse = sendSMS(renterData.phone, renterSmsBody);
      console.log(renterSmsResponse)

      const ownerDetails = await getUserByID(bookingData.owner);
      const ownerName = ownerDetails.firstName + ' ' + ownerDetails.lastName;
      const emailOwnerBody = await tripStatusUpdateEmailBody(ownerName, bookingData.listingTitle, 'Trip Started');
      sendEmail(ownerDetails.email, 'Trip Started', emailOwnerBody);
  
      const smsBody = `Trip of ${bookingData.listingTitle} with renter ${renterName} has now started.`
      const smsResponse = sendSMS(ownerDetails.phone, smsBody);
      console.log(smsResponse)

      setLoading(false);
      navigate(`/dashboard/event/${dynamicId}`);
    } catch (error) {
      setLoading(false);
      console.error('Error submitting form:', error);
    }
  };
  

  useEffect(() => {
    const fetchWaiverData = async () => {
      try {
        const bookingData = await getBookingByID(dynamicId);
        if (!bookingData) return; // No need to proceed if bookingData is null
  
        console.log('bookingData:', bookingData);
  
        setBookingData(bookingData);
  
        const inquiryData = await getInquiryDataById(bookingData.inquiryId);
        console.log('inquiryData:', inquiryData);
        setInquiryDetails(inquiryData);
        
        const waiverName = `Captain's Waiver & Equipment Checklist`;
        
        const questions = await getWaiverDataByName(waiverName);
        const description = await getWaiverDescriptionByName(waiverName);
  
        setSigningWaiverName(waiverName);
        setWaiverDescription(description);
        
        const questionsWithId = questions.map((question, index) => ({ ...question, id: index }));
        const currentUserDetails = await getCurrentUserDetails();
  
        setWaiverQuestions(questionsWithId);
        setUserData(currentUserDetails);
      } catch (error) {
        console.error('Error fetching waiver data:', error);
      }
    };
  
    fetchWaiverData();
  }, [dynamicId]);

  return (
    <>
      <Row className='card p-5 d-flex flex-row align-items-center' style={{gap: '20px'}}>
        <Col xs={12} md={3}>
          <img className='img-fluid' src="/assets/backend/images/logo.webp"></img>
        </Col>
        <Col xs={12} md={9}>
          <h3 className='' style={{color: '#27313F', fontWeight: '800', textAlign: 'center'}}>BBR BASS BOAT RENTALS, LLC</h3>
        </Col>
      </Row>

      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>{signingWaiverName}</h1>
            <div className='waiverDescrption mt-4' dangerouslySetInnerHTML={{ __html: waiverDescription }} />
          </div>
        </div>
      </div>
      <Row>
        <Col>
          <div className="card p-4">
            <form onSubmit={handleSubmit}>
              {waiverQuestions.map((questionData) => (
                <div key={`${questionData.question}-${questionData.id}`}>
                  <label className="form-label form-label mt-2">{questionData.question}:</label>
                  <br />
                  {questionData.type === 'text' ? (
                    <input
                      required={questionData?.required && questionData?.required == 'true' ? true : false}
                      type="text"
                      className="form-control form-control"
                      value={formResponses.find((response) => response.Question === questionData.id)?.Answer || ''}
                      onChange={(e) => handleInputChange(questionData.id, e.target.value)} 
                    />
                  ) : questionData.type === 'images' ? (
                    <input
                      required={questionData?.required && questionData?.required == 'true' ? true : false}
                      type="file"
                      accept="image/*"
                      capture="camera"
                      multiple
                      onChange={(e) => handleImageUpload(questionData.id, e.target.files)} 
                    />
                  ) : questionData.type === 'date' ? (
                    <input
                      required={questionData?.required && questionData?.required == 'true' ? true : false}
                      type="date"
                      className="form-control form-control"
                      value={formResponses.find((response) => response.Question === 'date')?.Answer || ''}
                      onChange={(e) => handleInputChange('date', e.target.value)} 
                    />
                  ) : questionData.type === 'country' ? (
                    <select
                    required={questionData?.required && questionData?.required == 'true' ? true : false}
                      className="form-control"
                      name="settingsInputCountry"
                      value={formResponses.find((response) => response.Question === 'country')?.Answer || ''}
                      onChange={(e) => handleInputChange('country', e.target.value)} 
                    >
                      <option>United States</option>
                      {/* Add other country options if needed */}
                    </select>
                  ) : questionData.type === 'signature' ? (
                    <div>
                      <SignatureCanvas
                        ref={signatureRef}
                        required={questionData?.required && questionData?.required == 'true' ? true : false}
                        canvasProps={{ width: isMobile ? 250 : 500, height: 200, className: 'sigCanvas' }}
                      />
                      <button type="button" onClick={() => signatureRef.current.clear()} className="btn btn-primary">
                        Clear
                      </button>
                    </div>
                  ) : (
                    questionData.options.map((option) => (
                      <div className="btn btn-light m-1 mb-4 form-check" key={option}>
                        <label htmlFor={option} className="form-check-label">
                          {option}
                        </label>
                        <input
                          required={questionData?.required && questionData?.required == 'true' ? true : false}
                          type="radio"
                          name={questionData.id}
                          value={option}
                          id={option}
                          className="form-check-input"
                          checked={
                            formResponses.find((response) => response.Question === questionData.id)?.Answer ===
                            option
                          }
                          onChange={() => handleInputChange(questionData.id, option)}
                        />
                      </div>
                    ))
                  )}
                </div>
              ))}

              <Row className='mt-3'>
                  <Col>
                  <h6>A security amount of $800 will be withheld.</h6>
                      <CardElement
                      className="form-control"
                      options={{
                          style: {
                          base: {
                              fontSize: '16px',
                              color: '#424770',
                              '::placeholder': {
                              color: '#aab7c4',
                              },
                          },
                          invalid: {
                              color: '#9e2146',
                          },
                          },
                      }}
                      />
                  </Col>
              </Row>
              
              <Row className='mt-4'>
              <Col>
                <div className='d-flex mt-1 additional-box'>
                  <FormControlLabel style={{alignItems: 'flex-start'}} required control={<Checkbox checked={acknowledgement} />} onChange={setAcknowledgement} />
                  <div>
                    <p style={{fontWeight: '500', lineHeight: '28px'}}>Please check this box to acknowledge that you will be responsible for a $800.00 refundable security fee <span style={{color: 'red'}}>*</span></p>
                    <p style={{fontStyle: 'italic', marginTop: '-11px'}}>Required for each rental transaction, returned within 7 days.</p>
                  </div>
                </div>

              </Col>
            </Row>
            {error && <Alert className='mt-3' severity='error'>{error}</Alert>}
            <button type="submit" className="btn btn-primary mt-3">
            {loading ? <ThreeDots
              visible={true}
              height="22"
              width="22"
              color="#fff"
              radius="20"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
              /> 
            :
              'Submit Waiver'
            }
            </button> 
            </form>
          </div>
        </Col>
      </Row>
    </>
  );
}
