import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const ImageSlider = ({ images }) => {
  const [isSliderReady, setSliderReady] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [isLightboxOpen, setLightboxOpen] = useState(false);

  useEffect(() => {
    setSliderReady(true);
  }, [images]);

  const settings = {
    infinite: true,
    speed: 500,
    adaptiveHeight: true,
    focusOnSelect: true,
    slidesToShow: 3,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const openLightbox = (index) => {
    setLightboxIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  return (
    <div className="slider-container">
      {isSliderReady && (
        <>
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index} onClick={() => openLightbox(index)} className="slider-image">
                <img src={image} alt={`slide ${index + 1}`} />
              </div>
            ))}
          </Slider>
{/* 
          {isLightboxOpen && (
            <Lightbox
              mainSrc={images[lightboxIndex]}
              nextSrc={images[(lightboxIndex + 1) % images.length]}
              prevSrc={images[(lightboxIndex + images.length - 1) % images.length]}
              onCloseRequest={closeLightbox}
              onMovePrevRequest={() => setLightboxIndex((lightboxIndex + images.length - 1) % images.length)}
              onMoveNextRequest={() => setLightboxIndex((lightboxIndex + 1) % images.length)}
            />
          )} */}
        </>
      )}
    </div>
  );
};

export default ImageSlider;
