import React, { useEffect, useState } from 'react'
import Listing from '../FrontendSingleListing';
import { getAllListings } from '../utils';

import { useParams } from 'react-router-dom';

export default function FrontEndListings() {
      
      const[listings, setListings] = useState([]);
      const [transformedData, setTransformedData] = useState([]);
      
      
      // Sample columns
      const columns = [
        { Header: 'ID', accessor: 'id' },
        { Header: 'Name', accessor: 'name' },
        { Header: 'Price', accessor: 'price' },
        { Header: 'backgroundImage', accessor: 'backgroundImage' },
        // ... more columns
      ];

    //   const [listingDetails, setListingDetails] = useState(null);

    //   const { id } = useParams();
      useEffect(() => {
        const fetchListings = async () => {
            try {
                const listings = await getAllListings();
                
                const transformedDataPromises = listings.map(async (listing) => {

                  console.log('transformedDataPromises');
                  console.log(listing.boatStatus);
      
                  if(listing.boatStatus == 'Active'){
                      console.log('Boat is active');
                  
                    return {
                      id: String(listing.id),
                      name: listing.listingTitle, // Replace 'listingTitle' with the actual property name in your listing object
                      price: '', // Hardcoded price
                      backgroundImage: listing.featured_image,
                    };
      
                  }else{
                    return '';
                  }
                });
          
                const transformedData = await Promise.all(transformedDataPromises);
                const filteredData = transformedData.filter(item => item !== '');

                console.log('Transformed Data:', transformedData);



                setListings(listings);
                setTransformedData(filteredData);
                console.log(listings);
            } catch (error) {
                // Handle error appropriately, e.g., log it or show an error message
                console.error("Error fetching listings:", error);
            }
        }

        fetchListings();
    }, []); // Empty dependency array means the effect runs once when the component mounts
    
      
 

  return (
    <>
    <div class="row">
        <div class="col">
        <Listing data={transformedData} columns={columns}/>
        </div>
    </div>


    </>
  )
}
