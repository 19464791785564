// Payment.js
import DataTable from '../DataTable';
import { useAuth } from '../../contexts/AuthContext';
import { useEffect, useState } from 'react';
import { getUserByID, getPaymentsByUserId, getListingByBookingId, getAllDisputes, getListingByID, getCurrentUserDetails } from '../utils';
import { useNavigate } from 'react-router-dom';
export default function Disputes() {

  const Navigate = useNavigate();
  const auth = useAuth();

  const [formattedDisputes, setFormattedDisputes] = useState([]);
  const [userDetails, setuserDetails] = useState([]);
  
  const handleRowClick = (row) => {
    if (row && row.original && row.original.bookingId) {
      const bookingId = row.original.bookingId;
      Navigate(`/dashboard/event/${bookingId}`);
    } else {
      console.error('Invalid row object or missing bookingId');
    }
  };
  

  useEffect(() => {

    const fetchUserData = async () => {
      try {
        const disputesDetails = await getAllDisputes();

        const formattedData = disputesDetails.map(async (dispute) => {

          const listingData = await getListingByID(dispute.listingId);

          const userData = await getCurrentUserDetails(auth.currentUser.uid);
          setuserDetails(userData);

          console.log(userData);

          return {
            name: listingData.listingTitle,
            dateTime: dispute.timestamp,
            amount: '$'+parseInt(dispute.amount),
            bookingId: dispute.bookingId,
            reason: dispute.reason,
            status: dispute.status,
            // Add more properties as needed
          };
        });

        Promise.all(formattedData).then((result) => {
          setFormattedDisputes(result);
        });
      } catch (error) {
        console.error('Error fetching payment details:', error);
      }
    };

    fetchUserData();
  }, [auth.currentUser.uid]);

  const columns = [
    { Header: 'Name', accessor: 'name' },
    { Header: 'Date Time', accessor: 'dateTime' },
    { Header: 'Amount', accessor: 'amount' },
    { Header: 'Booking ID', accessor: 'bookingId' },
    { Header: 'Reason', accessor: 'reason' },
    { Header: 'Status', accessor: 'status' },
    // Add more columns as needed
  ];

  // const data = [
  //   { name: 'John Doe', dateTime: '2023-01-15 10:30 AM', amount: 100.0, bookingId: 'B12345' },
  //   { name: 'Jane Doe', dateTime: '2023-02-02 03:45 PM', amount: 75.5, bookingId: 'B67890' },
  //   // Add more data rows as needed
  // ];

  return (
    <>
      {userDetails && userDetails.role === 'Admin' ? (
        // If the user is an Admin, render the following JSX
        <>
          <div className="row">
            <div className="col">
              <div className="page-description">
                <h1>Disputes</h1>
              </div>
            </div>
          </div>
  
          <div className="row">
            <div className="col">
              <div className="card calendar-container">
                <div className="card-body">
                  <DataTable columns={columns} data={formattedDisputes} onRowClick={handleRowClick} />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        // If the user is not an Admin, navigate to '/dashboard'
        Navigate('/dashboard')
      )}
    </>
  );
}
