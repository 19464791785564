import React from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getDatabase, ref, get, push, update } from 'firebase/database';
import { getBookingByID} from './utils';
import dayjs from 'dayjs';
import { useAuth } from '../contexts/AuthContext';
import { Alert} from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import { ThreeDots } from 'react-loader-spinner';


const PaymentForm = (bookingId) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const auth = useAuth();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [bookingData, setBookingData] = useState([]);
  const [amount, setAmount] = useState();

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        console.log('bookingDetails');
        console.log(bookingId.bookingId);
        const bookingDetails = await getBookingByID(bookingId.bookingId);
        if (!bookingDetails) {
          return;
        }
        setBookingData(bookingDetails);
      } catch (error) {
        console.error('Error fetching listing details:', error.message);
      }
    };
  
    fetchDetails();
  }, [bookingId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');
  
    if (!stripe || !elements) {
      return;
    }
  
    const { token, error } = await stripe.createToken(elements.getElement(CardElement));
  
    if (error) {
      console.error(error);
      setError(error.message || "There was an error processing your card. Please try again.");
      setLoading(false);
      return;
    } else {

      try {
        const response = await fetch('https://www.bbrbassboatrentals.com/capture-payment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': 'https://www.bbrbassboatrentals.com',
          },
          mode: 'cors',
          body: JSON.stringify({
            tokenId: token.id,
            amount: (amount*100),
          }),
        });
  
        const result = await response.json();
        console.log('result: ', result);
  
        if (result.success) {

          const database = getDatabase();

          try {
            const paymentRef = ref(getDatabase(), 'payments');
            let paymentData = {};
            paymentData = {
                reason: 'Tip',
                ownerAmount: (amount*100),
                dateTime: dayjs().format('dddd, MMMM D, YYYY HH:mm:ss'),
                transactionId: result.paymentIntent.id,
                userId: auth.currentUser.uid,
                owner: bookingData.owner,
                bookingId: bookingId.bookingId
            };
                        
              await push(paymentRef, paymentData);

            //   const renterData = await getCurrentUserDetails();
            //   const subjectEmail = 'Payment Successful';
            //   const renterName = renterData.firstName + ' ' + renterData.lastName;
            //   const emailBody = await BookingStatusUpdateEmailBody (renterName, bookingData.listingTitle, 'Paid')
            //   sendEmail (renterData.email, subjectEmail, emailBody);

            //   const ownerData = await getUserByID(bookingData.owner);
            //   const subjectOwnerEmail = 'Payment Successful';
            //   const ownerName = ownerData.firstName + ' ' + ownerData.lastName;
            //   const emailOwnerBody = await BookingStatusUpdateEmailBody (ownerName, bookingData.listingTitle, 'Paid')
            //   sendEmail (ownerData.email, subjectOwnerEmail, emailOwnerBody);

              const ownerMetaPayments = ref(database, `ownerMeta/${bookingData.owner}`)

              const previousOwnerPaymentsSnapshot = await get(ownerMetaPayments);


              if (previousOwnerPaymentsSnapshot.exists()) {

              const previousOwnerPayments = previousOwnerPaymentsSnapshot.val();

              const previousCurrentAmount = previousOwnerPayments.payments.currentAmount;
              const previousTotalAmount = previousOwnerPayments.payments.totalAmount;
              const ownerPayments = {
                currentAmount: parseInt(previousCurrentAmount) + parseInt(amount*100),
                totalAmount: parseInt(previousTotalAmount) + parseInt(amount*100),
              }
              await update(ownerMetaPayments, {payments: ownerPayments});
            }
            } catch (error) {
              console.log('Error saving data: ', error.message);
              setLoading(false);
            }
            navigate('/dashboard/thank-you');
            setLoading(false);
        }else if(result.error){
          console.error('Payment failed:', result.error);
          setError(result.error);
          setLoading(false);
        } else {
          console.error('Payment failed:');
          setError('Payment failed: ');
          setLoading(false);
        }
      } catch (error) {
        console.error('Error capturing payment:', error);
       setError(error);
        setLoading(false);
      }
    }
  };

  return (

    <>
    <Row className='p-4' style={{width: '60%'}}>
    <div class="">
        <div class="mt-2">
            <div class="row">
                <div class="col-sm-4 d-flex align-items-center">
                <h3 class="mb-0 text-white">Amount</h3>
                </div>
                <div class="col-sm-8 d-flex gap-3">
                <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} className='form-control' style={{ fontSize: '24px', border: 'none' }} placeholder="$"/>
                </div>
            </div>
            <hr/>
            </div>
        </div>
      <Col>
        <form className="d-flex flex-column" onSubmit={handleSubmit}>
          <div style={{ marginBottom: '20px' }}>
            {/* Style the CardElement */}
            <Row>
            </Row>
            <Row>
              <Col>
                <CardElement
                  className="form-control"
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#424770',
                        '::placeholder': {
                          color: '#aab7c4',
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }}
                />
                {error && <Alert className='mt-3' severity='error'>{error}</Alert>}
              </Col>
            </Row>      
          </div>
          <button variant="primary" type="submit" className='btn btn-light text-dark m-1'>
            {loading ? 
            <ThreeDots
              visible={true}
              height="22"
              width="22"
              color="#fff"
              radius="20"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
              /> : 'Tip'}
            </button>
        </form>
      </Col>
      
    </Row>
    </>
    
  );
};

export default PaymentForm;
