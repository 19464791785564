import React, { useState, useRef } from 'react';
import { getDatabase, ref, onValue, get, set, push, update, serverTimestamp } from 'firebase/database';
import { useAuth } from '../contexts/AuthContext';
import { useEffect } from 'react';
import { getUserByID, getInquiryDataById, getCurrentUserDetails, getListingByID, updateInquiryStatus, NewOfferRecievedEmailBody, sendEmail, OfferAccepetedRenterEmailBody, OfferAccepetedOwnerEmailBody, getPlatformHourlyRate, sendSMS } from './utils';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import { useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Alert } from '@mui/material';
import { ThreeDots } from 'react-loader-spinner'

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import e from 'cors';


export default function Inbox() {
  const scroll = useRef();
  const auth = useAuth();
  const currentUser = auth.currentUser;
  const [receiverId, setReceiverId] = useState(''); // Replace with actual receiver user ID
  const [message, setMessage] = useState('');
  const [chats, setChats] = useState([]);
  const [otherParticipants, setOtherParticipants] = useState({});
  const [otherParticipant, setOtherParticipant] = useState({});
  const [selectedChat, setSelectedChat] = useState(null);
  const [listingTitle, setlistingTitle] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [sameDayHours, setSameDayHours] = useState(0);
  const [sameDayMinutes, setSameDayMinutes] = useState(0);
  const [overnight, setOvernight] = useState(0);
  const [loading, setLoading] = useState(false);
  const [offeredDuration, setOfferedDuration] = useState('0');
  const [arivingDateTime, setArivingDateTime] = useState('');
  const [pickupAddress, setPickupAddress] = useState('');
  const [dbPickupAddress, setDbPickupAddress] = useState('');
  const [dbDropoffAddress, setDbDropoffAddress] = useState('');
  const [dropOffAddress, setDropOffAddress] = useState('');

  const handleOfferedTime = (value) => {
    setOfferedTime(value)
  };

  const handleSameDayHours = (value) => {
    setSameDayHours(value)
    if(value >= 24) {
      setTrailerCheckbox(true);
    }
  };

  const convert12to24 = (time12h) => {
    if (!time12h) {
      return ''; // Return an empty string for undefined or empty input
    }
  
    const [time, modifier] = time12h.split(':');
    let [hours, minutes] = time.split(':');
  
    if (modifier === 'pm' && hours !== '12') {
      hours = String(parseInt(hours, 10) + 12);
    }
  
    if (modifier === 'am' && hours === '12') {
      hours = '00';
    }
  
    return `${hours}:${minutes || '00'}`;
  };
  
  

  const handleOvernight = (value) => {
    if(value > 0) {
      setTrailerCheckbox(true);
      setSameDayHours(0);
    }

    setOvernight(value);

  }

  const Navigate = useNavigate();

  const [currentMessageListingDetails, setCurrentMessageListingDetails] = useState([]);

  const [deliveryPickupCheckbox, setDeliveryPickupCheckbox] = useState(false);
  const handleDeliveryPickupCheckboxChange = (event) => {
    setDeliveryPickupCheckbox(event.target.checked);
  };

  const [trailerCheckbox, setTrailerCheckbox] = useState(false);

  const handleTrailerCheckbox = (event) => {
    setTrailerCheckbox(event.target.checked);
  };
  const [captainCheckbox, setCaptainCheckbox] = useState(false);
  const [additionalCost, setAdditionalCost] = useState(null);

  const handleAdditionalCost = (value) => {
      setAdditionalCost(value);
  }

  const [ownerDiscount, setOwnerDiscount] = useState(0);
  const handleOwnerDiscount = (value) => {
    let maxAllowedValue = 0;
  
    if ((overnight == 1) || (sameDayHours >= 24 && sameDayHours < 48)) {
      maxAllowedValue = 100;
      if (value <= maxAllowedValue) {
        setOwnerDiscount(value);
        setError('');
      } else if (value >= maxAllowedValue) {
        setError('Maximum discount: $100');
      } else {
        setError('');
        setOwnerDiscount(value);
      }
    } else if ((overnight >= 2) || (sameDayHours >= 48)) {
      maxAllowedValue = 200;
      if (value <= maxAllowedValue) {
        setOwnerDiscount(value);
        setError('');
      } else if (value >= maxAllowedValue) {
        setError('Maximum discount: $200');
      } else {
        setOwnerDiscount(value);
        setError('');
      }
    }
  };

  const handleCaptainCheckbox = (event) => {
    setCaptainCheckbox(event.target.checked);
  };
  
  const database = getDatabase();
  
  const selectContact = async (newReceiverId) => {
    // Function to set the receiver ID when a contact is selected
    setReceiverId(newReceiverId);
    try {
      // Await the result of getUserByID before setting otherParticipant
      const otherParticipantDetails = await getUserByID(newReceiverId);
      setOtherParticipant(otherParticipantDetails);

      // Find the chat for the selected contact
      const selectedChat = chats.find(
        (chat) =>
          (currentUser.uid === chat.senderId && newReceiverId === chat.receiverId) ||
          (currentUser.uid === chat.receiverId && newReceiverId === chat.senderId)
      );
      console.log(selectedChat.chatId)
      const chatRef = ref(getDatabase(), `chats/${selectedChat.chatId}`);
      const selectedChatData = await get(chatRef);
      if(selectedChatData.lastSenderId !== currentUser.uid) {
        await update(chatRef, {receiverReadStatus: 1});
      }
      // Set the selected chat in the state
      setSelectedChat(selectedChat);

      scroll.current.scrollIntoView({ behavior: "smooth" });
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const getLocationName = async (lat, lng) => {
    try {

      const dropoffLat = lat;
      const dropoffLlng = lng;

      const dropOffResponse = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${dropoffLat},${dropoffLlng}&key=AIzaSyBfEcoyW9DM7QQWdV3oTjevrfyhX5n5qqg`
      );
      
      const dropOffdata = await dropOffResponse.json();
      const dropoffName =
      dropOffdata.results[0]?.formatted_address || 'Location not found';
      return dropoffName;
    } catch (error) {
      console.error('Error fetching location name:', error);
      return null;
    }
  };

  useEffect(() => {
    //useEffect(() => {
  // Calculate total duration based on selected values
  const totalMinutes = sameDayHours * 60 + sameDayMinutes + overnight * 24 * 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  // Update the duration input and label
  setOfferedDuration(`${hours} hours ${minutes} minutes`);

  // Calculate arriving date and time based on offer date, offer time, and duration
  if (offeredDate && offeredTime) {
    const offerDateTime = new Date(`${offeredDate}T${offeredTime}`);
    const arrivingDateTime = new Date(offerDateTime.getTime() + totalMinutes * 60 * 1000);
    
    // Format the arriving date and time as needed
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    const formattedArrivingDateTime = arrivingDateTime.toLocaleString('en-US', options);

    // Update the arriving date and time input
    setArivingDateTime(formattedArrivingDateTime);
  }

}, [sameDayHours, sameDayMinutes, overnight]);



  const handleAdd = (field) => {
    if (field === 'sameDayHours') {
      setSameDayHours(sameDayHours + 1);
      if(sameDayHours >= 24) {
        setTrailerCheckbox(true);
      }
      setOvernight(0);
    } else if (field === 'sameDayMinutes') {
      setSameDayMinutes(sameDayMinutes + 1);
      setOvernight(0);
    } else {
      // If user selects overnight, set hours and minutes to 0
      setSameDayHours(0);
      setSameDayMinutes(0);
      setOvernight(overnight + 1);
    }
  };

  const handleRemove = (field) => {
    if (field === 'sameDayHours' && sameDayHours > 0) {
      setSameDayHours(sameDayHours - 1);
      if(sameDayHours >= 24) {
        setTrailerCheckbox(true);
      }
    } else if (field === 'sameDayMinutes' && sameDayMinutes > 0) {
      setSameDayMinutes(sameDayMinutes - 1);
    } else if (overnight > 0) {
      setOvernight(overnight - 1);
    }
  };

  const [pickupLocation, setPickupLocation] = useState('');
  const [dropoffLocation, setDropoffLocation] = useState('');
  const [hourlyRate, setHourlyRate] = useState(0);

  const [otherUserDetails, setOtherUserDetails] = useState([]);

  useEffect(() => {
    const fetchOtherUserDetails = async () => {
      try {
        const userDetails = await getCurrentUserDetails();
        setOtherUserDetails(userDetails);
        const rateHourly = await getPlatformHourlyRate();
        setHourlyRate(rateHourly);

        if(currentMessageListingDetails.pricing?.hourlyRate) {
          setHourlyRate(currentMessageListingDetails.pricing?.hourlyRate);
        }
      } catch (error) {
        // Handle error, e.g., redirect to an error page
        console.error("Error fetching other user details", error);
      }
    };
  
    fetchOtherUserDetails();
  }, []);

  
  useEffect(() => {
    // Update the button state when listingTitle changes
    // You can modify this condition based on your specific logic
    if (listingTitle !== '') {
      // Enable the button when listingTitle is not empty
      // You might need to adjust the condition based on your specific requirements
      setButtonDisabled(false);
    }
  }, [listingTitle]);

  useEffect(() => {
    const fetchUserDetails = async (participantIds) => {
      const participantsDetails = {};
      await Promise.all(participantIds.map(async (id) => {
        if (!participantsDetails[id]) {
          const userDetails = await getUserByID(id);
          participantsDetails[id] = userDetails;
        }
      }));
      setOtherParticipants(participantsDetails);
    };

    const database = getDatabase();
    const chatsRef = ref(database, `chats`);

    const unsubscribe = onValue(chatsRef, async (snapshot) => {
      const chatData = snapshot.val() || {};
      const userChats = Object.keys(chatData).map((chatId) => ({
        chatId,
        senderId: chatData[chatId].senderId,
        receiverReadStatus: chatData[chatId].receiverReadStatus,
        senderReadStatus: chatData[chatId].senderReadStatus,
        lastSenderId: chatData[chatId].lastSenderId,
        receiverId: chatData[chatId].receiverId,
        messages: chatData[chatId].messages || [],
      }));
      setChats(userChats);

      // Update the selected chat when chats change
      if (receiverId) {
        const updatedSelectedChat = userChats.find(
          (chat) =>
            (currentUser.uid === chat.senderId && receiverId === chat.receiverId) ||
            (currentUser.uid === chat.receiverId && receiverId === chat.senderId)
        );
        setSelectedChat(updatedSelectedChat);
      }

      // Collect participant IDs for fetching details
      const participantIds = userChats.reduce((ids, chat) => {
        const otherId = currentUser.uid === chat.senderId ? chat.receiverId : chat.senderId;
        if (!ids.includes(otherId)) ids.push(otherId);
        return ids;
      }, []);

      // Fetch user details only if there are new participants
      if (participantIds.length > 0) {
        await fetchUserDetails(participantIds);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [currentUser, receiverId]);

  const formatDate = (inputDate) => {
    try {
      const dateObj = new Date(inputDate);
      const year = dateObj.getFullYear();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
      const day = dateObj.getDate().toString().padStart(2, '0');
  
      const formattedDate = `${year}-${month}-${day}`;
  
      return formattedDate;
    } catch (error) {
      console.error('Error formatting date:', error);
      return null;
    }
  };

  const [error, setError] = useState('');
  const handleOfferPricing = (value) => 
  {
    const maxAllowedValue = calculateEstimatedPricing() !== '' 
    ? `${deliveryPickupCheckbox ? +parseFloat(calculateEstimatedPricing()) + 400 : +calculateEstimatedPricing()}`
    : '';

    const upperEnd = calculateEstimatedPricing() !== '' ? 
    `${deliveryPickupCheckbox ? 1.05 *parseFloat(calculateEstimatedPricing() + 400) 
    : 1.05 *calculateEstimatedPricing()}` : '';

    const lowerEnd = calculateEstimatedPricing() !== '' ? 
    `${deliveryPickupCheckbox ? 0.95 *parseFloat(calculateEstimatedPricing() + 400) 
    : 0.95 *calculateEstimatedPricing()}` : '';
    
    if(value > maxAllowedValue * 1.05) {
      setOfferPrice(value);
      setError('Price should be lower than $'+ upperEnd);
    }
    else if(value < maxAllowedValue * 0.95) {
      setOfferPrice(value);
      setError('Price should be higher than $'+ lowerEnd);
    }
    else {
      setError('');
      setOfferPrice(value);
    }
  };
  

  const getTimestamp = (chat) => {
    // Assuming the timestamp is in the first message
    const lastMessage = chat.messages[chat.messages.length - 1];
    return lastMessage ? lastMessage.timestamp : 0;
  };
  const calculateEstimatedPricing = () => {
    const inquiryHours = sameDayHours;
    const inquiryovernight = overnight;
    let pricing = '';
  
    // Determine base pricing based on inquiry hours
    if (inquiryHours === 8) {
      pricing = trailerCheckbox ? 500 : 400; // Assuming trailer adds $100 to the base price
    } else if (inquiryHours < 8) {
      pricing = (inquiryHours * hourlyRate) + (trailerCheckbox ? 100 : 0);
    } else if (inquiryHours > 8 && inquiryHours < 24) {
      pricing = ((inquiryHours * hourlyRate) - 400) + (trailerCheckbox ? 100 : 0);
    } else if (inquiryHours === 24) {
      pricing = trailerCheckbox ? 500 : 400;
    } else if (inquiryHours > 24) {
      pricing = ((inquiryHours - 14) * hourlyRate) - 500;
    }
  
    if(currentMessageListingDetails && currentMessageListingDetails.boatType != 'Bass Boat' && currentMessageListingDetails?.pricing && currentMessageListingDetails?.pricing?.hourlyRate) {
      const hourlyOption = currentMessageListingDetails.pricing?.hourlyOptions?.find(option => option.hours == inquiryHours);
      console.log(currentMessageListingDetails.pricing.hourlyOptions);
  
      if (hourlyOption) {
        pricing = Number(hourlyOption.price) + (trailerCheckbox ? 100 : 0); // Use the price from dailyOptions if found
      } else {
        pricing = (inquiryHours * hourlyRate) + (trailerCheckbox ? 100 : 0);
      }
    }
  
    // Apply overnight charges
    if (inquiryovernight >= 1 && inquiryovernight <= 6) {
      const basePrices = [400, 600, 700, 800, 900, 1000];
      pricing += basePrices[inquiryovernight - 1]; // Assuming overnight prices are additional
    } else if (inquiryovernight >= 7) {
      const lastPrice = 1100; // Update with the actual last price
      pricing += lastPrice + (inquiryovernight - 6) * 100;
    }
  
    if (currentMessageListingDetails && currentMessageListingDetails?.boatType != 'Bass Boat' && currentMessageListingDetails?.pricing && currentMessageListingDetails.pricing?.dailyOptions) {
      const dailyOption = currentMessageListingDetails.pricing?.dailyOptions?.find(option => option.days == inquiryovernight);
      console.log('dailyOption');
      console.log(currentMessageListingDetails.pricing.dailyOptions);
      if (dailyOption) {
        pricing = Number(dailyOption.price); // Use the price from dailyOptions if found
      }
    }
    return pricing;
    return ''; // Return empty string if inquiryData or its properties are not defined
  };

  const sendMessage = async () => {
    if (message.trim() === '') return;

    const database = getDatabase();

    const [firstUserId, secondUserId] =
      currentUser.uid < receiverId ? [currentUser.uid, receiverId] : [receiverId, currentUser.uid];

    const chatId1 = `${firstUserId}_${secondUserId}`;
    const chatId2 = `${secondUserId}_${firstUserId}`;

    const messageData = {
      senderId: currentUser.uid,
      receiverId: receiverId,
      message,
      timestamp: serverTimestamp(),
    };

    const chatRef1 = ref(database, `chats/${chatId1}`);
    const chatRef2 = ref(database, `chats/${chatId2}`);

    try {

      const chatSnapshot1 = await get(chatRef1);
      const chatSnapshot2 = await get(chatRef2);
      let usedChatId;
      let usedChatRef;

      if (chatSnapshot1.exists()) {
          usedChatId = chatId1;
          usedChatRef = chatRef1;
          await update(chatRef1, { 
            senderReadStatus: 1, 
            receiverReadStatus: 0,
            lastSenderId: currentUser.uid,
            messages: [...chatSnapshot1.val().messages, messageData],
          });
      } 
      else if (chatSnapshot2.exists()) {
          usedChatId = chatId2;
          usedChatRef = chatRef2;
          await update(chatRef2, { 
            senderReadStatus: 1, 
            receiverReadStatus: 0,
            lastSenderId: currentUser.uid,
            messages: [...chatSnapshot2.val().messages, messageData],
          });
      } 
      else {
          usedChatId = chatId1;
          usedChatRef = chatRef1;
          await set(chatRef1, {
            senderId: currentUser.uid,
            receiverId,
            senderReadStatus: 1, 
            receiverReadStatus: 0,
            lastSenderId: currentUser.uid,
            messages: [messageData],
          });
      }

      // Hit the server's endpoint
      await fetch('https://www.bbrbassboatrentals.com/schedule-message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          senderId: currentUser.uid, 
          receiverId, 
          lastSenderId: currentUser.uid,
          chatId: usedChatId
        }),
      });

      setMessage('');
      scroll.current.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };
  
  async function addOfferToInquiry(inquiryId, newOffer) {
    const database = getDatabase();
    const inquiryRef = ref(database, `inquiries/${inquiryId}`);
  
    try {
      // Retrieve the existing inquiry data
      const inquirySnapshot = await get(inquiryRef);
      const existingInquiryData = inquirySnapshot.val();
  
      // Ensure the inquiry exists
      if (!existingInquiryData) {
        throw new Error('Inquiry not found');
      }
  
      // Update the array of offers in the existing data
      const updatedInquiryData = {
        ...existingInquiryData,
        offerData: newOffer,
      };
  
      // Update the inquiry with the new data
      await set(inquiryRef, updatedInquiryData);
  
    } catch (error) {
      console.error('Error adding offer to inquiry:', error);
      throw error; // Re-throw the error to handle it elsewhere if needed
    }
  }

  async function editOfferToInquiry(inquiryId, newOffer) {
    const database = getDatabase();
    const offerRef = ref(database, `inquiries/${inquiryId}/offerData`);
  
    try {
      await set(offerRef, newOffer);
    } catch (error) {
      console.error('Error updating offer:', error);
      throw error;
    }
  }

  const [isCreateOfferModalOpen, setCreateOfferModalOpen] = useState(false);
  const [isEditOfferModalOpen, setEditOfferModalOpen] = useState(false);
  const [offerExpiryToggle, setOfferExpiryToggle] = useState(false);
  const [offeredDate, setOfferedDate] = useState('');
  const [offeredTime, setOfferedTime] = useState('');
  const [offeredHours, setOfferedHours] = useState(0);
  const [offerPrice, setOfferPrice] = useState(1000);

  const [currentOffer, setCurrentOffer] = useState([]);

  const [inquiryDetails, setInquiryDetails] = useState([]);
  const [currentMessageInquiryId, setCurrentMessageInquiryId] = useState('');

  const openCreateOfferModal = async (id) => {
    setLoading(true);
    try {
      setCurrentMessageInquiryId(id);
      const inquiryData = await getInquiryDataById(id);
      // Now you can set the inquiry details in the modal state or directly in the form fields
      // For example, assuming you have state variables like setInquiryDetails
      setInquiryDetails(inquiryData);

      if (inquiryData && inquiryData.sameDayHours !== undefined) {
        setSameDayHours(inquiryData.sameDayHours);
        setOfferedDate(formatDate(inquiryData.prefferedDate));
  
        if(sameDayHours >= 24) {
          setTrailerCheckbox(true);
        }
        
        if(inquiryData.overnight > 0) {
          setOvernight(inquiryData.overnight); 
          setTrailerCheckbox(true);
        }
               
        const convertedTime = convert12to24(inquiryData.prefferedTime || '');
        handleOfferedTime(convertedTime);
        setOfferPrice(inquiryData.estimatedPrice);
      }  
      if (inquiryData && inquiryData.additionals !== undefined) { 
        if(inquiryData.additionals.captain) {
          setCaptainCheckbox(true);
        }
        if(inquiryData.additionals.deliveryPickup) {
          setDeliveryPickupCheckbox(true);
        }
        if(inquiryData.additionals.trailer) {
          setTrailerCheckbox(true);
        }
      }

      const listingData = await getListingByID(inquiryData.listingId);
      if(!listingData) {
        alert('This listing does not exist anymore.');
        setLoading(false);
        return;
      }
      setCurrentMessageListingDetails(listingData);

      const renterDataPromise = await getUserByID(inquiryData.renter);
      const ownerDataPromise = await getUserByID(inquiryData.owner);
      const listingDataPromise = await getListingByID(inquiryData.listingId);

      const [renterData, ownerData, listingPromiseData] = await Promise.all([
        renterDataPromise,
        ownerDataPromise,
        listingDataPromise
      ]);

      const renterName = `${renterData.firstName} ${renterData.lastName}`;
      const ownerName = `${ownerData.firstName} ${ownerData.lastName}`;
      const listingName = listingPromiseData?.listingTitle;

      const bookingTitle = listingName+' - '+renterName;
      setlistingTitle(bookingTitle);
      setCreateOfferModalOpen(true);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching inquiry data', error);
      setLoading(false);
      // Handle the error accordingly
    }
  };

  const [selectedMessageData, setSelectedMessageData] = useState({});
  const openViewOfferModal = async (message) => {
    setLoading(true);
    try {
      setSelectedMessageData(message);
      setCurrentMessageInquiryId(message.inquiryId);
      const inquiryData = await getInquiryDataById(message.inquiryId);  
      setInquiryDetails(inquiryData);
  
      const pickupName = await getLocationName(inquiryData.offerData.pickupAddress.lat, inquiryData.offerData.pickupAddress.lng);
      setPickupLocation(pickupName);
  
      const dropoffName = await getLocationName(inquiryData.offerData.dropOffAddress.lat, inquiryData.offerData.dropOffAddress.lng);
      setDropoffLocation(dropoffName);
      
      const renterDataPromise = await getCurrentUserDetails();
      const listingDataPromise = await getListingByID(inquiryData.listingId);
      setCurrentMessageListingDetails(listingDataPromise);

      const [renterData, listingPromiseData] = await Promise.all([
        renterDataPromise,
        listingDataPromise
      ]);

      const renterName = `${renterData.firstName} ${renterData.lastName}`;
      const listingName = listingPromiseData.listingTitle;

      const bookingTitle = listingName+' - '+renterName;

      setlistingTitle(bookingTitle);
      setCreateOfferModalOpen(true);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching inquiry data', error);
      setLoading(false);
      // Handle the error accordingly
    }
  };

  const openEditOfferModal = async (message) => {
    setLoading(true);
    try {
      setSelectedMessageData(message);
      setCurrentMessageInquiryId(message.inquiryId);
      const inquiryData = await getInquiryDataById(message.inquiryId);  
      setInquiryDetails(inquiryData);
  
      setCurrentOffer(inquiryData.offerData);
      setAdditionalCost(inquiryData.offerData?.additionalCost);
      setTrailerCheckbox(inquiryData.offerData?.trailerCheckbox);
      setCaptainCheckbox(inquiryData.offerData?.captainCheckbox);
      setDeliveryPickupCheckbox(inquiryData.offerData?.deliveryPickup)

      setOfferedDate(inquiryData.offerData?.offeredDate)
      setOfferedTime(inquiryData.offerData?.offeredTime)
      handleSameDayHours(inquiryData.offerData?.offeredHours)
      handleOvernight(inquiryData.offerData?.overnight)
      setOwnerDiscount(inquiryData.offerData?.discount);
      const pickupName = await getLocationName(inquiryData.offerData?.pickupAddress.lat, inquiryData.offerData?.pickupAddress.lng);
      setPickupLocation(pickupName);
      handlePSelect(pickupName);

      const dropoffName = await getLocationName(inquiryData.offerData?.dropOffAddress.lat, inquiryData.offerData?.dropOffAddress.lng);
      setDropoffLocation(dropoffName);
      handleDropOffSelect(dropoffName)

      const renterDataPromise = await getCurrentUserDetails();
      const listingDataPromise = await getListingByID(inquiryData?.listingId);
      setCurrentMessageListingDetails(listingDataPromise);
      console.log(listingDataPromise)
      const [renterData, listingPromiseData] = await Promise.all([
        renterDataPromise,
        listingDataPromise
      ]);

      const renterName = `${renterData.firstName} ${renterData.lastName}`;
      const listingName = listingPromiseData.listingTitle;

      const bookingTitle = listingName+' - '+renterName;

      setlistingTitle(bookingTitle);
      setEditOfferModalOpen(true);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching inquiry data', error);
      setLoading(false);
    }
  };

  const handlePSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setPickupAddress(results[0].formatted_address);
      setDbPickupAddress(latLng);
      console.log(results[0].formatted_address);

    } catch (error) {
      console.error('Error fetching geolocation:', error);
    }
  };
  
  
  const handleDropOffSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setDropOffAddress(results[0].formatted_address);
      setDbDropoffAddress(latLng);
      console.log(results[0].formatted_address);

    } catch (error) {
      console.error('Error fetching geolocation:', error);
    }
  };
  

  const closeCreateOfferModal = () => {
    setSelectedMessageData([]);
    setCurrentMessageInquiryId(null);
    setInquiryDetails([]);
  
    setCurrentOffer([]);
    setOfferedDate(null)
    setOfferedTime(null)
    handleSameDayHours(null)
    handleOvernight(null)
    setPickupLocation(null);
    setDropoffLocation(null);

    setAdditionalCost(0);
    handleOwnerDiscount(0);
    setTrailerCheckbox(false);
    setCaptainCheckbox(false);
    setDeliveryPickupCheckbox(false);

    setCurrentMessageListingDetails([]);
    setCreateOfferModalOpen(false);
  };

  const closeEditOfferModal = () => {
    setSelectedMessageData([]);
    setCurrentMessageInquiryId(null);
    setInquiryDetails([]);
  
    setCurrentOffer([]);
    setOfferedDate(null)
    setOfferedTime(null)
    handleSameDayHours(null)
    handleOvernight(null)
    setPickupLocation(null);
    setDropoffLocation(null);

    setAdditionalCost(0);
    handleOwnerDiscount(0);
    setTrailerCheckbox(false);
    setCaptainCheckbox(false);
    setDeliveryPickupCheckbox(false);

    setCurrentMessageListingDetails([]);
    setEditOfferModalOpen(false);
  };

  const handleToggleChange = () => {
    setOfferExpiryToggle(!offerExpiryToggle);
  };

  const sendOfferMessage = async () => {

    const inquiryData = await getInquiryDataById(inquiryDetails.id);
    // Now you can set the inquiry details in the modal state or directly in the form fields
    // For example, assuming you have state variables like setInquiryDetails
    setInquiryDetails(inquiryData);

    const offerMessage = `Hey, I have reviewed your requirements and would like to propose the following offer:
    LineBreakLineBreak<span style="font-weight: bold;">Offer Details:</span>LineBreak
    • $${deliveryPickupCheckbox
      ? parseInt(calculateEstimatedPricing() + 400 + (!isNaN(additionalCost) && additionalCost > 0 ? parseInt(additionalCost) : 0) - (!isNaN(ownerDiscount) && ownerDiscount > 0 ? parseInt(ownerDiscount) : 0))
      : calculateEstimatedPricing() + (!isNaN(additionalCost) && additionalCost > 0 ? parseInt(additionalCost) : 0) - (!isNaN(ownerDiscount) && ownerDiscount > 0 ? parseInt(ownerDiscount) : 0)} PriceLineBreak
    • Time offered, ${inquiryData.offerData.offeredDate} at
     ${inquiryData.offerData.offeredTime} LineBreak
    • Duration, ${offeredDuration}LineBreak
    • Pickup Location, ${pickupAddress}LineBreak
    • Dropoff Location, ${dropOffAddress}`;
  

    const database = getDatabase();
  
    const chatRef = ref(database, `chats/${selectedChat.chatId}`);

    try {
      const chatSnapshot = await get(chatRef);
  
      const messageData = {
        senderId: currentUser.uid,
        receiverId: receiverId,
        message: offerMessage,
        inquiryId: inquiryDetails.id,
        listingId: inquiryDetails.listingId,
        offer: 1,
        timestamp: serverTimestamp(),
      };

        await update(chatRef, {
          messages: [...chatSnapshot.val().messages, messageData],
        });
        
        const emailData = await getUserByID(receiverId);
        const currentUserData = await getCurrentUserDetails();
        // const ownerEmail = emailData.email;
        // const subjectEmail = 'New Offer Recieved';
        const ownerName = currentUserData.firstName + ' ' + currentUserData.lastName;

        // const emailBody = await NewOfferRecievedEmailBody(ownerName, listingTitle);
        // sendEmail (ownerEmail, subjectEmail, emailBody);

        const smsBody = `Hello ${emailData.firstName} ${emailData.lastName}, your inquiry for ${listingTitle} by ${ownerName} has received a new offer.\n\n Please login to your dashboard to view and accept the offer: https://www.bbrbassboatrentals.com`
        const response = sendSMS(emailData.phone, smsBody);
        console.log(response)
  
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };
  
  const sendAcceptOfferMessage = async () => {

    const inquiryData = await getInquiryDataById(inquiryDetails.id);
    // Now you can set the inquiry details in the modal state or directly in the form fields
    // For example, assuming you have state variables like setInquiryDetails
    setInquiryDetails(inquiryData);

    const offerMessage = `Hey, I have accepted the offer, quoted <span style="font-weight: bold;">$${inquiryData.offerData.offerPrice}</span>.
    LineBreakLooking forward to see you on ${inquiryData.prefferedDate} at ${inquiryData.prefferedTime}.`;
  

    const database = getDatabase();
  
    const chatRef = ref(database, `chats/${selectedChat.chatId}`);

    try {
      const chatSnapshot = await get(chatRef);
  
      const messageData = {
        senderId: currentUser.uid,
        receiverId: receiverId,
        message: offerMessage,
        inquiryId: inquiryDetails.id,
        listingId: inquiryDetails.listingId,
        offerAccepted: 1,
        timestamp: serverTimestamp(),
      };

      const listingDetails = await getListingByID(inquiryDetails.listingId);

      // const subjectEmail = 'Offer Accepted';
      // const emailBody = await OfferAccepetedRenterEmailBody(listingDetails.listingTitle);
      // sendEmail (otherUserDetails.email, subjectEmail, emailBody);

      const renterSmsBody = `Your offer for ${listingTitle} has been acccepted.\n\n To finalize your booking, kindly proceed with the payment.`
      const renterSmsResponse = sendSMS(otherUserDetails.phone, renterSmsBody);
      console.log(renterSmsResponse)

      const ownerDetails = await getUserByID(receiverId);
      // const subjectOwnerEmail = 'Offer Accepted';
      const renterName = otherUserDetails.firstName + '' + otherUserDetails.lastName;
      // const emailOwnerBody = await OfferAccepetedOwnerEmailBody(listingDetails.listingTitle, renterName);
      // sendEmail (ownerDetails.email, subjectOwnerEmail, emailOwnerBody);

      const ownerSmsBody = `Your offer for ${listingTitle} has been acccepted by ${renterName}.`
      const ownerSmsResponse = sendSMS(ownerDetails.phone, ownerSmsBody);
      console.log(ownerSmsResponse)
      
      await updateInquiryStatus(inquiryDetails.id, 'Accepted');

        await update(chatRef, {
          messages: [...chatSnapshot.val().messages, messageData],
        });
  
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleSendOffer = async () => {
    setLoading(true);
      try {
        const price = offerPrice;
        const offerDate = offeredDate;
        const offerTime = offeredTime;
        const offerHours = offeredHours;

        const sameDayHoursData = sameDayHours;
        const sameDayMinutesData = sameDayMinutes;
        const overnightData = overnight;
        const offeredDurationData = offeredDuration;

        const arivedDateTimeData = arivingDateTime;

        const pLocation = dbPickupAddress;
        const dLocation = dbDropoffAddress;
        

        const newOffer = {  
          offerPrice: deliveryPickupCheckbox
          ? parseInt(calculateEstimatedPricing() + 400 + (!isNaN(additionalCost) && additionalCost > 0 ? parseInt(additionalCost) : 0)- (!isNaN(ownerDiscount) && ownerDiscount > 0 ? parseInt(ownerDiscount) : 0)) 
          : calculateEstimatedPricing() + (!isNaN(additionalCost) && additionalCost > 0 ? parseInt(additionalCost) : 0) - (!isNaN(ownerDiscount) && ownerDiscount > 0 ? parseInt(ownerDiscount) : 0),
          offeredDate: offerDate,
          offeredTime: offerTime,
          pickupAddress: pLocation,
          additionalCost: additionalCost,
          discount: ownerDiscount,
          trailerCheckbox: trailerCheckbox ? true : false,
          captainCheckbox: captainCheckbox ? true : false,
          deliveryPickup: deliveryPickupCheckbox ? true : false,
          dropOffAddress: dLocation,
          offeredHours: offerHours,
          overnight: overnightData,
          duration: offeredDurationData,
          arivingDateTime: arivingDateTime,
        };
        await addOfferToInquiry(currentMessageInquiryId, newOffer);

  
        // Find the message in the selected chat that corresponds to the inquiry ID
        const updatedMessages = selectedChat.messages.map((message) => {
          if (message.inquiryId === currentMessageInquiryId) {
            // Update the offerSent property to 1
            return { ...message, offerStatus: 'Sent' };
          }
          return message;
        });
  
        // Update the chat with the modified messages
        const updatedChat = { ...selectedChat, messages: updatedMessages };
  
        // Update the chat in the Firebase Realtime Database
        const chatRef = ref(database, `chats/${selectedChat.chatId}`);
        await update(chatRef, { messages: updatedChat.messages });
  
        // Optionally, you can update the state to reflect the changes
        setSelectedChat(updatedChat);

        await sendOfferMessage();
        setLoading(false);
        closeCreateOfferModal();
      } catch (error) {
        console.error('Error sending offer:', error);
        setLoading(false);
      }
  };

  const handleEditOffer = async () => {
    setLoading(true);
      try {
        const price = offerPrice;
        const offerDate = offeredDate;
        const offerTime = offeredTime;
        const offerHours = offeredHours;

        const sameDayHoursData = sameDayHours;
        const sameDayMinutesData = sameDayMinutes;
        const overnightData = overnight;
        const offeredDurationData = offeredDuration;

        const arivedDateTimeData = arivingDateTime;

        const pLocation = dbPickupAddress;
        const dLocation = dbDropoffAddress;
        

        const newOffer = {  
          offerPrice: deliveryPickupCheckbox
          ? parseInt(calculateEstimatedPricing() + 400 + (!isNaN(additionalCost) && additionalCost > 0 ? parseInt(additionalCost) : 0)- (!isNaN(ownerDiscount) && ownerDiscount > 0 ? parseInt(ownerDiscount) : 0)) 
          : calculateEstimatedPricing() + (!isNaN(additionalCost) && additionalCost > 0 ? parseInt(additionalCost) : 0) - (!isNaN(ownerDiscount) && ownerDiscount > 0 ? parseInt(ownerDiscount) : 0),
          offeredDate: offerDate,
          offeredTime: offerTime,
          pickupAddress: pLocation,
          additionalCost: additionalCost,
          discount: ownerDiscount,
          trailerCheckbox: trailerCheckbox ? true : false,
          captainCheckbox: captainCheckbox ? true : false,
          deliveryPickup: deliveryPickupCheckbox ? true : false,
          dropOffAddress: dLocation,
          offeredHours: offerHours,
          overnight: overnightData,
          duration: offeredDurationData,
          arivingDateTime: arivingDateTime,
        };

        await editOfferToInquiry(currentMessageInquiryId, newOffer);
        setLoading(false);
        closeEditOfferModal();
      } catch (error) {
        console.error('Error editing offer:', error);
        setLoading(false);
      }
  };

  async function handleAcceptOffer() {
    setLoading(true);
    const database = getDatabase();
    const bookingRef = ref(database, `bookings`);
    
    const paymentRef = ref(database, `payments`)
  
    try {

      const userRef = ref(database, `users/${currentUser.uid}`);

      const previousBookings = await get(userRef);
      let activeBookings;
      if(previousBookings.activeBookings) {
        activeBookings = previousBookings+1;
        await update(userRef, {activeBookings});
      } else {
          activeBookings = 1;
          await update(userRef, {activeBookings});
      }

      // Use push to generate a new unique key
      const newBookingRef = push(bookingRef);
  
      // Get the key (ID) of the newly created node
      const newBookingId = newBookingRef.key;

      const newPaymentRef = push(paymentRef);
  
      // Get the key (ID) of the newly created node
      const newPaymentId = newPaymentRef.key;
  
      await set(newBookingRef, {
        renter: inquiryDetails.renter,
        owner: inquiryDetails.owner,
        inquiryId: currentMessageInquiryId,
        listingTitle: listingTitle,
        priceOffered: parseFloat(parseFloat(inquiryDetails.offerData.offerPrice) + parseFloat(additionalCost > 0 ? parseInt(additionalCost): 0) - parseFloat(ownerDiscount > 0 ? parseInt(additionalCost): 0)),
        listingId: inquiryDetails.listingId,
        paymentId: newPaymentId,
        totalWaivers: inquiryDetails.inquiryData.total,
        waiversFilled: {
          adult: 0,
          minor: 0},
        timestamp: serverTimestamp(),
        status: 'pending',
      });

      const queryParams = new URLSearchParams({
        bookingId: newBookingId,
      });
       
      // Find the message in the selected chat that corresponds to the inquiry ID
      const updatedMessages = selectedChat.messages.map((message) => {
        if (message.inquiryId === currentMessageInquiryId) {
          // Update the offerSent property to 1
          return { ...message, offerStatus: 'Accepted' };
        }
        return message;
      });

      // Update the chat with the modified messages
      const updatedChat = { ...selectedChat, messages: updatedMessages };

      // Update the chat in the Firebase Realtime Database
      const chatRef = ref(database, `chats/${selectedChat.chatId}`);
      await update(chatRef, { messages: updatedChat.messages });

      // Optionally, you can update the state to reflect the changes
      setSelectedChat(updatedChat);

      await sendAcceptOfferMessage();
      setLoading(false);
      closeCreateOfferModal();
      Navigate(`/dashboard/booking-payment?${queryParams.toString()}`);
      return newBookingId;
    } catch (error) {
      console.error('Error adding booking:', error);
      throw error; // Re-throw the error to handle it elsewhere if needed
      setLoading(true);
    }
  }

  return (
    <>
      <div class="row">
        <div class="col">
          <div class="page-description">
            <h1>Inbox</h1>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="mailbox-container">
            <div class="card">
              <div class="container-fluid">
                <div class="row">
                  <div class="mailbox-list col-xl-3">
                    <ul className="mailbox-list">
                    {chats
                      .filter((chat) => currentUser.uid === chat.senderId || currentUser.uid === chat.receiverId)
                      .sort((a, b) => getTimestamp(b) - getTimestamp(a))
                      .map((chat) => {
                        const otherParticipantId =
                          currentUser.uid === chat.senderId ? chat.receiverId : chat.senderId;
                        const otherParticipantDetails = otherParticipants[otherParticipantId];

                        const lastMessageTimestamp = getTimestamp(chat);
                        const lastMessageDate = new Date(lastMessageTimestamp);

                        // Format the date as per your requirement
                        const formattedDate = `${lastMessageDate.toLocaleDateString()} ${lastMessageDate.toLocaleTimeString()}`;
                        return (
                          <li
                            key={chat.chatId}
                            className={`mailbox-list-item ${otherParticipantId === receiverId ? 'active' : ''
                              }`}
                            onClick={() => {
                              selectContact(otherParticipantId);
                            }}
                          >
                            <a href="#">
                              <div className="mailbox-list-item-content">
                                <span className="mailbox-list-item-title">
                                {otherParticipantDetails ? (
                                  <div>
                                    {`${otherParticipantDetails.firstName} ${otherParticipantDetails.lastName} `}
                                    <span className='user-status'>{otherParticipantDetails.status !== 'Active' ? 'Not Verified' : 'Verified'}</span>
                                  </div>
                                ) : (
                                  'Loading...'
                                )}
                                </span>
                                <p className="mailbox-list-item-text">
                                  Last Message: {formattedDate} {/* Display the timestamp */}
                                </p>
                                <p className="mailbox-list-item-text">{/* Add your text content or other details here */}</p>
                              </div>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div class="mailbox-open-content col-xl-9">
                    {selectedChat && (
                      <>
                        <span class="mailbox-open-date">{new Date(getTimestamp(selectedChat)).toLocaleDateString()} {new Date(getTimestamp(selectedChat)).toLocaleTimeString()}</span>
                        <h5 class="mailbox-open-title">
                          {currentMessageListingDetails && currentMessageListingDetails.listingTitle != null
                            ? currentMessageListingDetails.listingTitle
                            : ''}
                        </h5>
                        <div class="mailbox-open-author"  >
                          <div class="mailbox-open-author-info">
                            <span class="mailbox-open-author-info-email d-block">{otherParticipant.email}</span>
                            <span class="mailbox-open-author-info-to">
                            {currentMessageListingDetails && currentMessageListingDetails.listingTitle != null
                            ? currentMessageListingDetails.listingTitle
                            : ''}
                            </span>
                          </div>
                          {/* <div class="mailbox-open-actions">
                            <Button variant="primary" className="m-1" onClick={openCreateOfferModal}>
                              Create Offer
                            </Button>
                            <a href="#" class="btn btn-danger m-1">
                              Cancel
                            </a>
                          </div> */}
                        </div>
                        <div class="mailbox-open-content-email">
                          <div className="row chat">
                            <div className="col">
                              <div className="row">
                                <div className="col d-flex flex-column">
                                {selectedChat.messages.map((message, index) => (
                                  <div
                                    ref={scroll}
                                    key={index}
                                    className={`d-flex flex-column chatBubble ${
                                      message.senderId === currentUser.uid ? 'sent' : 'received'
                                    } ${
                                      (message.offerStatus === 'Pending' ||
                                      message.offerStatus === 'pending' ||
                                      message.offerStatus === 'sent' ||
                                      message.offerStatus === 'Sent' ||
                                      message.offer === 1) ? 'flex-column' : ''
                                    }`}
                                    style={{
                                      alignItems: message.receiverId === currentUser.uid ? 'flex-start' : 'flex-end',
                                    }}
                                  >
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: message.message ? message.message.replace(/LineBreak/g, '<br />') : '',
                                      }}
                                    />
                                    {message.inquiryId != null && message.receiverId === currentUser.uid && otherUserDetails.role === "Boat Owner" ? (
                                      message.offer === 1 ? (
                                        <>
                                          <Button
                                          variant="primary offerButton"
                                          className="m-1"
                                          onClick={() => openViewOfferModal(message)}
                                        >
                                          {loading ? <ThreeDots
                                          visible={true}
                                          height="22"
                                          width="22"
                                          color="#fff"
                                          radius="20"
                                          ariaLabel="three-dots-loading"
                                          wrapperStyle={{}}
                                          wrapperClass=""
                                          /> : 'View Offer'}
                                        </Button></>
                                      ) : (
                                        message.offerStatus === 'Pending' || message.offerStatus === 'pending' ? (
                                          <Button
                                            variant="primary"
                                            className="m-1 offerButton"
                                            onClick={() => openCreateOfferModal(message.inquiryId)}
                                          >
                                            {loading ? <ThreeDots
                                          visible={true}
                                          height="22"
                                          width="22"
                                          color="#fff"
                                          radius="20"
                                          ariaLabel="three-dots-loading"
                                          wrapperStyle={{}}
                                          wrapperClass=""
                                          /> : 'Create Offer'}
                                          </Button>
                                        ) : message.offerStatus === 'Accepted' && message.offerAccepted !== null ? (
                                          <div className='d-flex' style={{gap: '5px', marginTop: '-8px', fontWeight: '500'}}>
                                            <i style={{color: 'green'}} className="material-icons">check_circle_outline</i> Offer Accepted
                                          </div>
                                        ) : message.offerStatus === 'Sent' && message.offerAccepted !== null ? (
                                          <Button
                                          variant="primary offerButton"
                                          className="m-1"
                                          onClick={() => openEditOfferModal(message)}
                                        >
                                          {loading ? <ThreeDots
                                          visible={true}
                                          height="22"
                                          width="22"
                                          color="#fff"
                                          radius="20"
                                          ariaLabel="three-dots-loading"
                                          wrapperStyle={{}}
                                          wrapperClass=""
                                          /> : 'Edit Offer'}
                                          
                                        </Button>
                                        ) : (
                                          ''
                                        )
                                      )
                                    ) : (message.inquiryId != null && otherUserDetails.role === "Renter") || (otherUserDetails.role === 'Boat Owner' && message.receiverId === currentUser.uid) ? (
                                      message.offer === undefined && message.offerStatus === 'Accepted' ? (
                                        // Your additional condition is met - Display whatever you want here
                                        ''
                                      ) : message.offer === 1 && message.offerStatus !== 'Accepted' ? (
                                        <>
                                          <Button
                                          variant="primary offerButton"
                                          className="m-1"
                                          onClick={() => openViewOfferModal(message)}
                                        >
                                          {loading ? <ThreeDots
                                          visible={true}
                                          height="22"
                                          width="22"
                                          color="#fff"
                                          radius="20"
                                          ariaLabel="three-dots-loading"
                                          wrapperStyle={{}}
                                          wrapperClass=""
                                          /> : 'View Offer'}
                                        </Button></>
                                        
                                      ) : message.offerStatus === 'Accepted' && message.offerAccepted !== null ? (
                                        <div className='d-flex' style={{gap: '5px', marginTop: '-8px', fontWeight: '500'}}>
                                          <i style={{color: 'green'}} className="material-icons">check_circle_outline</i> Offer Accepted
                                        </div>
                                      ) : message.offerStatus === ' ' ? (
                                        <i className="material-icons">check_circle_outline</i>
                                      ) : null
                                    ) : null}
                                  </div>
                                ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row sender pt-2">
                            <div className="col-12 col-md-10">
                              <div class="form-floating">
                                <input
                                  type="text"
                                  placeholder="Send Message..."
                                  className="form-control form-control-solid-bordered"
                                  aria-describedby="..."
                                  id="floatingInput"
                                  value={message}
                                  onChange={(e) => setMessage(e.target.value)}
                                />
                                <label for="floatingInput">Send Message</label>
                              </div>
                            </div>
                            <div className="col-12 col-md-2 d-flex">
                              <button type="button" class="btn send-btn btn-primary" onClick={sendMessage}>
                                <i class="material-icons-outlined">send</i>Send
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <Modal centered show={isCreateOfferModalOpen} onHide={closeCreateOfferModal}>
        <Modal.Header closeButton>
          <Modal.Title>{(otherUserDetails.role === 'Boat Owner' && selectedMessageData.receiverId != currentUser.uid) ? 'Create Offer' : 'Offer'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {otherUserDetails.role === 'Boat Owner' && selectedMessageData.receiverId != currentUser.uid ?
            <Row>
              <Form.Label className="inquiry-label">
              Estimated Platform Price: {calculateEstimatedPricing() !== '' ? `${deliveryPickupCheckbox ? '$'+parseFloat(calculateEstimatedPricing() + 400) : '$'+ calculateEstimatedPricing()}` : ''}
              </Form.Label>
              {/* <Form.Label className="inquiry-label mt-3">
              Price adjustments: {calculateEstimatedPricing() !== '' ? 
              `${deliveryPickupCheckbox ? '$'+ 0.95 *parseFloat(calculateEstimatedPricing() + 400) 
              : '$'+ 0.95 *calculateEstimatedPricing()}` : ''} 
              - {calculateEstimatedPricing() !== '' ? `${deliveryPickupCheckbox ? '$'+ 1.05 *parseFloat(calculateEstimatedPricing() + 400) : '$'+ 1.05 *calculateEstimatedPricing()}` : ''}
              </Form.Label> */}
              {error && (
                <Alert className='mt-3' severity="error">{error}</Alert>
              )}              
              <Col>
                <Form.Group controlId="priceInput">
                  <Form.Label>Price</Form.Label>
                  {calculateEstimatedPricing() !== '' && (
                    <CurrencyInput
                      id="offerPrice"
                      name="offerPrice"
                      placeholder="Enter Price"
                      defaultValue={deliveryPickupCheckbox ? parseInt(calculateEstimatedPricing() + 400) : calculateEstimatedPricing()}
                      decimalsLimit={2}
                      prefix='$'
                      value={
                        deliveryPickupCheckbox
                          ? parseInt(calculateEstimatedPricing() + 400 + (!isNaN(additionalCost) && additionalCost > 0 ? parseInt(additionalCost) : 0) - (!isNaN(ownerDiscount) && ownerDiscount > 0 ? parseInt(ownerDiscount) : 0))
                          : calculateEstimatedPricing() + (!isNaN(additionalCost) && additionalCost > 0 ? parseInt(additionalCost) : 0) - (!isNaN(ownerDiscount) && ownerDiscount > 0 ? parseInt(ownerDiscount) : 0)
                      }
                      className='form-control'
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
            : ''
            }     
            <Row >
              <Col>
              {otherUserDetails.role === 'Boat Owner' && selectedMessageData.receiverId != currentUser.uid ? <h5 className='mt-4'>Renter Requests for {currentMessageListingDetails?.listingTitle} </h5> : <h5>Your Specifications</h5>}
                <Row> 
                  <Col>
                  {
                      // Display details if inquiryDetails is available
                      inquiryDetails ? (
                        <>
                          {currentMessageListingDetails && currentMessageListingDetails.listingTitle != null
                            ? '• ' +currentMessageListingDetails.listingTitle
                            : ''}
                          {inquiryDetails.inquiryData && (
                            <>
                              <p className='mb-1'>• {inquiryDetails.inquiryData?.prefferedDate}</p>

                              <p className='mb-1'>• {inquiryDetails.inquiryData?.prefferedTime}</p>
                              {inquiryDetails.inquiryData?.overnights > 0 && (
                                <p className='mb-1'>• {inquiryDetails.inquiryData?.overnights} Nights</p>
                              )}
                              {inquiryDetails.inquiryData?.sameDayHours > 0 && (
                                <p className='mb-1'>• {inquiryDetails.inquiryData?.sameDayHours} Hours</p>
                              )}
                              {inquiryDetails.inquiryData?.sameDayMinutes > 0 && (
                                <p className='mb-1'>• {inquiryDetails.inquiryData?.sameDayMinutes} Minutes</p>
                              )}

                              <p className='mb-1'>
                                
                                • {inquiryDetails.inquiryData?.total} Guests (
                                {inquiryDetails.inquiryData?.children > 0 && (
                                <> {inquiryDetails.inquiryData?.children} Children,</>
                                )}
                                {inquiryDetails.inquiryData?.infants > 0 && (
                                <> {inquiryDetails.inquiryData?.infants} Infants,</>
                                )}
                                {inquiryDetails.inquiryData?.seniors > 0 && (
                                <> {inquiryDetails.inquiryData?.seniors} Seniors,</>
                                )}
                                {inquiryDetails.inquiryData?.adults > 0 && (
                                <> {inquiryDetails.inquiryData?.adults} Adults </>
                                )}
                                )
                              </p>
                              {inquiryDetails.inquiryData?.comments != null && (
                                <>
                                <h6 className='mt-4'>Comments:</h6>
                                <p className='mb-1'>• {inquiryDetails.inquiryData?.comments}</p>
                                </>
                              )}

                              {otherUserDetails.role !== 'Boat Owner' || selectedMessageData.receiverId === currentUser.uid && (
                                <>
                                  <h5 className="mt-4">Offer Details</h5>
                                  <p className='mb-1'>• Quote, ${(inquiryDetails.offerData?.offerPrice+(!isNaN(additionalCost) && additionalCost > 0 ? parseInt(additionalCost): 0) - (!isNaN(ownerDiscount) && ownerDiscount > 0 ? parseInt(ownerDiscount): 0))} </p>
                                  <p className='mb-1'>• Time offered, {inquiryDetails.offerData?.offeredDate} at {inquiryDetails.offerData?.offeredTime}</p>
                                  <p className='mb-1'>• Duration, {inquiryDetails.offerData?.duration}</p>
                                  {pickupLocation ? <p className='mb-1'>• Pickup Location, {pickupLocation} </p> : ''}
                                  {dropoffLocation ? <p className='mb-1'>• Dropoff Location, {dropoffLocation}</p> : ''}                                
                                  </>
                              )}
                            </>
                          )}
                        </>
                      ) : null
                    }
                  </Col>
                </Row>
              </Col>
            </Row>

            {otherUserDetails.role === 'Boat Owner' && selectedMessageData.receiverId != currentUser.uid ?
            <div className='mt-4'>
              <h5>Offer Details</h5>
              <div class="settings-integrations-item mb-2">
                <Row>
                  <Col xs={6}>
                    <div class="settings-integrations-item-info">
                      <img src="../../assets/images/icons/sourcetree.png" alt="" />
                      <span>Set Offer</span>
                    </div>
                  </Col>
                  <Col xs={6} className='d-flex justify-content-end'>
                    <div class="settings-integrations-item-switcher">
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input form-control-md"
                          type="checkbox"
                          id="settingsIntegrationFourSwitcher"
                          checked={offerExpiryToggle}
                          onChange={handleToggleChange}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              {offerExpiryToggle && (
                <div>
                  <Row>
                    <Col xs={6}>
                    <Form.Group controlId="offeredDateInput">
                      <Form.Label>Offer Date (Pickup Date):</Form.Label>
                      <Form.Control
                        type="date"
                        onChange={(e) => setOfferedDate(e.target.value)}
                        value={offeredDate}
                      />
                    </Form.Group>

                    </Col>
                    <Col xs={6}>
                      <Form.Group controlId="offeredTimeInput">
                        <Form.Label>Offer Time</Form.Label>
                        <Form.Control type="time" value={offeredTime} onChange={(e) => handleOfferedTime(e.target.value)} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                      <Col xs={6}>
                      <Form.Label className="mt-3 inquiry-label">Pickup Location</Form.Label>
                        <div className='locationDiv'>
                          <PlacesAutocomplete
                            value={pickupAddress}
                            onChange={setPickupAddress}
                            onSelect={handlePSelect}
                          >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                              <div>
                                <div className="input-group">
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Search Places...',
                                      className: 'location-search-input form-control',
                                      name: "location"
                                    })}
                                  />
                                  {/* <div className="input-group-append d-flex">
                                    <button
                                      className="btn btn-outline-dark"
                                      type="button"
                                      onClick={handleCurrentLocation}
                                    >
                                      <i className="fas fa-map-marker-alt"></i>
                                    </button>
                                  </div> */}
                                </div>
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? 'suggestion-item--active'
                                      : 'suggestion-item';
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className: 'location-suggestion form-control'
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </div>
                      </Col>
                      <Col xs={6}>
                      <Form.Label className="mt-3 inquiry-label">Drop Off Location</Form.Label>
                        <div className='locationDiv'>
                          <PlacesAutocomplete
                            value={dropOffAddress}
                            onChange={setDropOffAddress}
                            onSelect={handleDropOffSelect}
                          >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                              <div>
                                <div className="input-group">
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Search Places...',
                                      className: 'location-search-input form-control',
                                      name: "location"
                                    })}
                                  />
                                  {/* <div className="input-group-append d-flex">
                                    <button
                                      className="btn btn-outline-dark"
                                      type="button"
                                      onClick={handleCurrentLocation}
                                    >
                                      <i className="fas fa-map-marker-alt"></i>
                                    </button>
                                  </div> */}
                                </div>
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? 'suggestion-item--active'
                                      : 'suggestion-item';
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className: 'location-suggestion form-control'
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </div>
                      </Col>
                    </Row>
                    <Row className='mt-2'>
                    {((currentMessageListingDetails.boatType !== "Bass Boat" && currentMessageListingDetails.pricing?.hourlyRate) || (currentMessageListingDetails.boatType == 'Bass Boat')) && 
                    <Col xs={6} className='text-center'>
                      <Row>
                        <Col>
                          <Form.Label className="inquiry-label">Hours</Form.Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col className='d-flex' style={{gap: '5px'}}>
                          <Button onClick={() => handleRemove('sameDayHours')} className='d-flex justify-content-center align-items-center' style={{width: '10px'}}>
                            <i className="material-icons add-remove">remove_circle</i>
                          </Button>
                          <Form.Control
                            type="number"
                            name="sameDayHours"
                            className="form-control"
                            value={sameDayHours}
                            onChange={(e) => handleSameDayHours(e.target.value)}
                            required
                          />
                          <Button onClick={() => handleAdd('sameDayHours')} className='d-flex justify-content-center align-items-center' style={{width: '10px'}}>
                            <i className="material-icons add-remove">add_circle</i>
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                      }
                            
                    {/* <Col xs={6} className='text-center'>
                      <Row>
                        <Col>
                          <Form.Label className="inquiry-label">Minutes</Form.Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col className='d-flex' style={{gap: '5px'}}>
                          <Button onClick={() => handleRemove('sameDayMinutes')} className='d-flex justify-content-center align-items-center' style={{width: '10px'}}>
                            <i className="material-icons add-remove">remove_circle</i>
                          </Button>
                          <Form.Control
                            type="number"
                            name="sameDayMinutes"
                            className="form-control"
                            value={sameDayMinutes}
                            required
                          />
                          <Button onClick={() => handleAdd('sameDayMinutes')} className='d-flex justify-content-center align-items-center' style={{width: '10px'}}>
                            <i className="material-icons add-remove">add_circle</i>
                          </Button>
                        </Col>
                      </Row>
                    </Col> */}
                    <Col xs={6} className='text-center'>
                     <Row>
                     {currentMessageListingDetails && (
                        (currentMessageListingDetails.boatType === 'Bass Boat') ||
                        (currentMessageListingDetails.boatType !== 'Bass Boat' && currentMessageListingDetails.pricing && currentMessageListingDetails.pricing.overnightEnabled)
                      ) && (
                        <>
                        <Row>
                          <Col>
                          <Form.Label className="inquiry-label">Overnight</Form.Label>
                          <div className="d-flex justify-content-center align-items-center gap-3">
                            <Form.Select
                              name="overnight"
                              value={overnight}
                              onChange={(e) => handleOvernight(e.target.value)}
                              className="form-select"
                              style={{ width: '90%' }}
                              required
                            >
                              <option value="" default>Select An Option</option>
                              {currentMessageListingDetails.pricing && currentMessageListingDetails.boatType !== "Bass Boat" && currentMessageListingDetails.pricing.dailyOptions && currentMessageListingDetails.pricing.dailyOptions.length > 0 ? (
                                currentMessageListingDetails.pricing.dailyOptions.map(option => (
                                    <option key={option.days} value={option.days}>
                                        {`${option.days} Days (${option.days * 24} hours)`}
                                    </option>
                                ))
                            ) : (
                              currentMessageListingDetails.boatType === "Bass Boat" && (
                                    <>
                                        <option value="1">1 Day (24 hours)</option>
                                        <option value="2">2 Days (48 hours)</option>
                                        <option value="3">3 Days (72 hours)</option>
                                        <option value="4">4 Days (96 hours)</option>
                                        <option value="5">5 Days (120 hours)</option>
                                        <option value="6">6 Days (144 hours)</option>
                                    </>
                                )
                            )}
                            </Form.Select>
                          </div>
                          </Col>
                          </Row>
                        </>
                      )}
                     </Row>
                    </Col>
                    </Row>
                    {currentMessageListingDetails && currentMessageListingDetails.boatType != 'Bass Boat' && currentMessageListingDetails.pricing && currentMessageListingDetails.pricing.hourlyOptions &&
                      <>
                      <Row>
                        <Col xs={12} className='text-center mt-3'>
                          <Form.Label className="inquiry-label">Hourly Packages</Form.Label>
                          <div className="d-flex justify-content-center align-items-center gap-3">
                          <Form.Select
                            name="sameDayHours"
                            id="sameDayHours"
                            value={sameDayHours}
                            onChange={(e) => handleSameDayHours(e.target.value)}
                            className="form-select"
                            required
                          >
                          <option value="" default>Select An Option</option>
                          {currentMessageListingDetails.pricing.hourlyOptions.map(option => (
                              <option key={option.hours} value={option.hours}>
                                {`${option.hours} Hours`}
                              </option>
                            )) }
                          </Form.Select>
                        </div>
                      </Col>
                      </Row>
                      </>
                      }
                    <Row className='mt-3'>
                    <FormGroup className='d-flex'>
                      <h5 className='mt-3'>Additionals:</h5>
                        <div className='d-flex'>
                          <FormControlLabel style={{alignItems: 'flex-start'}} control={<Checkbox checked={deliveryPickupCheckbox}
                    onChange={handleDeliveryPickupCheckboxChange} />} />
                          <div>
                          <p style={{fontWeight: '500', lineHeight: '28px'}}><span style={{fontSize: '12px', fontWeight: '500', borderRadius: '8px', background: '#1a1a1a', color: 'white', marginRight: '5px', padding: '4px 8px'}}>$400</span>Delivery and pickup to 130 miles.</p>            
                          </div>
                          </div>
                          {currentMessageListingDetails && (
                            (currentMessageListingDetails.boatType === 'Bass Boat') ||
                            (currentMessageListingDetails.boatType !== 'Bass Boat' && currentMessageListingDetails.pricing && currentMessageListingDetails.pricing.overnightEnabled)
                          ) && (
                            <>
                              <div className='d-flex mt-1'>
                                <FormControlLabel
                                  style={{ alignItems: 'flex-start' }}
                                  control={
                                    <Checkbox
                                      disabled={(overnight && overnight > 0) || sameDayHours >= 24} // Modified the disabled condition
                                      checked={trailerCheckbox}
                                      onChange={handleTrailerCheckbox}
                                    />
                                  }
                                />
                                <div>
                                  <p style={{ fontWeight: '500', lineHeight: '28px' }}>
                                    <span style={{ fontSize: '12px', fontWeight: '500', borderRadius: '8px', background: '#1a1a1a', color: 'white', marginRight: '5px', padding: '4px 8px' }}>
                                      $100
                                    </span>
                                    Trailer Package.
                                    {overnight > 0 && (
                                      <span style={{ color: 'red' }}> *</span>
                                    )}
                                  </p>            
                                </div>
                              </div>
                              </>
                            )}
                            
                          <div className='d-flex mt-1'>
                          <FormControlLabel
                            style={{ alignItems: 'flex-start' }}
                            control={
                              <Checkbox
                                checked={captainCheckbox}
                                onChange={handleCaptainCheckbox}
                              />
                              }
                              />
                              <div>
                              <p style={{ fontWeight: '500', lineHeight: '28px' }}>
                                <span style={{ fontSize: '12px', fontWeight: '500', borderRadius: '8px', background: '#1a1a1a', color: 'white', marginRight: '5px', padding: '4px 8px' }}>
                                  Quote
                                </span>
                                Provide Captain.
                              </p>            
                        </div>
                      </div>
                      <Row>
                      <Col xs={6}>
                        <Form.Group controlId="priceInput">
                          <Form.Label>Additional Cost</Form.Label>
                          <CurrencyInput
                            id="additionalCost"
                            name="additionalCost"
                            placeholder="Enter Price"
                            defaultValue={0}
                            decimalsLimit={2}
                            prefix='$'
                            value={additionalCost}
                            onValueChange={(value) => handleAdditionalCost(value)}
                            className='form-control'
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="priceInput">
                          <Form.Label>Discount</Form.Label>
                          <CurrencyInput
                            id="ownerDiscount"
                            name="ownerDiscount"
                            placeholder="Enter discount"
                            defaultValue={0}
                            decimalsLimit={2}
                            prefix='$'
                            value={ownerDiscount}
                            onValueChange={(value) => handleOwnerDiscount(value)}
                            className='form-control'
                          />
                        </Form.Group>
                        {error && <Alert className='mt-3' severity='error'>{error}</Alert>}
                      </Col>
                      </Row>
                  </FormGroup>
                    <Col xs={12} className='text-center mt-3'>
                      <Row>
                        <Col>
                          <Form.Label className="inquiry-label">Duration: {offeredDuration}</Form.Label>
                          <Form.Control
                            type="hidden"
                            name="offeredduration"
                            className="form-control"
                            value={offeredDuration}
                            required
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} className='text-center mt-3'>
                      <Row>
                        <Col>
                          <Form.Label className="inquiry-label">Ariving Date & Time: {arivingDateTime}</Form.Label>
                          <Form.Control
                            type="hidden"
                            name="arivingdatetime"
                            value={arivingDateTime}
                            className="form-control"
                            required
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                 
                </div>
              )}
            </div>
            : ''}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeCreateOfferModal}>
            {otherUserDetails.role === 'Boat Owner' ? 'Close' : 'Reject Offer'}
          </Button>
          {otherUserDetails.role === 'Boat Owner' && selectedMessageData.receiverId != currentUser.uid ? <Button variant="primary" disabled={error} onClick={handleSendOffer}>
          {loading ? <ThreeDots
            visible={true}
            height="22"
            width="22"
            color="#fff"
            radius="20"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
            /> : 'Create Offer'} </Button> : '' }

          {otherUserDetails.role === 'Renter' || selectedMessageData.receiverId === currentUser.uid ? (
          <Button variant="primary" disabled={buttonDisabled} onClick={handleAcceptOffer}>
            {loading ? <ThreeDots
              visible={true}
              height="22"
              width="22"
              color="#fff"
              radius="20"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
              /> : 'Accept Offer'}
          </Button>
          ) : null}
        </Modal.Footer>
      </Modal>

      <Modal centered show={isEditOfferModalOpen} onHide={closeEditOfferModal}>
        <Modal.Header closeButton>
         Edit Offer
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Form.Label className="inquiry-label">
              Estimated Platform Price: {calculateEstimatedPricing() !== '' ? `${deliveryPickupCheckbox ? '$'+parseFloat(calculateEstimatedPricing() + 400) : '$'+ calculateEstimatedPricing()}` : ''}
              </Form.Label>
              {/* <Form.Label className="inquiry-label mt-3">
              Price adjustments: {calculateEstimatedPricing() !== '' ? 
              `${deliveryPickupCheckbox ? '$'+ 0.95 *parseFloat(calculateEstimatedPricing() + 400) 
              : '$'+ 0.95 *calculateEstimatedPricing()}` : ''} 
              - {calculateEstimatedPricing() !== '' ? `${deliveryPickupCheckbox ? '$'+ 1.05 *parseFloat(calculateEstimatedPricing() + 400) : '$'+ 1.05 *calculateEstimatedPricing()}` : ''}
              </Form.Label> */}
              {error && (
                <Alert className='mt-3' severity="error">{error}</Alert>
              )}              
              <Col>
                <Form.Group controlId="priceInput">
                  <Form.Label>Price</Form.Label>
                  {calculateEstimatedPricing() !== '' && (
                    <CurrencyInput
                      id="offerPrice"
                      name="offerPrice"
                      placeholder="Enter Price"
                      defaultValue={deliveryPickupCheckbox ? parseInt(calculateEstimatedPricing() + 400) : calculateEstimatedPricing()}
                      decimalsLimit={2}
                      prefix='$'
                      value={
                        deliveryPickupCheckbox
                          ? parseInt(calculateEstimatedPricing() + 400 + (!isNaN(additionalCost) && additionalCost > 0 ? parseInt(additionalCost) : 0) - (!isNaN(ownerDiscount) && ownerDiscount > 0 ? parseInt(ownerDiscount) : 0))
                          : calculateEstimatedPricing() + (!isNaN(additionalCost) && additionalCost > 0 ? parseInt(additionalCost) : 0) - (!isNaN(ownerDiscount) && ownerDiscount > 0 ? parseInt(ownerDiscount) : 0)
                      }
                      className='form-control'
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>  
            <Row >
              <Col>
                <Row> 
                  <Col>
                  {
                      // Display details if inquiryDetails is available
                      inquiryDetails ? (
                        <>
                          {currentMessageListingDetails && currentMessageListingDetails.listingTitle != null
                            ? '• ' +currentMessageListingDetails.listingTitle
                            : ''}
                          {inquiryDetails.inquiryData && (
                            <>
                              <p className='mb-1'>• {inquiryDetails.inquiryData?.prefferedDate}</p>

                              <p className='mb-1'>• {inquiryDetails.inquiryData?.prefferedTime}</p>
                              {inquiryDetails.inquiryData?.overnights > 0 && (
                                <p className='mb-1'>• {inquiryDetails.inquiryData?.overnights} Nights</p>
                              )}
                              {inquiryDetails.inquiryData?.sameDayHours > 0 && (
                                <p className='mb-1'>• {inquiryDetails.inquiryData?.sameDayHours} Hours</p>
                              )}
                              {inquiryDetails.inquiryData?.sameDayMinutes > 0 && (
                                <p className='mb-1'>• {inquiryDetails.inquiryData?.sameDayMinutes} Minutes</p>
                              )}

                              <p className='mb-1'>
                                
                                • {inquiryDetails.inquiryData?.total} Guests (
                                {inquiryDetails.inquiryData?.children > 0 && (
                                <> {inquiryDetails.inquiryData?.children} Children,</>
                                )}
                                {inquiryDetails.inquiryData?.infants > 0 && (
                                <> {inquiryDetails.inquiryData?.infants} Infants,</>
                                )}
                                {inquiryDetails.inquiryData?.seniors > 0 && (
                                <> {inquiryDetails.inquiryData?.seniors} Seniors,</>
                                )}
                                {inquiryDetails.inquiryData?.adults > 0 && (
                                <> {inquiryDetails.inquiryData?.adults} Adults </>
                                )}
                                )
                              </p>
                              {inquiryDetails.inquiryData?.comments != null && (
                                <>
                                <h6 className='mt-4'>Comments:</h6>
                                <p className='mb-1'>• {inquiryDetails.inquiryData?.comments}</p>
                                </>
                              )}

                              {otherUserDetails.role !== 'Boat Owner' || selectedMessageData.receiverId === currentUser.uid && (
                                <>
                                  <h5 className="mt-4">Offer Details</h5>
                                  <p className='mb-1'>• Quote, ${(inquiryDetails?.offerData?.offerPrice+(!isNaN(additionalCost) && additionalCost > 0 ? parseInt(additionalCost): 0) - (!isNaN(ownerDiscount) && ownerDiscount > 0 ? parseInt(ownerDiscount): 0))} </p>
                                  <p className='mb-1'>• Time offered, {inquiryDetails.offerData?.offeredDate} at {inquiryDetails.offerData?.offeredTime}</p>
                                  <p className='mb-1'>• Duration, {inquiryDetails.offerData?.duration}</p>
                                  {pickupLocation ? <p className='mb-1'>• Pickup Location, {pickupLocation} </p> : ''}
                                  {dropoffLocation ? <p className='mb-1'>• Dropoff Location, {dropoffLocation}</p> : ''}                                
                                  </>
                              )}
                            </>
                          )}
                        </>
                      ) : null
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row >
              <Col>
              <h5 className='mt-4'>Renter Requests for {currentMessageListingDetails?.listingTitle} </h5>
              </Col>
            </Row>
            <div className='mt-4'>
              <h5>Offer Details</h5>
              <div class="settings-integrations-item mb-2">
                <Row>
                  <Col xs={6}>
                    <div class="settings-integrations-item-info">
                      <img src="../../assets/images/icons/sourcetree.png" alt="" />
                      <span>Set Offer</span>
                    </div>
                  </Col>
                  <Col xs={6} className='d-flex justify-content-end'>
                    <div class="settings-integrations-item-switcher">
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input form-control-md"
                          type="checkbox"
                          id="settingsIntegrationFourSwitcher"
                          checked={offerExpiryToggle}
                          onChange={handleToggleChange}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              {offerExpiryToggle && (
                <div>
                  <Row>
                    <Col xs={6}>
                    <Form.Group controlId="offeredDateInput">
                      <Form.Label>Offer Date (Pickup Date):</Form.Label>
                      <Form.Control
                        type="date"
                        onChange={(e) => setOfferedDate(e.target.value)}
                        value={offeredDate}
                      />
                    </Form.Group>

                    </Col>
                    <Col xs={6}>
                      <Form.Group controlId="offeredTimeInput">
                        <Form.Label>Offer Time</Form.Label>
                        <Form.Control type="time" value={offeredTime} onChange={(e) => handleOfferedTime(e.target.value)} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                      <Col xs={6}>
                      <Form.Label className="mt-3 inquiry-label">Pickup Location</Form.Label>
                        <div className='locationDiv'>
                          <PlacesAutocomplete
                            value={pickupAddress}
                            onChange={setPickupAddress}
                            onSelect={handlePSelect}
                          >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                              <div>
                                <div className="input-group">
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Search Places...',
                                      className: 'location-search-input form-control',
                                      name: "location"
                                    })}
                                  />
                                  {/* <div className="input-group-append d-flex">
                                    <button
                                      className="btn btn-outline-dark"
                                      type="button"
                                      onClick={handleCurrentLocation}
                                    >
                                      <i className="fas fa-map-marker-alt"></i>
                                    </button>
                                  </div> */}
                                </div>
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? 'suggestion-item--active'
                                      : 'suggestion-item';
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className: 'location-suggestion form-control'
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </div>
                      </Col>
                      <Col xs={6}>
                      <Form.Label className="mt-3 inquiry-label">Drop Off Location</Form.Label>
                        <div className='locationDiv'>
                          <PlacesAutocomplete
                            value={dropOffAddress}
                            onChange={setDropOffAddress}
                            onSelect={handleDropOffSelect}
                          >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                              <div>
                                <div className="input-group">
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Search Places...',
                                      className: 'location-search-input form-control',
                                      name: "location"
                                    })}
                                  />
                                  {/* <div className="input-group-append d-flex">
                                    <button
                                      className="btn btn-outline-dark"
                                      type="button"
                                      onClick={handleCurrentLocation}
                                    >
                                      <i className="fas fa-map-marker-alt"></i>
                                    </button>
                                  </div> */}
                                </div>
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? 'suggestion-item--active'
                                      : 'suggestion-item';
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className: 'location-suggestion form-control'
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </div>
                      </Col>
                    </Row>
                    <Row className='mt-2'>
                    {((currentMessageListingDetails.boatType !== "Bass Boat" && currentMessageListingDetails.pricing?.hourlyRate) || (currentMessageListingDetails.boatType == 'Bass Boat')) && 
                    <Col xs={6} className='text-center'>
                      <Row>
                        <Col>
                          <Form.Label className="inquiry-label">Hours</Form.Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col className='d-flex' style={{gap: '5px'}}>
                          <Button onClick={() => handleRemove('sameDayHours')} className='d-flex justify-content-center align-items-center' style={{width: '10px'}}>
                            <i className="material-icons add-remove">remove_circle</i>
                          </Button>
                          <Form.Control
                            type="number"
                            name="sameDayHours"
                            className="form-control"
                            value={sameDayHours}
                            onChange={(e) => handleSameDayHours(e.target.value)}
                            required
                          />
                          <Button onClick={() => handleAdd('sameDayHours')} className='d-flex justify-content-center align-items-center' style={{width: '10px'}}>
                            <i className="material-icons add-remove">add_circle</i>
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                      }
                            
                    {/* <Col xs={6} className='text-center'>
                      <Row>
                        <Col>
                          <Form.Label className="inquiry-label">Minutes</Form.Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col className='d-flex' style={{gap: '5px'}}>
                          <Button onClick={() => handleRemove('sameDayMinutes')} className='d-flex justify-content-center align-items-center' style={{width: '10px'}}>
                            <i className="material-icons add-remove">remove_circle</i>
                          </Button>
                          <Form.Control
                            type="number"
                            name="sameDayMinutes"
                            className="form-control"
                            value={sameDayMinutes}
                            required
                          />
                          <Button onClick={() => handleAdd('sameDayMinutes')} className='d-flex justify-content-center align-items-center' style={{width: '10px'}}>
                            <i className="material-icons add-remove">add_circle</i>
                          </Button>
                        </Col>
                      </Row>
                    </Col> */}
                    <Col xs={6} className='text-center'>
                     <Row>
                     {currentMessageListingDetails && (
                        (currentMessageListingDetails.boatType === 'Bass Boat') ||
                        (currentMessageListingDetails.boatType !== 'Bass Boat' && currentMessageListingDetails.pricing && currentMessageListingDetails.pricing.overnightEnabled)
                      ) && (
                        <>
                        <Row>
                          <Col>
                          <Form.Label className="inquiry-label">Overnight</Form.Label>
                          <div className="d-flex justify-content-center align-items-center gap-3">
                            <Form.Select
                              name="overnight"
                              value={overnight}
                              onChange={(e) => handleOvernight(e.target.value)}
                              className="form-select"
                              style={{ width: '90%' }}
                              required
                            >
                              <option value="" default>Select An Option</option>
                              {currentMessageListingDetails.pricing && currentMessageListingDetails.boatType !== "Bass Boat" && currentMessageListingDetails.pricing.dailyOptions && currentMessageListingDetails.pricing.dailyOptions.length > 0 ? (
                                currentMessageListingDetails.pricing.dailyOptions.map(option => (
                                    <option key={option.days} value={option.days}>
                                        {`${option.days} Days (${option.days * 24} hours)`}
                                    </option>
                                ))
                            ) : (
                              currentMessageListingDetails.boatType === "Bass Boat" && (
                                    <>
                                        <option value="1">1 Day (24 hours)</option>
                                        <option value="2">2 Days (48 hours)</option>
                                        <option value="3">3 Days (72 hours)</option>
                                        <option value="4">4 Days (96 hours)</option>
                                        <option value="5">5 Days (120 hours)</option>
                                        <option value="6">6 Days (144 hours)</option>
                                    </>
                                )
                            )}
                            </Form.Select>
                          </div>
                          </Col>
                          </Row>
                        </>
                      )}
                     </Row>
                    </Col>
                    </Row>
                    {currentMessageListingDetails && currentMessageListingDetails.boatType != 'Bass Boat' && currentMessageListingDetails.pricing && currentMessageListingDetails.pricing.hourlyOptions &&
                      <>
                      <Row>
                        <Col xs={12} className='text-center mt-3'>
                          <Form.Label className="inquiry-label">Hourly Packages</Form.Label>
                          <div className="d-flex justify-content-center align-items-center gap-3">
                          <Form.Select
                            name="sameDayHours"
                            id="sameDayHours"
                            value={sameDayHours}
                            onChange={(e) => handleSameDayHours(e.target.value)}
                            className="form-select"
                            required
                          >
                          <option value="" default>Select An Option</option>
                          {currentMessageListingDetails.pricing.hourlyOptions.map(option => (
                              <option key={option.hours} value={option.hours}>
                                {`${option.hours} Hours`}
                              </option>
                            )) }
                          </Form.Select>
                        </div>
                      </Col>
                      </Row>
                      </>
                      }
                    <Row className='mt-3'>
                    <FormGroup className='d-flex'>
                      <h5 className='mt-3'>Additionals:</h5>
                        <div className='d-flex'>
                          <FormControlLabel style={{alignItems: 'flex-start'}} control={<Checkbox checked={deliveryPickupCheckbox}
                    onChange={handleDeliveryPickupCheckboxChange} />} />
                          <div>
                          <p style={{fontWeight: '500', lineHeight: '28px'}}><span style={{fontSize: '12px', fontWeight: '500', borderRadius: '8px', background: '#1a1a1a', color: 'white', marginRight: '5px', padding: '4px 8px'}}>$400</span>delivery and pickup to 130 miles.</p>            
                          </div>
                          </div>
                          {currentMessageListingDetails && (
                            (currentMessageListingDetails.boatType === 'Bass Boat') ||
                            (currentMessageListingDetails.boatType !== 'Bass Boat' && currentMessageListingDetails.pricing && currentMessageListingDetails.pricing.overnightEnabled)
                          ) && (
                            <>
                              <div className='d-flex mt-1'>
                                <FormControlLabel
                                  style={{ alignItems: 'flex-start' }}
                                  control={
                                    <Checkbox
                                      disabled={(overnight && overnight > 0) || sameDayHours >= 24} // Modified the disabled condition
                                      checked={trailerCheckbox}
                                      onChange={handleTrailerCheckbox}
                                    />
                                  }
                                />
                                <div>
                                  <p style={{ fontWeight: '500', lineHeight: '28px' }}>
                                    <span style={{ fontSize: '12px', fontWeight: '500', borderRadius: '8px', background: '#1a1a1a', color: 'white', marginRight: '5px', padding: '4px 8px' }}>
                                      $100
                                    </span>
                                    Trailer Package.
                                    {overnight > 0 && (
                                      <span style={{ color: 'red' }}> *</span>
                                    )}
                                  </p>            
                                </div>
                              </div>
                              </>
                            )}
                            
                          <div className='d-flex mt-1'>
                          <FormControlLabel
                            style={{ alignItems: 'flex-start' }}
                            control={
                              <Checkbox
                                checked={captainCheckbox}
                                onChange={handleCaptainCheckbox}
                              />
                              }
                              />
                              <div>
                              <p style={{ fontWeight: '500', lineHeight: '28px' }}>
                                <span style={{ fontSize: '12px', fontWeight: '500', borderRadius: '8px', background: '#1a1a1a', color: 'white', marginRight: '5px', padding: '4px 8px' }}>
                                  Quote
                                </span>
                                Provide Captain.
                              </p>            
                        </div>
                      </div>
                      <Row>
                      <Col xs={6}>
                        <Form.Group controlId="priceInput">
                          <Form.Label>Additional Cost</Form.Label>
                          <CurrencyInput
                            id="additionalCost"
                            name="additionalCost"
                            placeholder="Enter Price"
                            defaultValue={0}
                            decimalsLimit={2}
                            prefix='$'
                            value={additionalCost}
                            onValueChange={(value) => handleAdditionalCost(value)}
                            className='form-control'
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="priceInput">
                          <Form.Label>Discount</Form.Label>
                          <CurrencyInput
                            id="ownerDiscount"
                            name="ownerDiscount"
                            placeholder="Enter discount"
                            defaultValue={0}
                            decimalsLimit={2}
                            prefix='$'
                            value={ownerDiscount}
                            onValueChange={(value) => handleOwnerDiscount(value)}
                            className='form-control'
                          />
                        </Form.Group>
                        {error && <Alert className='mt-3' severity='error'>{error}</Alert>}
                      </Col>
                      </Row>
                  </FormGroup>
                    <Col xs={12} className='text-center mt-3'>
                      <Row>
                        <Col>
                          <Form.Label className="inquiry-label">Duration: {offeredDuration}</Form.Label>
                          <Form.Control
                            type="hidden"
                            name="offeredduration"
                            className="form-control"
                            value={offeredDuration}
                            required
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} className='text-center mt-3'>
                      <Row>
                        <Col>
                          <Form.Label className="inquiry-label">Ariving Date & Time: {arivingDateTime}</Form.Label>
                          <Form.Control
                            type="hidden"
                            name="arivingdatetime"
                            value={arivingDateTime}
                            className="form-control"
                            required
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                 
                </div>
              )}
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeEditOfferModal}>
            Close
          </Button>
          <Button variant="primary" disabled={error} onClick={handleEditOffer}>
          {loading ? <ThreeDots
            visible={true}
            height="22"
            width="22"
            color="#fff"
            radius="20"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
            /> : 'Update Offer'} 
            </Button> 
        </Modal.Footer>
      </Modal>
    </>
  );
}
