import { faAnchor, faShip } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'


export default function FloatingButtons() {
  return (
    <>
        <div className='floating-btn'>
            <Button href="/listings" className='btn btn-light cs-bg-orange text-cs-dark p-3 m-2'><FontAwesomeIcon icon={faShip} className='icon' /> I'm here to rent</Button>
            <Button href="/dashboard" className='btn btn-light cs-bg-orange text-cs-dark p-3 m-2'><FontAwesomeIcon icon={faAnchor} className='icon'/> I'm here to list my boat</Button>
        </div>
    </>
  )
}
