import React, { useState } from 'react'
import { addWaiver } from '../utils';

export default function AddWaiver() {

    const [waiverName, setWaiverName] = useState('');
    const [waiverDescription, setWaiverDescription] = useState('');


    const resetForm = () => {
        setWaiverName('');
        setWaiverDescription('');
        setQuestions([{ question: '', type: 'text', required: '', options: [''] }]);
      };


    const [questions, setQuestions] = useState([
        { question: '', type: 'text', required: '', options: [''] },
      ]);
    
      const handleAddQuestion = () => {
        setQuestions([...questions, { question: '', type: 'text', required: '', options: [''] }]);
      };
    
      const handleRemoveQuestion = (index) => {
        const updatedQuestions = [...questions];
        updatedQuestions.splice(index, 1);
        setQuestions(updatedQuestions);
      };
    
      const handleQuestionChange = (index, key, value) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index][key] = value;
        setQuestions(updatedQuestions);
      };
    
      const handleOptionChange = (questionIndex, optionIndex, value) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].options[optionIndex] = value;
        setQuestions(updatedQuestions);
      };
    
      const handleAddOption = (questionIndex) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].options.push('');
        setQuestions(updatedQuestions);
      };
    
      const handleRemoveOption = (questionIndex, optionIndex) => {
        const updatedQuestions = [...questions];
        updatedQuestions[questionIndex].options.splice(optionIndex, 1);
        setQuestions(updatedQuestions);
      };
    
      const handleSubmit = () => {
        // Filter out questions with empty options for type 'text'
        const filteredQuestions = questions.map((question) => {
          if (question.type === 'text') {
            // If type is 'text', omit the 'options' field
            const { options, ...textQuestion } = question;
            return textQuestion;
          }
          return question;
        });
      
        // Print the modified JSON in the console
        console.log(JSON.stringify(filteredQuestions, null, 2));
        console.log(JSON.stringify(waiverName, null, 2));
        // Pass the modified questions to the addWaiver function
        addWaiver(waiverName, waiverDescription, filteredQuestions);


        resetForm();

      };


  return (
    <>
    <div className="row">
        <div className="col">
            <div className="page-description">
                <h1>Add Waivers</h1>
            </div>
        </div>
    </div>

    <div className="row">
        <div className="col">
          <div className="card calendar-container">
            <div className="card-body">
              <form>
                <label htmlFor='waiverName'>Waiver Name</label>
                <input
                      type="text"
                      className="form-control"
                      name="waiverName"
                      id='waiverName'
                      value={waiverName}  // Add this line
                      onChange={(e) => setWaiverName(e.target.value)}
                />
                <label htmlFor='description'>Description</label>
                <textarea
                      type="text"
                      className="form-control"
                      name="waiverDescription"
                      id='waiverDescription'
                      value={waiverDescription}  // Add this line
                      onChange={(e) => setWaiverDescription(e.target.value)}
                />
                {questions.map((question, index) => (
                  <div key={index} className="mb-3">
                    <div className='row mt-3'>
                        <div className='col-6'>
                            <label htmlFor={`question${index + 1}`}>Question No {index + 1}:</label>
                            <input
                              type="text"
                              className="form-control"
                              id={`question${index + 1}`}
                              value={question.question}
                              onChange={(e) => handleQuestionChange(index, 'question', e.target.value)}
                            />
                        </div>
                        <div className='col-3'>
                            <label htmlFor={`type${index + 1}`}>Type:</label>
                            <select
                              className="form-control"
                              id={`type${index + 1}`}
                              value={question.type}
                              onChange={(e) => handleQuestionChange(index, 'type', e.target.value)}
                            >
                              <option value="text">Text</option>
                              <option value="options">Options</option>
                              <option value="images">Images</option>
                              <option value="signature">Signature</option>
                              <option value="date">Date</option>
                              <option value="country">Country</option>
                            </select>
                        </div>
                        <div className='col-3'>
                            <label htmlFor={`type${index + 1}`}>Required?</label>
                            <select
                              className="form-control"
                              id={`type${index + 1}`}
                              value={question.required}
                              onChange={(e) => handleQuestionChange(index, 'required', e.target.value)}
                            >
                              <option value="" selected>Select Option</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                        </div>
                    </div>
                    
                    {question.type === 'options' && (
                      <>
                        <div className='row'>
                            <div className='col'>
                                <label>Options:</label>
                                <div className='row'>
                                {question.options.map((option, optionIndex) => (
                                  <div key={optionIndex} className='col'>
                                    <div className="input-group mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={option}
                                        onChange={(e) => handleOptionChange(index, optionIndex, e.target.value)}
                                      />
                                      <button
                                        type="button"
                                        className="btn btn-outline-danger"
                                        onClick={() => handleRemoveOption(index, optionIndex)}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                ))}

                                </div>
                            </div>
                        </div>
                        
                        <button
                          type="button"
                          className="btn btn-outline-success mt-2"
                          style={{marginRight: '8px'}}
                          onClick={() => handleAddOption(index)}
                        >
                          Add Option
                        </button>
                      </>
                    )}

                    <button
                      type="button"
                      className="btn btn-outline-danger mt-2"
                      onClick={() => handleRemoveQuestion(index)}
                    >
                      Remove Question
                    </button>
                  </div>
                ))}

                <button type="button" className="btn btn-outline-success m-1" onClick={handleAddQuestion}>
                  Add Question
                </button>

                <button type="button" className="btn btn-primary  m-1" onClick={handleSubmit}>
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
    </div>
    </>
  )
}
