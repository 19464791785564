import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { UpdateWaiversFilled, WaiverSignedEmailBody, adultWaiverEmailBody, checkAndUpdateStatus, getBookingByID, getCurrentUserDetails, getInquiryDataById, getUserByID, getWaiverDataByName, getWaiverDescriptionByName, sendEmail, sendSMS, updateWaiversFilled } from '../utils';
import { get, getDatabase, push, ref, serverTimestamp, set, update } from 'firebase/database';
import SignatureCanvas from 'react-signature-canvas';
import { ThreeDots } from 'react-loader-spinner'

export default function TripWaivers() {
  const { dynamicId } = useParams();
  const navigate = useNavigate();
  const [adultFormResponses, setAdultFormResponses] = useState([]);
  const [adultWaiverQuestions, setAdultWaiverQuestions] = useState([]);
  const [userData, setUserData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [renterData, setRenterData] = useState([]);
  const [ownerData, setOwnerData] = useState([]);
  const [ownerEmail, setOwnerEmail] = useState([]);
  const [renterEmail, setRenterEmail] = useState([]);
  const [inquiryDetails, setInquiryDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const signatureRef = React.useRef();

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed
      
      setIsMobile(isMobile);
      
    };

    // Initial setup
    handleResize();

    // Attach event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };
  
  const [signatureUrl,  setSignatureFile] = useState('');
  const handleSaveSignature = async () => {
    if (signatureRef.current.isEmpty()) {
      console.error('Signature is empty');
      return;
    }
  
    const signatureImage = signatureRef.current.getTrimmedCanvas().toDataURL('image/png');
    const signatureFile = dataURLtoFile(signatureImage, 'signature.png');
    await handleImageUpload('Signature', [signatureFile]);
  };

  const handleImageUpload = async (question, files) => {
    const formData = new FormData();
  
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
  
    try {
      const response = await fetch('https://www.bbrbassboatrentals.com/upload-images', {
        method: 'POST',
        body: formData,
      });
  
      if (response.ok) {
        const data = await response.json();
        const filenames = data.file_names;

        if(question == 'Signature') {
          setSignatureFile('https://www.bbrbassboatrentals.com/images/'+filenames);
          console.log('https://www.bbrbassboatrentals.com/images/'+filenames)
        }
        const existingResponseIndex = adultFormResponses.findIndex((response) => response.Question === question);
  
        if (existingResponseIndex !== -1) {
          const updatedResponses = [...adultFormResponses];
          updatedResponses[existingResponseIndex].Answer = question === 'Signature' ? 'https://www.bbrbassboatrentals.com/images/'+filenames : filenames;
          setAdultFormResponses(updatedResponses);
          console.log('updatedResponses');
          console.log(updatedResponses);
        } else {
          setAdultFormResponses([...adultFormResponses, { Question: question, Answer: question === 'Signature' ? 'https://www.bbrbassboatrentals.com/images/'+filenames : filenames, type: 'signature' }]);
          console.log('adultFormResponses');
          console.log(question);
          console.log(adultFormResponses);
        }
      } else {
        console.error('Error uploading images:', response.statusText);
      }
    } catch (error) {
      console.error('Error uploading images:', error);
    }
  };

  const handleAdultInputChange = (question, answer) => {
    if (question === 'date' || question === 'country') {
      // Handle date and country inputs
      const updatedResponses = [...adultFormResponses];
      const existingResponseIndex = updatedResponses.findIndex((response) => response.Question === question);
  
      if (existingResponseIndex !== -1) {
        updatedResponses[existingResponseIndex].Answer = answer;
      } else {
        updatedResponses.push({ Question: question, Answer: answer });
      }
  
      setAdultFormResponses(updatedResponses);
    } else {
      const existingResponseIndex = adultFormResponses.findIndex((response) => response.Question === question);
  
      if (existingResponseIndex !== -1) {
        const updatedResponses = [...adultFormResponses];
        updatedResponses[existingResponseIndex].Answer = answer;
        setAdultFormResponses(updatedResponses);
      } else {
        setAdultFormResponses([...adultFormResponses, { Question: question, Answer: answer }]);
      }
    }
    console.log('adultFormResponses');
    console.log(adultFormResponses);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    var newPushKey = '';
    setLoading(true);
    try {
      await handleSaveSignature();
      // Define the path in the Firebase database
      const updatedFormResponses = await new Promise((resolve) => {
        setAdultFormResponses((prevResponses) => {
          const responses = [...prevResponses];
          resolve(responses);
          return responses;
        });
      });
      
      const database = getDatabase();
      const adultTripDataRef = ref(database, `bookings/${dynamicId}/tripData/waivers/Adult Waiver`);
        const adultsQuestionsArray = updatedFormResponses.map((response) => {
        let answer;
        if (response.type === 'signature') {
          answer = response.Answer;
        } else if (response.type === 'images') {
          // For images, use an array of URLs
          answer = response.Answer.map((image) => ({ url: image }));
        } else {
          // For other types, use the answer directly
          answer = response.Answer;
        }
  
        return {
          question: adultWaiverQuestions.find((q) => q.id === response.Question)?.question || response.Question,
          answer,
        };
      });

      const newPushRef = await push(adultTripDataRef, { waiverName: 'Adult Waiver', questions: adultsQuestionsArray });
      newPushKey = newPushRef.key;

      const bookingRef = ref(database, `bookings/${dynamicId}`);
      await updateWaiversFilled(dynamicId, 'adult');
      
      const tripData = ref(database, `bookings/${dynamicId}/tripData`);
      await update(tripData, { startTimestamp: serverTimestamp() });

      
      const subject = 'Congratulations! Adult Passenger Waiver Signed';
      const WaiverName = 'Adult Passenger Waiver';
      const url = 'https://bbrbassboatrentals.com/my-waiver/'+newPushKey;
      let name = updatedFormResponses
      .filter(response => [0, 1, 2].includes(response.Question)) // Filter responses for questions 0, 1, and 2
      .map(response => response.Answer.trim()) // Trim each response before mapping
      .join(' '); // Join the trimmed answers into a single string with a space between them  
      
      const emailBody = await WaiverSignedEmailBody(WaiverName, name, url, newPushKey);
      sendEmail('waivers@bbrbassboatrentals.com', subject, emailBody);
      sendEmail(ownerEmail, subject, emailBody);
      sendEmail(renterEmail, subject, emailBody);

      const smsBody = `${WaiverName} has been submitted, If you need a signed copy of this waiver for your records you may download it by clicking on this link ${url}. Or use your submission ID ${newPushKey}\n\nhttps://www.bbrbassboatrentals.com`
      const renterSmsResponse = sendSMS(renterData.phone, smsBody);
      const ownerSmsResponse = sendSMS(ownerData.phone, smsBody);

      setLoading(false);
      navigate(`/waivers/thank-you`);
    } catch (error) {
      setLoading(false);
      console.error('Error submitting form:', error);
    }
  };

  const [adultWaiverDescription, setAdultWaiverDescription] = useState([]);
  useEffect(() => {
    const fetchWaiverData = async () => {
      try {
        const bookingData = await getBookingByID(dynamicId);
        if (!bookingData) return; // No need to proceed if bookingData is null

        const ownerData = await getUserByID(bookingData.owner);
        const renterData = await getUserByID(bookingData.renter);
        setRenterData(renterData);
        setRenterEmail(renterData.email);
        setOwnerEmail(ownerData.email);
        setOwnerData(ownerData);
        setBookingData(bookingData);

        const inquiryData = await getInquiryDataById(bookingData.inquiryId);
        setInquiryDetails(inquiryData);


          const waiverName = 'Adult Waiver';
          const questions = await getWaiverDataByName(waiverName);
          const questionsWithId = questions.map((question, index) => ({ ...question, id: index }));
          setAdultWaiverQuestions(questionsWithId);
          console.log('questionsWithId');
          console.log(questionsWithId);
          const description = await getWaiverDescriptionByName(waiverName);
          setAdultWaiverDescription(description);
        
          const currentUserDetails = await getCurrentUserDetails();
          setUserData(currentUserDetails);
      } catch (error) {
        console.error('Error fetching waiver data:', error);
      }
    };
  
    fetchWaiverData();
  }, [dynamicId]);
  
  useEffect(() => {
    const todayDateElement = document.querySelector('.today-date');
    if (todayDateElement) {
      const todaysDate = new Date().toLocaleDateString(); // Get today's date in a readable format
      todayDateElement.textContent = `Today's Date: ${todaysDate}`;
    }
  }, [adultWaiverDescription]);
  
  return (
    <>
      <section className='p-4 d-flex align-items-center'>
      <Container className='mt-4 mb-4'>
        <Row className='card p-5 d-flex flex-row align-items-center'>
          <Col xs={4}>
            <img className='img-fluid' src="/assets/backend/images/logo.webp"></img>
          </Col>
          <Col xs={8}>
            <h3 className='' style={{color: '#27313F', fontWeight: '800', textAlign: 'center'}}>BBR BASS BOAT RENTALS, LLC</h3>
            <p style={{ color: '#27313F', fontWeight: '400', fontSize: '19px', textAlign: 'center' }}>ADULT PASSENGER WAIVER</p>
          </Col>
        </Row>
        <Row>
          <Col className='m-0 p-0 mt-4'>
            
              <form onSubmit={handleSubmit}>
                  {adultWaiverQuestions && adultWaiverQuestions !==null ?
                  <div className="card p-4">
                      <div className="row">
                      <div className="col">
                      <div className="page-description">
                          <h1>Adult Passenger Waiver</h1>
                          <div className='waiverDescrption mt-4' dangerouslySetInnerHTML={{ __html: adultWaiverDescription }} />
                      </div>
                      </div>
                  </div></div> : ''}
                  <div className="card p-4 mt-5">
                  {adultWaiverQuestions && adultWaiverQuestions !== null ? 
                    adultWaiverQuestions.map((questionData) => (
                      <div key={`${questionData.question}-${questionData.id}`}>
                        {questionData.question === 'First Name' ? (
                            <>
                              <h3 className="mt-2">Adult Passenger Name</h3>
                              <label className="form-label form-label mt-2">{questionData.question}:</label>
                            </>
                          ) : questionData.question === "Adult Passenger Date Of Birth" ? (
                            <>
                              <h3 className="mt-5">Adult Passenger Date Of Birth</h3>
                            </>
                          ) : questionData.question === "Adult Passenger Signature" ? (
                            <>
                              <h3 className="mt-5 mb-3">Adult Passenger Signature</h3>
                            </>
                          ) : questionData.question === "Email" ? (
                            <>
                              <h3 className="mt-5 mb-3">Email Address</h3>
                              <label className="form-label form-label mt-2">{questionData.question}:</label>
                            </>
                          ) : questionData.question === "Name of the captain operating the boat" ? (
                            <>
                              <h3 className="mt-5 mb-3">Name of the captain operating the boat:</h3>
                            </>
                          ) : (
                            <label className="form-label form-label mt-2">{questionData.question}:</label>
                          )}
                        {questionData.type === 'text' ? (
                          <input
                            required={questionData?.required && questionData?.required == 'true' ? true : false}
                            type="text"
                            className="form-control form-control"
                            value={adultFormResponses.find((response) => response.Question === questionData.id)?.Answer || ''}
                            onChange={(e) => handleAdultInputChange(questionData.id, e.target.value)} 
                          />
                        ) : questionData.type === 'date' ? (
                          <input
                            required={questionData?.required && questionData?.required == 'true' ? true : false}
                            type="date"
                            className="form-control form-control"
                            value={adultFormResponses.find((response) => response.Question === 'date')?.Answer || ''}
                            onChange={(e) => handleAdultInputChange('date', e.target.value)} 
                          />
                        ) : questionData.type === 'country' ? (
                          <select
                            required={questionData?.required && questionData?.required == 'true' ? true : false}
                            className="form-control"
                            name="settingsInputCountry"
                            value={adultFormResponses.find((response) => response.Question === 'country')?.Answer || ''}
                            onChange={(e) => handleAdultInputChange('country', e.target.value)} 
                          >
                            <option>United States</option>
                            {/* Add other country options if needed */}
                          </select>
                        ) : questionData.type === 'signature' ? (
                          <div>
                            <SignatureCanvas
                              ref={signatureRef}
                              required={questionData?.required && questionData?.required == 'true' ? true : false}
                              canvasProps={{ width: isMobile ? 250 : 500, height: 200, className: 'sigCanvas' }}
                            />
                            <button type="button" onClick={() => signatureRef.current.clear()} className="btn btn-primary">
                              Clear
                            </button>
                          </div>
                        ) : (
                          questionData.options.map((option) => (
                            <div className="btn btn-light m-1 mb-4 form-check" key={option}>
                              <label htmlFor={option} className="form-check-label">
                                {option}
                              </label>
                              <input
                                required={questionData?.required && questionData?.required == 'true' ? true : false}
                                type="radio"
                                name={questionData.id}
                                value={option}
                                id={option}
                                className="form-check-input"
                                checked={
                                  adultFormResponses.find((response) => response.Question === questionData.id)?.Answer ===
                                  option
                                }
                                onChange={() => handleAdultInputChange(questionData.id, option)}
                              />
                            </div>
                          ))
                        )}
                      </div>
                    ))
                    
                  : ''}
                <button style={{width: '20%'}} type="submit" className="btn mt-3 btn-primary mt-3">
                {loading ? <ThreeDots
                  visible={true}
                  height="22"
                  width="22"
                  color="#fff"
                  radius="20"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  /> : 'Agree To This Document'}
                </button>
                </div>
              </form>
          </Col>
        </Row>
      </Container>
      </section>
    </>
  );
}
