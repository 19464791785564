import React, { useState } from 'react'
import { sendEmail, welcomeEmailBody } from '../utils'

export default function Email() {

    const [to, setTo] = useState('');
    const [subject, setSubject] = useState('');
    const [emailBody, setEmailBody] = useState('');
  
    const handleSubmit = async (e) => {
        e.preventDefault();
        const emailBodyNew = await welcomeEmailBody('Ethan Collins');
        const emailSent = await sendEmail(to, subject, emailBodyNew);
      if (emailSent) {
        console.log('Email sent successfully!');
      } else {
        console.error('Failed to send email.');
      }
    };
  
    return (
    <>
    <form onSubmit={handleSubmit}>
      <label>
        To:
        <input type="email" value={to} onChange={(e) => setTo(e.target.value)} />
      </label>
      <br />
      <label>
        Subject:
        <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
      </label>
      <br />
      <label>
        Email Body:
        <textarea value={emailBody} onChange={(e) => setEmailBody(e.target.value)} />
      </label>
      <br />
      <button type="submit">Send Email</button>
    </form>
    </>
  )
}
