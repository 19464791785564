import React from 'react'
import { getAllUsersDetails, getAllUsersMeta } from '../utils';
import { useState, useEffect } from 'react';
import DataTable from '../UsersDataTable';

export default function PendingUsers() {

  const[allUsers, setAllUsers] = useState([]);
  const[usersMeta, setUsersMeta] = useState([]);


  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const allUsers = await getAllUsersDetails();
        // Convert the object into an array of values
        setAllUsers(allUsers);
      } catch (error) {
        console.error('Error fetching Questions:', error);
      }
    };

    fetchUsers();
  }, []);


    const columns = [
        {
          Header: 'BBR ID',
          accessor: 'bbrId',
        },
        {
          Header: 'Full Name',
          accessor: 'name',
        },
        {
          Header: 'Phone',
          accessor: 'phone',
        },
        {
          Header: 'Email',
          accessor: 'email',
        },
        // Add more columns as needed
    ];

    const linkUsersAndUsersMeta = () => {
      const linkedData = allUsers.map((user, index) => {
    
        if ( user.role === "Renter") {
          
          if(user.status == 'Pending'){
            const age = user.age;
            const location = user.location;
            const fullName = `${user.firstName} ${user.lastName}`;
      
            return { ...user, age, location, name: fullName };
          }
          
        }
    
        return null;
      }).filter(Boolean); // Remove null values from the array
    
      return linkedData;
    };
  
    const data = linkUsersAndUsersMeta();

    console.log('Users Data');
    console.log(data);

  return (
    <>
    <div class="row">
        <div class="col">
            <div class="page-description">
                <h1>Pending Users</h1>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <div class="card calendar-container">
                <div class="card-body">
                    <DataTable  columns={columns} data={data}/>
                </div>
            </div>
        </div>
    </div>
    
    </>
  )
}
