import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faShip, faAnchor } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../contexts/AuthContext'
import { getCurrentUserDetails } from './utils'


export default function UserType() {

  const [userDetails, setUserDetails] = useState([]);
  const auth = useAuth();
  
  useEffect(() => {
    const fetchUserDetails = async () => {
        try {
          const userData = await getCurrentUserDetails();
          setUserDetails(userData);
        } catch (error) {
          console.error('Error fetching User Deta:', error);
        }
    };
    fetchUserDetails();
  }, []); 
  
  return (
      <>
        <section className='cs-bg-dark py-4 cs-section userTypeSection'>
          <Container>
            <Row>
              <Col md={12} style={{zIndex: '99'}} className='text-white d-flex flex-column text-center'>
                <div>
                    <Button href="/listings" className='btn btn-light cs-bg-orange p-3 m-2'><FontAwesomeIcon icon={faShip} className='icon' />I'm here to rent</Button>
                    {!auth.currentUser && <>
                      <Button href="/dashboard/add-listing" className='btn btn-light  cs-bg-orange p-3 m-2'><FontAwesomeIcon icon={faAnchor} className='icon'/> I'm here to list my boat</Button>
                    </>}
                    {auth.currentUser &&
                     <>
                      {userDetails && userDetails.role != "Renter" && <>
                        <Button href="/dashboard/add-listing" className='btn btn-light  cs-bg-orange p-3 m-2'><FontAwesomeIcon icon={faAnchor} className='icon'/> I'm here to list my boat</Button>
                      </>}
                      <Button href="/dashboard" className='btn btn-light  cs-bg-orange p-3 m-2'><FontAwesomeIcon icon={faAnchor} className='icon'/>Go to dashboard</Button>
                     </>
                    }
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    )
  }