import React from 'react';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import { Row, Col } from 'react-bootstrap';

const CreateListingDataTable = ({ columns, data, pageSize }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        setGlobalFilter,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
      } = useTable(
        {
          columns,
          data,
          initialState: { pageIndex: 0, pageSize: pageSize || 10 }, // Set the initial page index to 0 and page size to 20
        },
        useGlobalFilter,
        usePagination
      );
  const { pageIndex, globalFilter } = state;

  return (
    <div>
      <div>
        
        <div class="form-floating mb-3">
            <input
              type="text"
              value={globalFilter || ''}
              onChange={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search..."
              className="form-control form-control-solid-bordered cs-input-p"
              aria-describedby="..."
              id="floatingInput"
            />
            <label for="floatingInput">Search</label>
        </div>
      </div>
      <table {...getTableProps()} style={{ borderSpacing: '0', width: '100%' }} className='table responsive-table'>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} style={{ borderBottom: '1px solid black' }}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr style={{cursor: 'pointer'}} {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} style={{ padding: '10px', borderBottom: '1px solid black' }}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div>
        <Row className='mt-2'>
            <Col xs={12} className='d-flex width-data-table page-no align-items-center'>
                <span className='m-2'>
                  Page{' '}
                  <strong>
                    {pageIndex + 1} of {page.length}
                  </strong>{' '}
                </span>
            </Col>
            <Col xs={12} className='d-flex width-data-table justify-content-end'>
                <button
                  className={`btn-cs-p btn ${canPreviousPage ? 'btn-primary' : 'btn-light'} m-1`}
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}>
                  Previous Page
                </button>
                <button 
                  className={`btn-cs-p btn ${canNextPage ? 'btn-primary' : 'btn-light'} m-1`}
                  onClick={() => nextPage()}
                  disabled={!canNextPage}>
                  Next Page
                </button>
            </Col>
        </Row>
        
        
      </div>
    </div>
  );
};

export default CreateListingDataTable;
