import { Row, Col, Container } from 'react-bootstrap';
import FloatingButtons from './FloatingButtons';
import { useEffect } from 'react';
export default function FAQ() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  const faqData = [
    {
      question: 'Is there a fuel charge?',
      answer: `For all overnight rentals, please replace the fuel with the grade of gas specified by the boat owner. If you forget to replace the gas, the boat owner will have the option to hold the cost of replacement from your deposit. If you are renting the boat locally with valet drop off and collection at the lake, for up to an 8 hour rental, without the trailer package, there is no need to replace the fuel. However, if it looks like you're going to run out of gas while on the water, use common sense and fill up with the grade of gas specified by the boat owner.`,
    },
    {
      question: `Who is allowed to drive the boat?`,
      answer: `The renter of the boat (AKA the captain) may drive the boat. A BBR employee or trained operator may drive the boat with the permission of the boat owner; nobody else is allowed to drive the boat. It is the responsibility of the captain to enforce this rule. If you cannot enforce this rule, you WILL lose a 10lb bass on your first and last cast.`,
    },
    {
      question: `What if more than one captain would like to drive the boat?`,
      answer: `Each captain will need to do the following:
  1. Complete BBR renter verification waiver.
  2. Complete a Captains waiver equipment checklist.
  3. Indicate the names of all captains on the Captains waiver equipment checklist.`,
    },
    {
      question: `Do I need to sign a liability waiver?`,
      answer: `The Captain and all passengers need to sign an electronic waiver. There is a Captains waiver equipment checklist for the renter of the boat. Adult passengers need to complete an adult passenger waiver. Minors under the age of 18 need to have a parent or legal parental guardian complete a minor passenger waiver.`,
    },
    {
      question: `When should I sign my waiver?`,
      answer: `The digital Captains waiver equipment checklist needs to be completed by the captain upon pick up of the boat during the boat inspection. Upon acceptance of the equipment, the captain will sign the digital waiver; this triggers a time stamp that will start the rental period and place a deposit hold on the renter's credit card.`,
    },
    {
      question: `When do I receive the keys to the boat?`,
      answer: `The Captain will receive the keys when the boat owner or their representative confirms all waivers have been signed.`,
    },
    {
      question: `What information do I need to have ready before I sign the captains waiver?`,
      answer: `The Captains waiver requires that you know the name and address of all passengers.`,
    },
    {
      question: `What if I want to add a passenger during the rental period?`,
      answer: `The captain needs to notify the boat owner through the messaging system in the rental portal at www.bbrbassboatrentals.com. The added passenger needs to complete a passenger waiver online and list the name of the boat captain on their passenger waiver. Following this procedure could help avoid any unexpected liability for the captain and/or the renter of the boat.`,
    },
    {
      question: `Do the passengers that are minors (under 18 years of age) need to sign a liability waiver?`,
      answer: `The parent or legal guardian of a minor (under 18 years of age) needs to sign an electronic waiver on behalf of their minor before the rental period begins.`,
    },
    {
      question: `Is there a weather-related cancellation policy?`,
      answer: `Bass fishing is an outdoor activity; weather is a factor that adds to the challenge and fun in participation. A boat owner is not obligated to cancel a reservation due to bad weather unless there is a named storm at the pick-up location. Cancellation of the booking is at the sole discretion of the boat owner. See cancellation policy.`,
    },
    {
      question: `What is your cancellation policy?`,
      answer: `If cancellation occurs 2 weeks prior to your reservation, there will be a full refund. Within 2 weeks and up to 48 hours of the booking, we will keep a $200 booking fee from your refund amount. Cancellations within 48 hours prior to your reservation will be charged the full amount unless you re-schedule to a date within 90 days of the original reservation. No shows for any reservation will be charged the full amount.
  If the tournament is canceled prior to the reservation start "by the tournament director," the boat owner will cancel and refund your booking at no cost.
  If the tournament is canceled on the day of the tournament "by the tournament director," the boat owner will prorate your refund based on the daily rate. For example, if you return the boat 1 day early, the boat owner could refund the cost of that additional day rental. All refunded fees are guidelines and are negotiable between boat owner and renter. It is recommended that both parties be fair and considerate to maintain a good relationship for all future rental opportunities. BBR is not responsible for settling refund disputes between boat owner and renter.`,
    },
    {
      question: `Are reservations required?`,
      answer: `Reservations are our business model! If you are in a tournament and need a tournament bass boat urgently due to a mechanical failure of your own vessel, our registered boat owners and operators will do everything possible to get you back on the water if they have a boat available. We recommend if you are considering making a reservation, NOW is the time to create a login and get verified as an experienced bass boat captain. Once you are in the system, you will have immediate access to all of our listed boats.`,
    },
    {
      question: `How old do I need to be to make a bass boat rental reservation?`,
      answer: `21 years old`,
    },
    {
      question: `How does BBR verify I can operate the vessel?`,
      answer: `The vessel operator needs to complete the renter verification section of our booking system. A BBR representative will verify the answers you provide in your application. If you have experience operating a bass boat, you will likely have no problem getting approved.`,
    },
    {
      question: `Do I need a boaters license?`,
      answer: `In Texas, per TPWD regulation, if you were born on or after September 1, 1993, and are 18 or over, you must have completed a Boaters education course to legally rent a boat in Texas. However, BBR requires that you are at least 21 years old. Choose a boaters license course below (hint: boatUS.org is free, allow a few hours to complete)
      <a href='https://www.boaterexam.com/'>https://www.boaterexam.com/</a>
      <a href='https://www.boat-ed.com/'>https://www.boat-ed.com/</a>
      <a href='https://www.boatsmartexam.com/us/texas/'>https://www.boatsmartexam.com/us/texas/</a>
      <a href='https://www.boatus.org/'>https://www.boatus.org/</a>
      <a href='https://www.ilearntoboat.com/texas/'>https://www.ilearntoboat.com/texas/</a>
      For all other boat rentals in states other than Texas, you must adhere to that state regulations for boater education and boat rental, while renting a boat within that state. Phew... that was wordy!`,
    },
    {
      question: `Is a deposit required to rent?`,
      answer: `Yes, all rentals require an $800 security deposit, which will be placed as a hold on the renter's credit card. The hold will be released by the boat owner after 7 days, in which time the boat owner is required to complete their equipment return checklist. If any damage is identified by the boat owner upon return of the equipment, the boat owner will notify the renter of the cost of repair and may choose to use funds from the deposit amount to cover the cost of the repairs. The boat owner is required to use the BBR messaging system to communicate the damages via receipts and pictures with the renter. If the renter's credit card does not have sufficient funds to accommodate the hold amount at the start of the rental, the owner may accept a cash deposit.`,
    },
    {
      question: `Do I need insurance?`,
      answer: `That is the renter's decision. For the duration of the rental period, or until the equipment is returned to the boat owner, whichever is longer, the Renter accepts responsibility for any damages caused to the rented equipment described in the rental agreement. The boat owner must have a liability insurance policy of at least $300,000 liability, suitable for most bass fishing tournament circuits, that must be available to share with the renter at the renter's request. BBR does not have an insurance policy to provide coverage for any renters, their passengers, or for boat owners. If the renter intends to fish a tournament, it is the renter's responsibility to check with the tournament director to ensure the insurance policy is acceptable to the tournament director, prior to renting the boat. The boat owner should provide proof of insurance directly to the tournament director upon request, or share the policy details with the boat renter so they may present a copy to the tournament director. If you are uncertain of your own personal insurance coverage while renting a boat, we recommend you call your boat owner, home owners, or home rental policy provider if you have one. If you do not have any insurance, the renter will be responsible. If trailering the boat, the renter is responsible for checking with their vehicle insurance policy provider to ensure they have adequate coverage.`,
    },
    {
      question: `Does BBR sell supplemental insurance?`,
      answer: `No, not yet, but we are working on this to mitigate some of the risk to both boat owner and renter.`,
    },
    {
      question: `What if I find a problem with the boat I have rented?`,
      answer: `The boat owner is obligated to do everything possible to ensure the boat, motor, prop, and trailer are fully functional and performing to the highest standard possible. If you notice a problem upon receiving the equipment, it is the renter's responsibility to advise the boat owner of any issues before signing off and accepting the equipment. It is the renter's responsibility to ensure they check everything fully before accepting the equipment using the Captains waiver equipment checklist. A walkdown with the boat owner or their representative is required, and the Captains waiver equipment checklist needs to be completed and signed by the renter before the keys can be exchanged. If you have any questions at the time of the exchange, please feel free to ask questions to make yourself feel comfortable with the transaction at hand. We are here to provide a service; it is our goal to have systems in place to ensure you are 100% satisfied with this service. If there is a problem with the equipment that renders it un-useable to the renter and is not serviceable at the time of the start of the rental, the boat owner is obligated to endeavor to repair or replace the equipment. If the repair or replacement is not to the satisfaction of the renter, the rental transaction will be canceled by the boat owner, including a full and immediate refund.`,
    },
    {
      question: `What if I damage the boat I have rented?`,
      answer: `The renter has an obligation to advise the boat owner of the damage at the time damage occurs or as soon as it is noticed. The boat owner will do everything they can to fix the damage upon rental return. The boat owner may need to use the renter's deposit toward the repair costs. The boat owner will provide receipts for all work related to the repair of the damage. If the damage exceeds the amount of the deposit, the boat owner will either rely upon their insurance policy if it provides coverage under its terms, or will request the boat renter pay the additional expense. This would amount to the same circumstance if you were the owner of the vessel and had it repaired yourself. Do the right thing! BBR is not responsible for settling disputes between boat owner and renter.`,
    },
    {
      question: `Do you allow dogs on the boat?`,
      answer: `This is at the discretion of the boat owner; have the conversation in advance to negotiate additional pet fees or to avoid an unforeseen cleaning fee.`,
    },
  ];


  return (
    <>
    <FloatingButtons/>
    <section className='cs-bg-dark py-4 cs-section'>
        <Container>
            <Row>
                <Col>
                    <h1 className='text-white'>Frequently Asked Questions</h1>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col>
                    <div class="accordion" id="accordionExample">
                        {faqData.map((faq, index) => (
                          <div className="accordion-item" key={index}>
                            <h2 className="accordion-header" id={`heading${index}`}>
                              <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index}`}
                                aria-controls={`collapse${index}`}
                              >
                                {faq.question}
                              </button>
                            </h2>
                            <div
                              id={`collapse${index}`}
                              className="accordion-collapse collapse"
                              aria-labelledby={`heading${index}`}
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <p dangerouslySetInnerHTML={{ __html: faq.answer.replace(/\n/g, '<br />') }}></p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
    </>
    
  )
}
