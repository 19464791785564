import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StartTrip from './StartTrip';
export default function StartTripWrapped() {
    const stripePromise = loadStripe('pk_live_51OP31wGUDI27aRLukPP75NEf9vM9xP8zu8sIC5Nw20LUvA7qfrFHe5OCEvtjMtGLUEYEwLcp4d4sdu5eiNfrDtm2006843MTXC');
  return (
    <>
        <Elements stripe={stripePromise}>
            <StartTrip />
        </Elements>
    </>
  );
}
